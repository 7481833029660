import moment from "moment";
import Helpers from "./helpers";
import Constants from "constants/index";
import Strings from "constants/strings";
import { BookingDetailAmendType, ConfirmStatus } from "constants/enum";
import { IBookingDetail, IDetailBooking, IIssueTicketInfo, IItineraryExtraInfo } from "components/Booking/useDataRequestBooking.hook";
import { IBookingDetailAmend, IBookingDetailFlightAmendServicePopupExtraInfo } from "components/Booking/DetailContainer/interfaces";



const getBookingDetailsWithAmendExtraInfoParsed = (details: IBookingDetailAmend[]) => {
    return details.map((detail) => {
        const newDetail = { ...detail };
        if (!detail.extraInfo) {
            return detail;
        }
        if (detail.amendExtraInfoParsed) {
            return detail;
        }
        newDetail.amendExtraInfoParsed = Helpers.converStringToJson(detail.extraInfo) as IBookingDetailFlightAmendServicePopupExtraInfo;
        return newDetail;
    })
}

const extractFlightMetadataFromParsedExtraInfo = ({
    userId, language, extraInfo
}: {
    userId?: string,
    language: string,
    extraInfo: IItineraryExtraInfo,
}) => {
    if (!extraInfo) {
        throw Error("No extra info provided");
    };

    const flightInfo = extraInfo?.flightInfo;
    const issueTicketInfoParsed: IIssueTicketInfo = extraInfo?.issueTicketInfo;

    const ticketNumber = issueTicketInfoParsed?.booking?.itineraries?.[0]?.e_tickets?.[0]?.ticket_number;
    const itineraryData = issueTicketInfoParsed?.booking?.itineraries.find(i => i.leg === flightInfo?.leg);
    const reservationCode = itineraryData?.reservation_code;
    const baggages = itineraryData?.free_baggages || [];

    const departPlace = flightInfo?.departPlace;
    const departPlaceCity = flightInfo?.departPlaceObj?.city;

    const arrivalPlace = flightInfo?.arrivalPlace;
    const arrivalPlaceCity = flightInfo?.arrivalPlaceObj?.city;

    const getTicketNumberByUserId = (userId: string) => {
        const paxId = flightInfo?.userPassenger[userId];
        const ticketNumber = issueTicketInfoParsed?.booking?.itineraries?.[0]?.e_tickets?.find(t => t.pax_id.toString() === paxId.toString())?.ticket_number;
        return ticketNumber;
    };

    return {
        success: true,
        arrivalDT: flightInfo?.arrivalDT,
        arrivalPlace,
        arrivalPlaceCity,
        departDT: flightInfo?.departDT,
        departPlace,
        departPlaceCity,
        segments: flightInfo?.segmentsList.map(segment => ({
            index: segment?.index ?? undefined,
            departPlace: segment?.departPlace,
            departPlaceCity: segment?.departPlaceObj?.city,
            arrivalPlace: segment?.arrivalPlace,
            arrivalPlaceCity: segment?.arrivalPlaceObj?.city,
            carrierMarketing: {
                code: segment?.carrierMarketingObj?.code,
                logoUrl: segment?.carrierMarketingObj?.logoUrl,
                name: segment?.carrierMarketingObj?.name?.[language as "vi" | "en"],
            },
        })),
        // ticketNumber,
        getTicketNumberByUserId,
        flightNumber: flightInfo?.carrierOperator.concat(flightInfo?.flightNumber),
        cabinClass: flightInfo?.cabinClass,
        reservationCode,
        baggages,
        seatNumber: "-",
        carrierMarketing: {
            code: flightInfo?.carrierMarketingObj.code,
            logoUrl: flightInfo?.carrierMarketingObj.logoUrl,
            name: flightInfo?.carrierMarketingObj.name?.[language as "vi" | "en"],
        },
        departDate: flightInfo?.departDate,
        arrivalDate: flightInfo?.arrivalDate,
        isInternational: flightInfo?.isInternational,
        contact: {},
    };
};

const extractFlightMetadataFromAmendParsedExtraInfo = (
    extraInfo: IBookingDetailFlightAmendServicePopupExtraInfo,
    userId: string,
) => {
    if (!extraInfo) {
        throw Error("No extra info provided");
    }

    const { users, contact } = extraInfo || {};
    const flight = users?.find(u => u.id === userId)?.flight;

    return {
        success: !!flight,
        arrivalDT: moment.utc(flight?.arrivalTime * 1000).format(Constants.DateFormat.DDMMYYYY_HHMM),
        departDT: moment.utc(flight?.departTime * 1000).format(Constants.DateFormat.DDMMYYYY_HHMM),

        arrivalPlace: flight?.arrivalPlaceObj?.code?.toString(),
        arrivalPlaceCity: flight?.arrivalPlaceObj?.cityName?.toString(),
        departPlace: flight?.departPlaceObj?.code?.toString(),
        departPlaceCity: flight?.departPlaceObj?.cityName?.toString(),
        segments: [{
            departPlace: flight?.departPlaceObj?.code?.toString(),
            departPlaceCity: flight?.departPlaceObj?.cityName?.toString(),
            arrivalPlace: flight?.arrivalPlaceObj?.code?.toString(),
            arrivalPlaceCity: flight?.arrivalPlaceObj?.cityName?.toString(),
            carrierMarketing: {
                name: flight?.airlineObj?.name,
                code: flight?.airlineObj?.code,
                logoUrl: "",
            },
            index: flight?.segmentIndex ?? undefined,
        }],

        // ticketNumber: flight?.ticketNumber,
        getTicketNumberByUserId: (userId: string) => flight?.ticketNumber,
        flightNumber: flight?.flightNumber,
        cabinClass: flight?.cabinClass,
        reservationCode: flight?.reservationCode,
        baggages: flight?.baggage,
        seatNumber: flight?.seatNumber,

        carrierMarketing: {
            name: flight?.airlineObj?.name,
            code: flight?.airlineObj?.code,
            logoUrl: "",
        },
        departDate: flight?.departTime,
        arrivalDate: flight?.arrivalTime,
        isInternational: flight?.isInternational,
        contact,
    }
};

const getFlightExtraInfo = (flight: any, userId?: string, language: string = "vi") => {
    if ("amendExtraInfoParsed" in flight) {
        return extractFlightMetadataFromAmendParsedExtraInfo(flight.amendExtraInfoParsed, userId);
    }
    return extractFlightMetadataFromParsedExtraInfo({
        extraInfo: Helpers.toCamelCaseObj(Helpers.converStringToJson(flight.extraInfo)),
        language,
        userId,
    });
}

const getFlightExtraInfoByBookingDetailType = (flight: any, userId?: string, language: string = "vi") => {
    if (!flight.extraInfo || typeof flight.extraInfo !== "string") return { } as any;

    if (flight.amendType === BookingDetailAmendType.Normal) {
        return extractFlightMetadataFromParsedExtraInfo({
            extraInfo: Helpers.toCamelCaseObj(Helpers.converStringToJson(flight.extraInfo)),
            language,
            userId,
        });
    } else if ([BookingDetailAmendType.AdditionService, BookingDetailAmendType.PartialRefund, BookingDetailAmendType.Transfer].includes(flight.amendType)) {
        return extractFlightMetadataFromAmendParsedExtraInfo(Helpers.converStringToJson(flight.extraInfo), userId);
    }

    return { } as any;
}

const getBookingReservationCode = (details: IBookingDetail[], userId?: string) => {
    for (let i = 0; i < details.length; i++) {
        const detail = details[i];
        if (detail.amendType === BookingDetailAmendType.Normal) {
            const reservationCode = detail.bookingReferenceCodes?.[0]?.reservationCode;
            if (reservationCode) {
                return reservationCode;
            }
            continue;
        } else {
            const { reservationCode } = getFlightExtraInfo(details[i], userId);
            return reservationCode;
        }
    }
    return undefined;
}

const getBookingTicketNumber = (detail: IBookingDetail, userId: string) => {
    const { getTicketNumberByUserId } = getFlightExtraInfo(detail, userId);
    return getTicketNumberByUserId(userId);
}

const getBookingContactInfo = (details: IBookingDetail[], language: string) => {
    const originalBookings = details.filter(d => d.amendType === BookingDetailAmendType.Normal);

    const firstOriginalBooking = originalBookings[0];
    
    let extraInfoParsed = Helpers.toCamelCaseObj(Helpers.converStringToJson(firstOriginalBooking.extraInfo));

    const { contact } = extractFlightMetadataFromParsedExtraInfo({
        extraInfo: extraInfoParsed,
        language: language,
        userId: undefined,
    });

    return contact;
}

const isBookingWaitingForReservationCode = (booking: IDetailBooking) => {
    const normalDetails = booking.bookingDetails.filter(detail => detail.amendType === BookingDetailAmendType.Normal);
    return (booking.confirmStatus === ConfirmStatus.Completed) && getBookingReservationCode(normalDetails) === undefined;
};

const isBookingIssued = (booking: IDetailBooking) => {
    const normalDetails = booking.bookingDetails.filter(detail => detail.amendType === BookingDetailAmendType.Normal);
    return (booking.confirmStatus === ConfirmStatus.Completed) && getBookingReservationCode(normalDetails) !== undefined;
};

export const BookingHelpers = {
    getBookingDetailsWithAmendExtraInfoParsed,
    getFlightExtraInfo,
    getFlightExtraInfoByBookingDetailType,

    getBookingReservationCode,
    getBookingTicketNumber,
    getBookingContactInfo,

    isBookingIssued,
    isBookingWaitingForReservationCode,
};