import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Box, Button, FormField, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Constants from "constants/index";
import SystemSettingService from "services/booking/systemsetting.service";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { OrganizationType, SystemSettingCode } from "constants/enum";
import { setDataAlert } from "store/slice/message.slice";
import { IResponseSystemSetting } from "services/booking/systemsetting.service";
import InputMaskTime from "components/InputMaskTime";



const systemSettingService = new SystemSettingService();

const SettingOtherScreen = () => {

    const dispatch = useDispatch();
    const userProfile = useSelector((state: RootState) => state.userInfoSlice?.userProfile);

    const [loadingTripCheckIn, setLoadingTripCheckIn] = useState<boolean>(false);
    const [modeTripCheckIn, setModeTripCheckIn] = useState<"Update" | "View">("View");
    const [dataSettingTripCheckIn, setDataSettingTripCheckIn] = useState<IResponseSystemSetting | undefined>(undefined);
    const [dataSettingTripCheckInBackup, setDataSettingTripCheckInBackup] = useState<IResponseSystemSetting | undefined>(undefined);

    useEffect(() => {
        (async () => {
            try {

                const resultList = await systemSettingService.getSettingCode({
                    settingCode: SystemSettingCode.TripCheckIn,
                    organizationId: userProfile?.organizationId,
                    tenantCode: Constants.TENANT_CODE,
                });

                setDataSettingTripCheckIn(resultList);
                setDataSettingTripCheckInBackup(resultList);
            } catch (error) { }
        })();
    }, []);

    return (
        <DashboardLayout
            isPermission
            title="Thiết lập khác"
            route={[
                { title: "Thiết lập hệ thống", route: "" },
                { title: "Thiết lập khác", route: "" },
            ]}
        >
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <Box sx={{
                                gap: 1,
                                padding: 3,
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "space-between",

                            }}>
                                <Box sx={{
                                    gap: 1,
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                }}>
                                    {/* <img width={"280px"} height={"280px"} alt="FLIGHT_TIME" src={Resources.Images.FLIGHT_TIME} /> */}
                                    <Box paddingRight={2}>
                                        <Typography variant="button" fontWeight="bold">
                                            {"Xác định giờ Check-in:"}
                                        </Typography>
                                    </Box>
                                    <Box maxWidth={"280px"}>
                                        <InputMaskTime
                                            is24hours
                                            variant={"outlined"}
                                            placeholder={"Nhập thời gian"}
                                            disabled={modeTripCheckIn === "View"}
                                            readOnly={modeTripCheckIn === "View"}
                                            defaultValue={dataSettingTripCheckIn?.settingValue}
                                            onChangeValue={(value) => {
                                                setDataSettingTripCheckIn(prev => ({
                                                    ...prev,
                                                    settingValue: value,
                                                }));
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{
                                    gap: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "end",
                                }}>
                                    {modeTripCheckIn === "Update" &&
                                        <Button
                                            color="secondary"
                                            disabled={loadingTripCheckIn}
                                            onClick={() => {
                                                setDataSettingTripCheckIn({ ...dataSettingTripCheckInBackup });
                                                setModeTripCheckIn("View");
                                            }}
                                        >
                                            {Strings.Common.CANCEL}
                                        </Button>
                                    }
                                    {modeTripCheckIn === "Update" &&
                                        <Button
                                            loading={loadingTripCheckIn}
                                            disabled={loadingTripCheckIn}
                                            onClick={async () => {
                                                try {
                                                    setLoadingTripCheckIn(true);

                                                    let organizationId = dataSettingTripCheckIn?.organizationId;

                                                    if (
                                                        userProfile?.organizationType === OrganizationType.Main &&
                                                        dataSettingTripCheckIn?.tenantCode === Constants.TENANT_CODE
                                                    ) {
                                                        organizationId = "0";
                                                    }

                                                    await systemSettingService.update({
                                                        id: dataSettingTripCheckIn?.id,
                                                        settingCode: dataSettingTripCheckIn?.settingCode,
                                                        settingValue: dataSettingTripCheckIn?.settingValue,
                                                        serviceCode: dataSettingTripCheckIn?.serviceCode || Constants.SERVICE_CODE,
                                                        organizationId: organizationId,
                                                    });

                                                    dispatch(setDataAlert({ type: "success", message: "Cập nhật thành công!" }));
                                                } catch (error) {
                                                    const e = Helpers.renderExceptionError(error);
                                                    dispatch(setDataAlert({ type: "error", message: e }));
                                                } finally {
                                                    setModeTripCheckIn("View");
                                                    setLoadingTripCheckIn(false);
                                                }
                                            }}
                                        >
                                            {Strings.Common.SAVE}
                                        </Button>
                                    }
                                    {modeTripCheckIn === "View" &&
                                        <Button onClick={() => { setModeTripCheckIn("Update"); }}>
                                            {Strings.Common.EDIT}
                                        </Button>
                                    }
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </>
        </DashboardLayout>
    );
};

export default SettingOtherScreen;
