import moment from "moment";
import { useMemo } from "react";
import { Grid, } from "@mui/material";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import useDataBookingOrder from "../useDataBookingOrder.hook";

import { IRecordOrder } from "services/sale/order.service";
import { BookingOrderType, OrderStatus, PaymentStatus } from "constants/enum";



const CardBasicBookingOrder = (props: {
    index: number;
    order: IRecordOrder;
    referenceCode: string;
}) => {
    const {
        listOrderDetailType,
        getPaymentStatusByBookingOrder,
    } = useDataBookingOrder();

    const confirmTime = Number(props.order?.confirmTime);
    const paymentStatus = props.order?.paymentStatus;
    const orderConfirmStatus = paymentStatus < PaymentStatus.Completed && confirmTime < (Date.now() / 1000) ? OrderStatus.Cancel : Number(props.order?.orderStatus);
    const orderCode = props.order?.orderCode;
    const orderDate = props.order?.orderDate;
    const orderDetailType = props.order?.orderDetails?.[0]?.type;
    const newOrderDate = useMemo(() => (Helpers.getDateValue(orderDate)), [orderDate]);
    const isManualCancelOrder = orderConfirmStatus === OrderStatus.Cancel && paymentStatus === PaymentStatus.WaitingRefund && props.order?.note;

    const newDataPaymentStatus = useMemo(() => {
        if (orderConfirmStatus === OrderStatus.Cancel) {
            if (paymentStatus === PaymentStatus.Refunded) {
                return { icon: "currency_exchange", color: "error", title: Strings.STATUS.PAYMENT.Refunded };
            }
            if (paymentStatus === PaymentStatus.WaitingRefund) {
                return { icon: "currency_exchange", color: "secondary", title: "Đã huỷ, chờ hoàn tiền" };
            }

            return { icon: "cancel", color: "secondary", title: Strings.STATUS.PAYMENT.Cancel };
        } else if (orderConfirmStatus === OrderStatus.Confirming) {
            if (paymentStatus === PaymentStatus.Completed) {
                return { icon: "check", color: "warning", title: "Đã thanh toán, chờ xử lý" };
            }
        } else if (orderConfirmStatus === OrderStatus.Completed) {
            if (paymentStatus === PaymentStatus.Completed) {
                return { icon: "check", color: "success", title: "Hoàn thành" };
            }
        }
        const newdata = getPaymentStatusByBookingOrder({
            bookingOrderType: BookingOrderType.Main,
            paymentStatus: Number(paymentStatus),
            confirmStatusBooking: orderConfirmStatus,
        });

        return newdata;
    }, [orderConfirmStatus, getPaymentStatusByBookingOrder, paymentStatus]);
    
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={7}>
                    <Box gap={1} display={"grid"}>
                        <Typography variant="button" fontWeight="bold">
                            {`${Strings.BOOKING_ORDER.DETAIL.ORDER_CODE}: `}
                            {orderCode || "-"}
                        </Typography>
                        <Typography variant="button">
                            {`${Strings.BOOKING_ORDER.DETAIL.BOOKING_SERVICE}: `}
                            {listOrderDetailType?.find(el => el.code === orderDetailType)?.name || "-"}
                        </Typography>
                        {!Helpers.isNullOrEmpty(props.referenceCode) &&
                            <Typography variant="button">
                                {"Booking Code: "}
                                {props.referenceCode || ""}
                            </Typography>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={5}>
                    <Box gap={1} display={"flex"} justifyContent="flex-end" flexDirection="column">
                        <Typography variant="button">
                            {`${Strings.BOOKING_ORDER.DETAIL.CREATE_ORDER_DATE}: `}
                            {newOrderDate ? moment(newOrderDate).format("HH:mm:ss DD/MM/YYYY") : "-"}
                        </Typography>
                        <Typography variant="button">
                            {`${Strings.BOOKING_ORDER.DETAIL.PAYMENT_STATUS}: `}
                            {
                                <Typography variant="button" color={newDataPaymentStatus?.color as any}>
                                    {newDataPaymentStatus?.title || "-"}
                                </Typography>
                            }
                        </Typography>
                        {isManualCancelOrder && (
                            <Typography variant="button">
                                {`${Strings.BOOKING_ORDER.DETAIL.CANCEL_ORDER_REASON}: `}
                                {props.order?.note}
                            </Typography>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
};

export default CardBasicBookingOrder;