import { Card, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Box, Button, IPropDetailContainer, Typography, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import OrderService from "services/sale/order.service";
import CardItemBookingOrder from "./cardItemBookingOrder";
import CardBasicBookingOrder from "./cardBasicBookingOrder";
import useDataBookingOrder from "../useDataBookingOrder.hook";
import CardPaymentBookingOrder from "./cardPaymentBookingOrder";
import CardCustomerBookingOrder from "./cardCustomerBookingOrder";
import CardHistoriesBookingOrder from "./cardHistoriesBookingOrder";
import OrganizationService from "services/identity/organization.service";
import RequestBookingService from "services/booking/requestBooking.service";

import { BookingDetailAmendType, OrderStatus, OrderType, PaymentStatus } from "constants/enum";
import { IOrderDetail, IOrderHistorie, IRecordOrder } from "services/sale/order.service";
import { IDetailBooking, IItineraryExtraInfo } from "components/Booking/useDataRequestBooking.hook";
import CardPaymentLinkBookingOrder from "./cardPaymentLinkBookingOrder";
import { IBookingDetailFlightAmendServicePopupExtraInfo } from "components/Booking/DetailContainer/interfaces";



const orderService = new OrderService();
const organizationService = new OrganizationService();
const requestBookingService = new RequestBookingService();

export type IOrderDetailExtends = IOrderDetail & { orderType: number };

const BookingOrderDetailContainer = (props: IPropDetailContainer & { bookingId?: string }) => {
    const {
        userInfo,
        onError,
        onHideLoading,
        onShowLoading,
    } = useCommonComponentContext();

    const { listOrderStatus, paymentTypeList, getDataMapUserByIds } = useDataBookingOrder();

    const [dataDetail, setDataDetail] = useState<IRecordOrder | undefined>(undefined);

    const [bookingData, setBookingData] = useState<IDetailBooking | undefined>(undefined);
    const { dataBookingReferenceCode, dataMapReferenceBookingId, dataMapExtraInfoRoot } = useMemo(() => {
            const newDataMapId: Map<string, string> = new Map();
            const newDataMapExtraInfo: Map<string, IItineraryExtraInfo> = new Map();

            for (const element of [...bookingData?.bookingDetails || []]) {
                if (Helpers.isNullOrEmpty(element.referenceId) || element.referenceId === "0") {
                    const reservationCode = element.bookingReferenceCodes?.[0]?.reservationCode;

                    let newExtraInfo: IItineraryExtraInfo = Helpers.toCamelCaseObj(Helpers.converStringToJson(element.extraInfo));

                    const itineraries = [...newExtraInfo?.issueTicketInfo?.booking?.itineraries || []].map(el => ({
                        ...el,
                        reservation_code: el.reservation_code || reservationCode,
                    }));

                    newExtraInfo = {
                        ...newExtraInfo,
                        issueTicketInfo: {
                            ...newExtraInfo?.issueTicketInfo,
                            booking: {
                                ...newExtraInfo?.issueTicketInfo?.booking,
                                itineraries: itineraries
                            },
                        }
                    };

                    newDataMapExtraInfo.set(element.id, newExtraInfo);
                } else {
                    newDataMapId.set(element.id, element.referenceId);
                }
            };
            const listNormal = bookingData?.bookingDetails.filter(el => el.amendType === BookingDetailAmendType.Normal);
            const newItem = listNormal?.[0]?.bookingReferenceCodes?.[0];

            return {
                dataMapReferenceBookingId: newDataMapId,
                dataMapExtraInfoRoot: newDataMapExtraInfo,
                dataBookingReferenceCode: newItem,
            };
    }, [bookingData]);

    const [dataMapUser, setDataMapUser] = useState<Map<string, string>>(new Map());
    const [dataMapOrderHistories, setDataMapOrderHistories] = useState<Map<string, IOrderHistorie[]>>(new Map());
    const [dataOrganization, setDataOrganization] = useState<{
        id?: string,
        name?: string,
        code?: string,
        email?: string,
        createUser?: string,
        phoneNumber?: string,
    }>({});

    useEffect(() => {
        if (
            bookingData
            && !Helpers.isNullOrEmpty(userInfo?.userProfile.organizationId)
            && !Helpers.isNullOrEmpty(props.idDetail)
        ) {
            getDetailOrder(props.idDetail, bookingData);
        }
    }, [bookingData, props.idDetail, userInfo?.userProfile.organizationId]);

    useEffect(() => {
        if (props.bookingId) {
            fetchBookingData(props.bookingId);
        }
        async function fetchBookingData(bookingId: string) {
            try {
                const result = await requestBookingService.getDetail(bookingId);
                setBookingData(result);
            } catch (error) { };
        }
    }, [props.bookingId]);

    const getDetailOrder = async (id: string, bookingData: IDetailBooking) => {
        try {
            onShowLoading();

            let newDataUser = new Map();
            let newDataMapOrderHistories = new Map();

            let resultOrder: IRecordOrder | undefined = undefined;

            resultOrder = await orderService.getDetailOrderIncludeSubOrder(id);

            if (Helpers.isNullOrEmpty(resultOrder?.referenceId)) {
            } else {
                resultOrder = await orderService.getDetailOrderIncludeSubOrder(resultOrder?.referenceId);
            }

            // Order Root
            newDataMapOrderHistories.set(resultOrder?.orderCode, [...resultOrder?.orderHistories || []]);

            const uids = [...resultOrder?.orderHistories || []].map(el => el.createUser);
            bookingData.members.forEach(userId => {
                uids.push(userId);
            });
            newDataUser = await getDataMapUserByIds({
                ids: uids,
                newDataUser: newDataUser,
                orgId: resultOrder?.organization?.id,
            });

            // Order Children
            let userIds: string[] = [];
            for (const element of [...resultOrder?.subOrder || []]) {

                const temps = [...element.orderHistories || []];

                const uids = temps.map(el => el.createUser);

                userIds = [...userIds || [], ...uids || []];

                newDataMapOrderHistories.set(element.orderCode, temps);
            };

            if (userIds.length > 0) {
                newDataUser = await getDataMapUserByIds({
                    ids: userIds,
                    newDataUser: newDataUser,
                    orgId: userInfo?.userProfile?.organizationId || "0",
                });
            }

            // get Org info
            const resultOrg = await organizationService.getOrganizationByIds([resultOrder?.organizationId]);
            const itemOrgProfile = [...resultOrg[0]?.organizationProfiles || []].find(org => (org.parentGroup === "0"));
            if (!userIds.includes(resultOrg[0]?.createUser) &&
                !uids.includes(resultOrg[0]?.createUser)) {
                newDataUser = await getDataMapUserByIds({
                    orgId: resultOrg[0]?.id,
                    newDataUser: newDataUser,
                    ids: [resultOrg[0]?.createUser],
                });
            };

            const currency = resultOrder?.currency || userInfo?.userProfile?.currency;
            setDataDetail({ ...resultOrder, currency: currency });

            setDataMapUser(newDataUser);
            setDataMapOrderHistories(newDataMapOrderHistories);

            setDataOrganization({
                id: resultOrg[0]?.id,
                email: itemOrgProfile?.email,
                code: resultOrg[0]?.organizationCode,
                name: resultOrg[0]?.name?.value?.["vi"],
                phoneNumber: itemOrgProfile?.phoneNumber,
                createUser: newDataUser.get(resultOrg[0]?.createUser),
            });
        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            onError(e);
        } finally {
            onHideLoading();
        }
    };

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(dataDetail?.id)
            && (dataDetail?.id !== props.idDetail)) {
            document.getElementById(props.idDetail).scrollIntoView();
        }
    }, [dataDetail?.id, props.idDetail]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Button color={"secondary"} onClick={() => { props.onGoBack(); }}>
                            {Strings.Common.GO_BACK}
                        </Button>
                    </Box>
                </Grid>
                {dataDetail?.id &&
                    <Grid item xs={12} sm={12} md={7} lg={8}>
                        {
                            [dataDetail, ...dataDetail?.subOrder || []].map((itemDetail, index) => (
                                <Card id={itemDetail?.id} key={index} sx={{ mb: 3 }}>
                                    <Grid container spacing={2} padding={3}>
                                        <Grid item xs={12}>
                                            <CardBasicBookingOrder
                                                index={index}
                                                order={itemDetail}
                                                referenceCode={dataBookingReferenceCode?.referenceCode}
                                            />
                                            {(itemDetail.type === OrderType.Transfer || itemDetail.type === OrderType.AdditionService)
                                                ? <CardPaymentLinkBookingOrder
                                                    booking={bookingData}
                                                    userMap={dataMapUser}
                                                    refetchOrderDataCallback={() => getDetailOrder(props.idDetail, bookingData)}
                                                    order={itemDetail}
                                                />
                                                : null}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CardItemBookingOrder
                                                orderType={itemDetail?.type}
                                                orderDetails={itemDetail?.orderDetails}
                                                paymentStatus={itemDetail?.paymentStatus}
                                                organizationId={dataDetail?.organizationId}
                                                typeBooking={dataBookingReferenceCode?.type}
                                                isSubOrder={!Helpers.isNullOrEmpty(itemDetail.referenceId)}
                                                currency={itemDetail?.currency || userInfo?.userProfile?.currency}

                                                dataMapExtraInfoRoot={dataMapExtraInfoRoot}
                                                dataMapReferenceBookingId={dataMapReferenceBookingId}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CardPaymentBookingOrder
                                                amount={itemDetail?.amount}
                                                paymentTypeList={paymentTypeList}
                                                serviceFee={itemDetail?.serviceFee}
                                                paymentFee={itemDetail?.paymentFee}
                                                paymentMethod={itemDetail?.paymentMethod}
                                                paymentStatus={itemDetail?.paymentStatus}
                                                serviceFeeTax={itemDetail?.serviceFeeTax}
                                                currency={itemDetail?.currency || userInfo?.userProfile?.currency}
                                            />
                                        </Grid>
                                        {itemDetail.type === OrderType.Transfer
                                            && OrderStatus.Completed === itemDetail.orderStatus
                                            && PaymentStatus.Completed <= itemDetail.paymentStatus
                                            && <Grid item xs={12}>
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    gap={3}
                                                    padding="16px"
                                                    borderRadius="16px"
                                                    border="1.5px solid #F0F0F0"
                                                >
                                                    <Typography variant="h6" component="span">Thông tin giá vé thực tế</Typography>
                                                    {itemDetail.orderDetails?.map((orderDetail, index) => {
                                                        const extraInfo = Helpers.converStringToJson(orderDetail.extraInformation) as IBookingDetailFlightAmendServicePopupExtraInfo;
                                                        const user = extraInfo.users[0];
                                                        const actualData = user?.transferFlightActualData;
                                                        const userDisplayName = dataMapUser.get(user?.id);

                                                        return actualData && (
                                                            <Box key={index} display="flex" flexDirection="column" gap={0.5}>
                                                                <Typography variant="button" component="span">Hành khách: {userDisplayName}</Typography>
                                                                <Box display="flex" justifyContent="space-between">
                                                                    <Typography variant="button" component="span">Tiền chênh lệch thực tế:</Typography>
                                                                    <Typography variant="button" component="span">{Helpers.formatCurrency(actualData?.actualCost)} {orderDetail.currency}</Typography>
                                                                </Box>
                                                                <Box display="flex" justifyContent="space-between">
                                                                    <Typography variant="button" component="span">Phí thực tế:</Typography>
                                                                    <Typography variant="button" component="span">{Helpers.formatCurrency(actualData?.actualCost)} {orderDetail.currency}</Typography>
                                                                </Box>
                                                                <Box display="flex" justifyContent="space-between">
                                                                    <Typography variant="button" component="span">Mã đặt chỗ mới:</Typography>
                                                                    <Typography variant="button" component="span" fontWeight="bold">{actualData.actualReservationCode}</Typography>
                                                                </Box>
                                                                <Box display="flex" justifyContent="space-between">
                                                                    <Typography variant="button" component="span">Số vé mới</Typography>
                                                                    <Typography variant="button" component="span" fontWeight="bold">{actualData.actualTicketNumber}</Typography>
                                                                </Box>
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                            </Grid>
                                        }
                                    </Grid>
                                </Card>
                            ))
                        }
                    </Grid>
                }
                {dataDetail?.id &&
                    <Grid item xs={12} sm={12} md={5} lg={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={12} lg={12}>
                                <CardCustomerBookingOrder {...dataOrganization} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={12} lg={12}>
                                <Card>
                                    <Box padding={2}>
                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            {Strings.BOOKING_ORDER.DETAIL.BOOKING_ORDER_HISTORY}
                                        </Typography>
                                        {
                                            Array.from(dataMapOrderHistories.keys()).map(key => (
                                                <CardHistoriesBookingOrder
                                                    key={key}
                                                    orderCode={key}
                                                    dataMapUser={dataMapUser}
                                                    listOrderStatus={listOrderStatus}
                                                    dataOrderHistories={dataMapOrderHistories.get(key)}
                                                />
                                            ))
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </>
    );
};

export default BookingOrderDetailContainer;
