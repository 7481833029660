import Helpers from "commons/helpers";
import Constants from "../../constants";
import SaleService from "./sale.service";
import { ICodename, IGetPaged, IMultiLanguageContent } from "commons/interfaces";

class OrderService extends SaleService {
    public getPaged = async (data: IReqGetPagedOrder): Promise<IGetPaged<IRecordOrder>> => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.get({
            path: `${Constants.ApiPath.ORDER.GET_PAGED}?${query}`,
        });
        return result.data.result;
    }

    public getDetail = async (id: string): Promise<IRecordOrder> => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.ORDER.DETAIL}/${id}`,
        });
        return result.data.result;
    }

    public getDetailOrderIncludeSubOrder = async (id: string): Promise<IRecordOrder> => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.ORDER.GET_DETAIL_ORDER_INCLUDE_SUB_ORDER}/${id}`,
        });
        return result.data.result;
    }

    public confirmOrder = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORDER.CONFIRM_ORDER,
            data: data,
        });
        return result.data.result;
    }

    public completeOrder = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORDER.COMPLETE_ORDER,
            data: data,
        });
        return result.data;
    }

    public calcPricing = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORDER.CALC_PRICE,
            data: data,
        });
        return result.data;
    }

    public update = async (data: any) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORDER.UPDATE,
            data: data,
        });
        return result.data;
    }

    public updateOrderDetail = async (data: IUpdateOrderDetailRequest): Promise<any> => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORDER.UPDATE_ORDER_DETAIL,
            data: data,
        });
        return result.data.result;
    }

    public cancel = async (id: string) => {
        const result = await this.api.post({
            path: Constants.ApiPath.ORDER.CANCEL + `/${id}`,
        });
        return result.data.result;
    }

    public refund = async (data: {
        id: string,
        reason?: string,
    }) => {
        const result = await this.api.patch({
            path: Constants.ApiPath.ORDER.REFUND,
            data: data,
        });
        return result.data.result;
    }

    public exportCsv = async (data: IReqGetPagedOrder) => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.getExcel({
            path: Constants.ApiPath.ORDER.EXPORT_CSV + `?${query}`
        });

        return new Blob([result.data]);
    };

    public getResponseByOrderIds = async (ids: string[]) => {
        const query = Helpers.handleFormatParams({ ids: ids });

        const result = await this.api.get({
            path: `${Constants.ApiPath.ORDER.GET_RESPONSE_BY_ORDER_IDS}?${query}`,
        });

        return result.data.result;
    }

}

export default OrderService;

export interface IReqGetPagedOrder {
    orderType?: number;

    partnerName?: string;
    orderDetailType?: number;
    toDate?: number | string;
    fromDate?: number | string;

    organizationId?: string;
    paymentStatus?: number;
    orderStatus?: number;
    searchText?: string;
    seller?: string;
    buyer?: string;

    orderby?: string;
    pageSize?: number;
    pageNumber?: number;
    totalCount?: number;
};

export interface IRecordOrder {
    mobileAmount?: number;
    originAmount?: number;
    totalItem?: number;
    discountWithPromotion?: number;
    discountWithoutPromotion?: number;
    sellerName?: string;
    buyerName?: string;
    buyerPhoneNumber?: string;
    buyerAddress?: string;
    buyerType?: any;
    buyerClass?: string;
    buyerUserId?: string;
    createUserName?: string;
    transactionId?: string;
    organization?: {
        id?: string;
        type?: number;
        ownerId?: string;
        tenantCode?: string;
        name?: IMultiLanguageContent;
        description?: IMultiLanguageContent;
        organizationProfiles?: {
            id?: string;
            email?: string;
            currency?: string;
            location?: string;
            addressId?: string;
            faxNumber?: string;
            taxNumber?: string;
            phoneNumber?: string;
            name?: IMultiLanguageContent;
            description?: IMultiLanguageContent;
        }[];
    };
    orderDetailType?: number;
    orderDetails?: IOrderDetail[];
    orderDiscounts?: IO_IItemDiscount[];
    orderTaxs?: any[];
    orderHistories?: IOrderHistorie[];
    discountDetails?: any[];
    discountNumber?: number;
    applyPromotions?: IO_AvailablePromotion[];
    availablePromotions?: IO_AvailablePromotion[];
    paymentInfo?: any;
    type?: number;
    tenantCode?: string;
    serviceCode?: string;
    organizationId?: string;
    groupId?: string;
    seller?: string;
    buyer?: string;
    shippingMethod?: number;
    shippingAddress?: string;
    paymentMethod?: number;
    billingAddress?: string;
    orderCode?: string;
    orderDate?: string;
    discount?: number;
    amount?: number;
    tax?: number;
    currency?: string;
    location?: string;
    note?: string;
    paymentStatus?: number;
    orderStatus?: number;
    referenceId?: string;
    serviceFee?: number;
    paymentFee?: number;
    serviceFeeTax?: number;
    extraFee?: number;
    extraFeeDetail?: string;

    subOrder?: IRecordOrder[];

    // sub order attribute
    paymentLink?: string;
    confirmTime?: string;

    id?: string;
    status?: number;
    createTime?: string;
    createUser?: string;
    updateTime?: string;
    updateUser?: string;
};

export interface IOrderDetail {
    itemName?: IMultiLanguageContent;
    itemSku?: string;
    itemStatus?: number;
    suggestQuantity?: number;
    suggestDiscount?: number;
    orderDetailComboItems?: any;
    extraInformation?: string;
    itemDiscount?: IO_IItemDiscount;
    itemTaxes?: IItemTax[];
    itemPhotos?: IItemPhoto[];
    itemAttributes?: IItemAttribute[];
    type?: number;
    orderId?: string;
    itemId?: string;
    promotion?: number;
    description?: any;
    quantity?: number;
    discount?: number;
    unitPrice?: number;
    currency?: number;
    amount?: number;
    tax?: number;
    fee?: number;
    feeTax?: number;
    referenceId?: string;
    externalId?: string;
    extraFee?: number;
    extraFeeDetail?: string;
    id?: string;
    status?: number;
    createTime?: string;
    createUser?: string;
    updateTime?: string;
    updateUser?: string;
};

export interface IO_IItemDiscount {
    itemId?: string;
    orderDetailId?: string;
    isIssued?: boolean;
    promotionCode?: string;
    type?: number;
    invoiceId?: string;
    invoiceDetailId?: string;
    promotionId?: string;
    voucherCodeId?: string;
    discountRate?: number;
    discount?: number;
    discountQuantity?: number;
    id?: string;
    status?: number;
    createTime?: string;
    createUser?: string;
    updateTime?: string;
    updateUser?: string;
};

export interface IOrderHistorie {
    id?: string;
    note?: string;
    status?: number;
    orderId?: string;
    orderStatus?: number;
    createUserName?: string;
    createTime?: string;
    createUser?: string;
    updateTime?: string;
    updateUser?: string;
};

export interface IO_AvailablePromotion {
    endlessTime: number;
    programDiscountSetting: any;
    programCriterias: IO_ProgramCriteria[];
    programTargets: any;
    programCodeSettingResponse: any;
    programVoucherValue: number;
    voucherQuantityUsed: number;
    tenantCode: string;
    serviceCode: string;
    organizationId: string;
    groupId: any;
    programType: number;
    programCode: string;
    programName: IMultiLanguageContent;
    description: IMultiLanguageContent;
    startTime: string;
    endTime: string;
    id: string;
    status: number;
    createTime: string;
    createUser: string;
    updateTime: string;
    updateUser: string;
};

export interface IItemTax {
    itemId?: string;
    orderDetailId?: string;
    invoiceId?: string;
    invoiceDetailId?: string;
    taxCode?: string;
    taxRate?: number;
    tax?: number;
    id?: string;
    status?: number;
    createTime?: string;
    createUser?: string;
    updateTime?: string;
    updateUser?: string;
};

export interface IO_ProgramCriteria {
    discountType: number;
    voucherName: any;
    programCriteriaItems: any[];
    programId: string;
    minimumExpense: number;
    minimumQuantity: number;
    criteriaType: number;
    amount: number;
    quantity: number;
    priority: number;
    id: string;
    status: number;
    createTime: string;
    createUser: string;
    updateTime: string;
    updateUser: string;
};

export interface IItemPhoto {
    itemId: string;
    id: string;
    photoId: string;
    photoUrl: string;
    pixels: number;
    displayOrder: number;
    sectionId: string;
    sectionName: IMultiLanguageContent;
};


export interface IItemAttribute {
    type?: number;
    organizationId?: string;
    value?: IMultiLanguageContent;
    title?: IMultiLanguageContent;
    iconId?: string;
    itemId?: string;
    attributeCode: string;
    attributeValue: string;
    valueType?: number;

    id?: string;
    status?: number;
    createTime?: string;
    createUser?: string;
    updateTime?: string;
    updateUser?: string;

    attributeValueList?: ICodename[];
    productId?: string;
};

export interface IUpdateOrderDetailRequest {
    orderId: string;
    orderDetailDataUpdate: IOrderDetailDataUpdate[];
}

export interface IOrderDetailDataUpdate {
    orderDetailId: string,
    costPrice: number,
    extraInformation: string;
}