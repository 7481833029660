import { Card, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Box, Button, IPropDetailContainer, Typography, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import CardItemBookingOrder from "./cardItemBookingOrder";
import CardBasicBookingOrder from "./cardBasicBookingOrder";
import useDataBookingOrder from "../useDataBookingOrder.hook";
import CardPaymentBookingOrder from "./cardPaymentBookingOrder";
import CardCustomerBookingOrder from "./cardCustomerBookingOrder";
import CardHistoriesBookingOrder from "./cardHistoriesBookingOrder";
import OrganizationService from "services/identity/organization.service";
import RequestBookingService from "services/booking/requestBooking.service";
import { IDetailBooking, IItineraryExtraInfo } from "components/Booking/useDataRequestBooking.hook";
import OrderService, { IOrderDetail, IRecordOrder } from "services/sale/order.service";
import { OrderType, PaymentStatus } from "constants/enum";



const orderService = new OrderService();
const organizationService = new OrganizationService();
const requestBookingService = new RequestBookingService();

export type IOrderDetailExtends = IOrderDetail & { orderType: number };

const BookingRefundOrderDetailContainer = (props: IPropDetailContainer) => {
    const {
        userInfo,
        onError,
        onHideLoading,
        onShowLoading,
    } = useCommonComponentContext();

    const { listOrderStatus, paymentTypeList, getDataMapUserByIds } = useDataBookingOrder();

    const [dataDetail, setDataDetail] = useState<IRecordOrder | undefined>(undefined);

    const [dataMapUser, setDataMapUser] = useState<Map<string, string>>(new Map());
    const [dataBooking, setDataBooking] = useState<IDetailBooking | undefined>(undefined);
    const [dataMapExtraInfoBooking, setDataMapExtraInfoBooking] = useState<Map<string, string>>(new Map());
    const [dataOrganization, setDataOrganization] = useState<{
        id?: string,
        name?: string,
        code?: string,
        email?: string,
        createUser?: string,
        phoneNumber?: string,
    }>({});
    const dataMapOrderHistories = useMemo(() => {
        const dataMapOrderHistories = new Map();
        if (dataDetail) {
            for (const element of [dataDetail, ...dataDetail.subOrder || []]) {
                const temps = [...element.orderHistories || []];
                dataMapOrderHistories.set(element.orderCode, temps);
            };
        }
        return dataMapOrderHistories;
    }, [dataDetail]);
    const dataMapOrderDetail = useMemo(() => {
        const dataMapOrderDetail = new Map();
        if (dataDetail) {
            for (const order of [dataDetail, ...dataDetail.subOrder || []]) {
                for (const orderDetail of order.orderDetails) {
                    dataMapOrderDetail.set(order.id, {
                        ...order,
                        orderType: orderDetail.type,
                    });
                };
            };
        }

        return dataMapOrderDetail;
    }, [dataDetail]);


    async function fetchData(id: string) {
        try {
            onShowLoading();
            const userInfoCurrency = userInfo?.userProfile?.currency;
            const resultOrder = await getOrderDetail(id);
            const newDataBooking = await getExtraInfoDetailBookingByOrderId(resultOrder?.id);
            const { resultOrg, rootOrganizationGroup } = await getOrganization(resultOrder?.organizationId || "0");
            const createUserUserMap = await getCreateOrderUsersMap({
                baseUserIds: resultOrg[0]?.createUser ? [resultOrg[0]?.createUser] : [],
                orders: [resultOrder, ...resultOrder?.subOrder || []],
                organizationId: resultOrder?.organizationId || "0",
            });

            setDataDetail({ ...resultOrder, currency: resultOrder?.currency || userInfoCurrency });
            setDataBooking(newDataBooking.dataBooking);
            setDataMapExtraInfoBooking(newDataBooking.newDataMap);
            setDataOrganization({
                id: resultOrg[0]?.id,
                email: rootOrganizationGroup?.email,
                code: resultOrg[0]?.organizationCode,
                name: resultOrg[0]?.name?.value?.["vi"],
                phoneNumber: rootOrganizationGroup?.phoneNumber,
                createUser: createUserUserMap.get(resultOrg[0]?.createUser),
            });

            setDataMapUser(createUserUserMap);

        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            onError(e);
        } finally {
            onHideLoading();
        }

        async function getOrderDetail(orderId: string): Promise<IRecordOrder> {
            let resultOrder = await orderService.getDetailOrderIncludeSubOrder(orderId);
            if ([OrderType.AdditionService, OrderType.Transfer, OrderType.PartialRefund].includes(resultOrder.type)) {
                resultOrder = await orderService.getDetailOrderIncludeSubOrder(resultOrder?.referenceId);
            }
            return resultOrder;
        }
        async function getOrganization(organizationId: string) {
            const resultOrg = await organizationService.getOrganizationByIds([organizationId]);
            const rootOrganizationGroup = [...resultOrg[0]?.organizationProfiles || []].find(org => (org.parentGroup === "0"));
            return { resultOrg, rootOrganizationGroup };
        }
        async function getCreateOrderUsersMap({ baseUserIds, orders, organizationId }: {
            orders: IRecordOrder[],
            baseUserIds: string[],
            organizationId: string,
        }) {
            let newDataUser = new Map();
            const userIds: string[] = [...baseUserIds];
            for (const element of orders) {
                const histories = [...element.orderHistories || []];
                const createOrderUsers = histories.map(el => el.createUser).filter(item => !!item);
                userIds.push(...createOrderUsers);
            };
            if (userIds.length > 0) {
                newDataUser = await getDataMapUserByIds({
                    ids: userIds,
                    newDataUser: newDataUser,
                    orgId: organizationId || "0",
                });
            }

            return newDataUser;
        }
        async function getExtraInfoDetailBookingByOrderId(orderId: string): Promise<{ newDataMap: Map<string, string>, dataBooking: IDetailBooking | undefined, }> {
            try {
                const newDataMap: Map<string, string> = new Map();

                const result = await requestBookingService.getDetailByOrderId(orderId);


                for (const element of [...result?.bookingDetails || []]) {
                    const reservationCode = element.bookingReferenceCodes?.[0]?.reservationCode

                    let newExtraInfo: IItineraryExtraInfo = Helpers.toCamelCaseObj(Helpers.converStringToJson(element.extraInfo));

                    const itineraries = [...newExtraInfo?.issueTicketInfo?.booking?.itineraries || []].map(el => ({
                        ...el,
                        reservation_code: el.reservation_code || reservationCode,
                    }));

                    newExtraInfo = {
                        ...newExtraInfo,
                        issueTicketInfo: {
                            ...newExtraInfo?.issueTicketInfo,
                            booking: {
                                ...newExtraInfo?.issueTicketInfo?.booking,
                                itineraries: itineraries
                            },
                        }
                    };

                    newDataMap.set(element.id, JSON.stringify(newExtraInfo));
                };

                return { dataBooking: result, newDataMap };
            } catch (error) {
                return { dataBooking: undefined, newDataMap: new Map() };
            }
        };
    };
    useEffect(() => {
        if (!Helpers.isNullOrEmpty(userInfo?.userProfile.organizationId) &&
            !Helpers.isNullOrEmpty(props.idDetail)) {
            fetchData(props.idDetail);
        }
    }, [userInfo?.userProfile.organizationId, props.idDetail]);

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(dataDetail?.id)
            && (dataDetail?.id !== props.idDetail)) {
            document.getElementById(props.idDetail).scrollIntoView();
        }
    }, [dataDetail?.id, props.idDetail]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Button color={"secondary"} onClick={() => { props.onGoBack(); }}>
                            {Strings.Common.GO_BACK}
                        </Button>
                    </Box>
                </Grid>
                {dataDetail?.id &&
                    <Grid item xs={12} sm={12} md={7} lg={8}>
                        {
                            [dataDetail, ...dataDetail?.subOrder || []].map((itemDetail, index) => (
                                <Card id={itemDetail?.id} key={index} sx={{ mb: 3 }}>
                                    <Grid container spacing={2} padding={3}>
                                        <Grid item xs={12}>
                                            <CardBasicBookingOrder
                                                refundable={[PaymentStatus.WaitingRefund, PaymentStatus.WaitingPartialRefund].includes(itemDetail?.paymentStatus)}
                                                onFetchData={() => fetchData(dataDetail.id)}
                                                index={index}
                                                dataBooking={dataBooking}
                                                orderCode={itemDetail?.orderCode}
                                                orderDate={itemDetail?.orderDate}
                                                paymentStatus={itemDetail?.paymentStatus}
                                                orderDetailType={itemDetail?.orderDetails?.[0]?.type}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CardItemBookingOrder
                                                orderType={itemDetail?.type}
                                                typeBooking={dataBooking?.type}
                                                orderDetails={itemDetail?.orderDetails}
                                                paymentStatus={itemDetail?.paymentStatus}
                                                organizationId={dataDetail?.organizationId}
                                                isSubOrder={!Helpers.isNullOrEmpty(itemDetail?.referenceId)}
                                                currency={itemDetail?.currency || userInfo?.userProfile?.currency}

                                                dataMapOrderDetail={dataMapOrderDetail}
                                                dataMapExtraInfoBooking={dataMapExtraInfoBooking}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CardPaymentBookingOrder
                                                orderType={itemDetail?.type}
                                                amount={itemDetail?.amount}
                                                paymentTypeList={paymentTypeList}
                                                serviceFee={itemDetail?.serviceFee}
                                                paymentFee={itemDetail?.paymentFee}
                                                paymentMethod={itemDetail?.paymentMethod}
                                                paymentStatus={itemDetail?.paymentStatus}
                                                serviceFeeTax={itemDetail?.serviceFeeTax}
                                                currency={itemDetail?.currency || userInfo?.userProfile?.currency}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            ))
                        }
                    </Grid>
                }
                {dataDetail?.id &&
                    <Grid item xs={12} sm={12} md={5} lg={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={12} lg={12}>
                                <CardCustomerBookingOrder {...dataOrganization} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={12} lg={12}>
                                <Card>
                                    <Box padding={2}>
                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                            {"Lịch sử đơn hàng"}
                                        </Typography>
                                        {
                                            Array.from(dataMapOrderHistories.keys()).map(key => (
                                                <CardHistoriesBookingOrder
                                                    key={key}
                                                    orderCode={key}
                                                    dataMapUser={dataMapUser}
                                                    listOrderStatus={listOrderStatus}
                                                    dataOrderHistories={dataMapOrderHistories.get(key)}
                                                />
                                            ))
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </>
    );
};

export default BookingRefundOrderDetailContainer;
