import { Card, Grid } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import CardItemHotelInfo from "./cardItemHotelInfo";
import CardStatusInfo from "../FlightBooking/cardStatusInfo";
import CardPaymentInfo from "../FlightBooking/cardPaymentInfo";
import CardListMemberInfo from "../FlightBooking/cardListMemberInfo";
import OrderService, { IRecordOrder } from "services/sale/order.service";
import RequestBookingService from "services/booking/requestBooking.service";

import { BookingDetailAmendType, ConfirmStatus, PaymentStatus } from "constants/enum";
import useDataRequestBooking, { IDetailBooking } from "components/Booking/useDataRequestBooking.hook";



interface IProps {
    onGoBack: () => void;
    dataDetail?: IDetailBooking;
    refetchDetail: () => Promise<void>;
}

const orderService = new OrderService();
const requestBookingService = new RequestBookingService();

const HotelBookingDetailContainer = ({ dataDetail, onGoBack, refetchDetail }: IProps) => {

    const { VALUE_NULL, getDataMapUserByIds } = useDataRequestBooking();

    const [dataUserProfile, setDataUserProfile] = useState<Map<string, any>>(new Map());

    const [bookingOrderDetail, setBookingOrderDetail] = useState<IRecordOrder | undefined>(undefined);
    const [isBookingOrderDetailLoading, setIsBookingOrderDetailLoading] = useState<boolean>(true);

    const getOrderDetail = useCallback(async (orderId?: string) => {
        if (orderId) {
            try {
                setIsBookingOrderDetailLoading(true);
                const response = await orderService.getDetailOrderIncludeSubOrder(orderId);
                setBookingOrderDetail(response);
            } catch (e) {
                console.error(e);
            } finally {
                setIsBookingOrderDetailLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        getOrderDetail(dataDetail?.bookingOrderId);
    }, [dataDetail?.bookingOrderId, getOrderDetail]);

    useEffect(() => {
        (async () => {
            if ([...dataDetail?.members || []].length > 0) {
                const result = await getDataMapUserByIds(dataDetail?.members, dataDetail?.organizationId);
                setDataUserProfile(result);
            }
        })()
    }, [dataDetail?.members, dataDetail?.organizationId]);

    const newBookingDetails = useMemo(() => (
        [...dataDetail?.bookingDetails || []].filter(el => el.amendType === 0) || []
    ), [dataDetail?.bookingDetails]);

    const bookingUsers = useMemo(() => (
        Array.from(dataUserProfile.values())
    ), [dataUserProfile]);

    const serviceFee = bookingOrderDetail?.serviceFee ?? 0;
    const serviceFeeTax = bookingOrderDetail?.serviceFeeTax;
    const rawServiceFee = serviceFee - serviceFeeTax;

    //#region Hanlde Retry Issue Booking
    const [count, setCount] = useState<number>(3);
    const [reservationCode, setReservationCode] = useState("");

    useEffect(() => {
        const itemFirst = dataDetail?.bookingDetails?.filter(d => d.amendType === BookingDetailAmendType.Normal)?.[0]?.bookingReferenceCodes?.[0];

        if (
            !Helpers.isNullOrEmpty(itemFirst?.referenceCode) &&
            Helpers.isNullOrEmpty(itemFirst?.reservationCode) &&
            (Number(dataDetail?.itineraryDetailConfirmStatus) === ConfirmStatus.Completed) &&
            (
                Number(dataDetail?.paymentStatus) >= PaymentStatus.Completed &&
                Number(dataDetail?.paymentStatus) < PaymentStatus.WaitingRefund
            )
        ) {
            setCount(3);
        } else {
            setCount(0);
        }
    }, [
        dataDetail?.paymentStatus,
        dataDetail?.bookingDetails,
        dataDetail?.itineraryDetailConfirmStatus,
    ]);

    useEffect(() => {
        if (count > 0) {
            let newRetryInterval = setInterval(() => {
                retryGetReservationCode();
            }, 30 * 1000);
            return () => clearInterval(newRetryInterval);
        }
    }, [count]);

    const retryGetReservationCode = async () => {
        try {
            const newResult = await getReservationCode(dataDetail?.id);

            if (Helpers.isNullOrEmpty(newResult)) {
                const newCount = Number(count) - 1;

                if (newCount === 0) {
                    setReservationCode(VALUE_NULL);
                };

                setCount(newCount);

                return;
            } else {
                if (newResult?.status === "paid") {
                    const newCount = Number(count) - 1;

                    if (newCount === 0) {
                        setReservationCode(VALUE_NULL);
                    };

                    setCount(newCount);

                    return
                } else if (newResult?.status === "cancelled") {
                    setCount(0);

                    await refetchDetail();

                    return;
                } else if (newResult?.status === "in-progress") {
                    setCount(0);

                    !Helpers.isNullOrEmpty(newResult?.reservationCode)
                        && setReservationCode(newResult?.reservationCode || VALUE_NULL);

                    return;
                } else if (newResult?.status === "completed") {
                    setCount(0);

                    !Helpers.isNullOrEmpty(newResult?.reservationCode)
                        && setReservationCode(newResult?.reservationCode || VALUE_NULL);

                    return;
                } else {
                    const newCount = Number(count) - 1;

                    if (newCount === 0) {
                        setReservationCode(VALUE_NULL);
                    };

                    setCount(newCount);

                    return
                }
            }
        } catch (error) {
            const newCount = Number(count) - 1;

            if (newCount === 0) {
                setReservationCode(VALUE_NULL);
            };

            setCount(newCount);
        }
    };

    const getReservationCode = async (
        bookingId: string
    ): Promise<
        | {
            reservationCode?: string;
            status?: "in-progress" | "cancelled" | "completed" | "paid";
        }
        | undefined
    > => {
        try {

            const result = await requestBookingService.retrieveBooking(bookingId);

            if (result?.booking?.status === "in-progress"
                && !Helpers.isNullOrEmpty(result?.booking?.confirmation_ids)) {
                return {
                    reservationCode: "",
                    status: "in-progress",
                };
            } else if (result?.booking?.status === "paid") {
                return {
                    status: "paid",
                    reservationCode: "",
                };
            } else if (["failed", "expired", "cancelled"].includes(result?.booking?.status)) {
                return {
                    status: "cancelled",
                    reservationCode: "",
                };
            } else if (result?.booking?.status === "completed" || result?.booking?.status === "complete") {
                return {
                    status: "completed",
                    reservationCode: result?.booking?.confirmation_ids?.join(";"),
                };
            } else {
                return undefined;
            };
        } catch (error) {
            return undefined;
        }
    };
    //#endregion Hanlde Retry Issue Booking

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button color={"secondary"} onClick={() => { onGoBack(); }}>
                        {Strings.Common.GO_BACK}
                    </Button>
                </Box>
            </Grid>

            {/* Status Information */}
            <Grid item xs={12}>
                <CardStatusInfo
                    data={dataDetail}
                    order={bookingOrderDetail}
                    isBookingOrderLoading={isBookingOrderDetailLoading}
                    refetchDetail={async (args) => {
                        if (args?.needRefetchBookingOrder) {
                            await Promise.all([
                                getOrderDetail(dataDetail?.bookingOrderId),
                                refetchDetail(),
                            ])
                        } else {
                            refetchDetail();
                        }
                    }}
                />
            </Grid>

            {/* Itinerary Information */}
            <Grid item xs={12}>
                <Card>
                    <Box p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <Typography variant="h6">
                                    {`${Strings.BOOKING.BOOKING_INFO_TITLE} (${newBookingDetails?.length})`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    [...newBookingDetails || []].map(item => (
                                        <CardItemHotelInfo
                                            data={item}
                                            key={item?.id}
                                            reservationCode={reservationCode}
                                            dataUserProfile={dataUserProfile}
                                            isShowReservationCode={
                                                Number(dataDetail?.paymentStatus) >= PaymentStatus.Completed &&
                                                Number(dataDetail?.paymentStatus) < PaymentStatus.WaitingRefund &&
                                                Number(dataDetail?.itineraryDetailConfirmStatus) === ConfirmStatus.Completed
                                            }
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Grid>

            {/* Member Information */}
            <Grid item xs={12}>
                <CardListMemberInfo dataUserProfile={dataUserProfile} />
            </Grid>

            {/* Payment Information */}
            <Grid item xs={12}>
                <CardPaymentInfo
                    type={dataDetail?.type}
                    data={{
                        members: dataDetail?.members,
                        currency: dataDetail?.currency,
                        serviceFee: rawServiceFee || 0,
                        serviceFeeTax: serviceFeeTax || 0,
                        paymentFee: bookingOrderDetail?.paymentFee || 0,
                        totalAmount: bookingOrderDetail?.amount || (dataDetail?.totalAmount + serviceFee) || 0,
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default HotelBookingDetailContainer;