import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import AmendBookingModule from "components/Booking/Amend";

import { Mode } from "constants/enum";
import { DashboardLayout } from "layout";



const AmendBookingScreen = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const idDetail: string = searchParams.get("id");
    const route = [{ title: Strings.BOOKING.TITLE_MENU_AMEND, route: "" }];

    const handleGoBack = () => {
        navigate(Screens.AMEND_BOOKING_LIST);
    };

    return (
        <DashboardLayout
            isPermission={true}
            route={route}
        >
            <AmendBookingModule
                keyModuleContainer={{
                    "list": {
                        pathName: Screens.AMEND_BOOKING_LIST,
                    },
                    "detail": {
                        pathName: Screens.AMEND_BOOKING_VIEW,
                    },
                }}
                onNavigate={(data) => {
                    if (data.id) {
                        const query = Helpers.handleFormatParams({
                            ...data,
                            amend: 1,
                        });
                        if (data.mode === Mode.View) {
                            navigate(Screens.AMEND_BOOKING_VIEW + `?${query}`);
                        } else {
                            navigate(Screens.AMEND_BOOKING_VIEW + `?${query}`);
                        }
                    }
                }}

                idDetail={idDetail}
                onGoBack={handleGoBack}
            />

        </DashboardLayout>
    );

}

export default AmendBookingScreen;