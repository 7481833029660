import { useEffect, useState } from "react";
import { KeyModuleScreen, IPropModuleContainer } from "@maysoft/common-component-react";

import BookingOrderListContainer from "./listBookingOrder";
import BookingOrderDetailContainer from "./DetailBookingOrder";
import { IReqGetPagedOrder } from "services/sale/order.service";



const ModuleBookingOrder = (props: IPropModuleContainer<IReqGetPagedOrder> & {
    bookingId?: string;
    hidenActionExport?: boolean;
}) => {
    const currentPath = window.location.pathname || "";

    const [idDetail, setIdDetail] = useState<string | undefined>(undefined);
    const [bookingId, setBookingId] = useState<string | undefined>(undefined);

    useEffect(() => { setIdDetail(props.idDetail); }, [props.idDetail]);
    useEffect(() => { setBookingId(props.bookingId); }, [props.bookingId]);

    const NewComponentTemp = (
        <BookingOrderDetailContainer
            mode={props.mode}
            idDetail={idDetail}
            bookingId={bookingId}

            onChangeMode={props.onChangeMode}
            onGoBack={() => {
                setIdDetail(undefined);
                setBookingId(undefined);
                props.onGoBack();
            }}
        />
    );

    const listContainers: { [key in KeyModuleScreen]?: JSX.Element; } = {
        "list": (
            <BookingOrderListContainer
                hidenActionExport={props.hidenActionExport}

                onGetPaged={props.onGetPaged}
                requestGetPaged={props.requestGetPaged}
                onNavigate={(data) => {
                    props.onNavigate(data);
                }}
            />
        ),
        "create": NewComponentTemp,
        "detail": NewComponentTemp,
        "update": NewComponentTemp,
    };

    const renderContainerView = () => {
        const itemFind = Object.keys(props.keyModuleContainer).find((key) => ((props.keyModuleContainer as any)[key]?.pathName === currentPath));
        return (listContainers as any)[itemFind];
    };

    return <>{renderContainerView()}</>;
};

export default ModuleBookingOrder;
