import Helpers from "commons/helpers";
import Constants from "constants/index";
import BookingService from "./booking.service";
import { IGetPaged } from "commons/interfaces";



export interface IRequestGetBookingOrder {
    paymentStatusMain?: number[];
    paymentStatusSubOrder?: number[];
    groupId?: string[];
    groupManager?: string[];
    memberIds?: string[];
    searchText?: string;
    code?: string;
    startTime?: string | number;
    endTime?: string | number;
    amendType?: number;
    bookingType?: number;
    pageNumber?: number;
    pageSize?: number;
    orderby?: string;
};

export interface IRecordBookingOrder {
    amendType: number
    itineraryId: string
    totalAmount: number
    reservationCode: string
    groupIds: string[]
    bookingType: number
    paymentMethod: number
    amountBooking: number
    organizationId: string
    organizationName: string
    confirmStatusBooking: number
    statusBooking: number
    expiryDate: string
    itineraryDetailConfirmStatus: number
    type: number
    bookingId: string
    bookingDetailId: string
    orderId: string
    orderCode: string
    paymentStatus: number
    referenceOrderId: string
    id: string
    status: number
    createTime: string
    createUser: string
    updateTime: string
    updateUser: string
    extraInfoDetails: string[]
    referenceCodes: IBookingReferenceCode[]
    referenceTime: string
};

export interface IBookingReferenceCode {
  id: string
  bookingDetailId: string
  referenceCode: string
  reservationCode: any
  expiryDate: string
  extraInfo: any
}

class BookingOrderService extends BookingService {

    public getPaged = async (data: IRequestGetBookingOrder): Promise<IGetPaged<IRecordBookingOrder>> => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.get({
            path: `${Constants.ApiPath.BOOKING_ORDER.GET_PAGED}?${query}`,
        });
        return result.data.result;
    };

    public getOrderPaged = async (data: IRequestGetBookingOrder): Promise<IGetPaged<IRecordBookingOrder>> => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.get({
            path: `${Constants.ApiPath.BOOKING_ORDER.GET_ORDER_PAGED}?${query}`,
        });
        return result.data.result;
    };

    public exportCsv = async (data: IRequestGetBookingOrder) => {
        const query = Helpers.handleFormatParams(data);
        const result = await this.api.getExcel({
            path: Constants.ApiPath.BOOKING_ORDER.EXPORT_CSV + `?${query}`
        });

        return new Blob([result.data]);
    };

};

export default BookingOrderService;
