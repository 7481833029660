import { Box, Typography } from "@maysoft/common-component-react";
import { ICodename } from "commons/interfaces";
import { IBookingDetailAmendHistorySectionProps } from "components/Booking/DetailContainer/interfaces";
import OperationHistoryBookingText from "../components/OperationHistoryBookingText";
import OperationHistoryOrderDataText from "../components/OperationHistoryOrderDataText";
import OperationHistorySectionTitle from "../components/OperationHistorySectionTitle";
import { BookingHelpers } from "commons/bookingHelpers";
import { BookingDetailAmendType } from "constants/enum";
import Strings from "constants/strings";

const TransferAmendHistory = ({ index, details, userMap, createTime, cabinClassCodenames, currency, amount, createUser, paymentMethod, orderCode }: IBookingDetailAmendHistorySectionProps & { cabinClassCodenames: ICodename[]; }) => {
    const renderTransferFlightItem = (title: string, value: string) => {
        return value && <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="caption" component="span">{title}</Typography>
            <Typography variant="button" component="span" textTransform="none" fontWeight="medium" whiteSpace="pre-line">{value}</Typography>
        </Box>
    }
    return (
        <Box display="flex" flexDirection="column" p={2} gap={1}>
            <OperationHistorySectionTitle
                title={Strings.BOOKING.ADD_SERVICE_TRANSFER_FLIGHT}
                fullName={createUser?.fullName}
                updateTime={createTime}
                index={index}
            />
            <Box px={2} display="flex" flexDirection="column" gap={2}>
                <OperationHistoryOrderDataText
                    amendType={BookingDetailAmendType.Transfer}
                    amount={amount}
                    currency={currency}
                    paymentMethod={paymentMethod}
                    orderCode={orderCode}
                />
                
                <Typography variant="button" textTransform="none" fontWeight="bold">{Strings.BOOKING.AMEND_BOOKING.NEW_FLIGHT_INFORMATION}: </Typography>  

                {details.map(d => {
                    const extraInfoParsed = d.amendExtraInfoParsed;
                    return extraInfoParsed.users.map(user => {
                        const { cabinClass, departDate, reservationCode, arrivalPlaceCity, arrivalPlace, departPlaceCity, departPlace, seatNumber, baggages = [], getTicketNumberByUserId } = BookingHelpers.getFlightExtraInfo(d, user.id);
                        const ticketNumber = getTicketNumberByUserId(user.id);

                        const userInformation = userMap[user.id];
                        const cabinClassText = cabinClassCodenames.find(c => c.code === cabinClass)?.name ?? cabinClass;
                        return <Box display="flex" flexDirection="column" gap={1} key={`${d.id}_${user.id}`}>
                                <OperationHistoryBookingText 
                                    departTime={departDate}
                                    arrivalPlace={arrivalPlaceCity || arrivalPlace}
                                    departPlace={departPlaceCity || departPlace}
                                    reservationCode={reservationCode}
                                />

                                <Box display="grid" sx={{
                                    gridTemplateColumns: "repeat(5, 1fr)", 
                                    gridTemplateRows: "1fr auto",
                                    maxWidth: "1000px",
                                    "@media (max-width: 900px)": {
                                        gridTemplateColumns: "repeat(3, 1fr)", 
                                    },
                                    "@media (max-width: 600px)": {
                                        gridTemplateColumns: "repeat(2, 1fr)", 
                                    },
                                }} gap={2}>
                                    <Typography variant="button" fontWeight="bold"
                                        sx={{
                                            gridRow: "1 / -1"
                                        }} 
                                    >{userInformation.fullName}</Typography>  
                                    {renderTransferFlightItem(Strings.BOOKING.BAGGAGE, (typeof baggages === "string" ? [baggages] : baggages).join(", "))}
                                    {renderTransferFlightItem(Strings.BOOKING.CABIN_CLASS, cabinClassText)}
                                    {renderTransferFlightItem(Strings.BOOKING.SEAT_NUMBER, seatNumber)}
                                    {renderTransferFlightItem(Strings.BOOKING.TICKET_NUMBER, ticketNumber)}
                                </Box>
                        </Box>
                    })
                })}
            </Box>
        </Box> 
    )
}

export default TransferAmendHistory;