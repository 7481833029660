import { HotelOutlined, LocationCityOutlined, Star } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { Box, Typography, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import useDataRequestBooking, { IItineraryExtraInfo } from "../../useDataRequestBooking.hook";

import { IBookingDetail } from "../../useDataRequestBooking.hook";
import { ICodename } from "commons/interfaces";
import { Gender } from "constants/enum";



const CardItemHotelInfo = (props: {
    data: IBookingDetail,
    reservationCode?: string;
    isShowReservationCode?: boolean,
    dataUserProfile: Map<string, any>
}) => {
    const {
        language,
    } = useCommonComponentContext();

    const {
        VALUE_NULL,
        getDateNameFormat,
    } = useDataRequestBooking();

    const [passengers, setPassengers] = useState<any[]>([]);
    const [reservationCode, setReservationCode] = useState("");

    const extraInfo: IItineraryExtraInfo = useMemo(() => Helpers.toCamelCaseObj(Helpers.converStringToJson(props.data?.extraInfo)), [props.data]);

    const genderList: ICodename[] = [
        { code: Gender.Male, name: Strings.Common.MALE },
        { code: Gender.Female, name: Strings.Common.FEMALE },
    ];

    useEffect(() => {
        const item = [...props?.data?.bookingReferenceCodes || []].find(el => el.bookingDetailId === props.data?.id);
        if (Helpers.isNullOrEmpty(item)) {
            setReservationCode(props.reservationCode);
        } else {
            setReservationCode(item?.reservationCode);
        }
    }, [
        props.data?.id,
        props.reservationCode,
        props.data?.bookingReferenceCodes,
    ]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Box sx={{
                    borderRadius: "4px",
                    padding: "12px 16px",
                    backgroundColor: "#EBF0FF",
                }}>
                    <Box sx={{
                        gap: 2,
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "end",
                    }}>
                        <LocationCityOutlined />
                        <Typography variant="h5">
                            {extraInfo?.hotelInfo?.partnerName?.value?.[language] || "-/-"}
                        </Typography>
                    </Box>

                    <Typography variant="button" fontWeight="bold">
                        {`${getDateNameFormat(extraInfo?.hotelInfo?.checkinDate * 1000)}`}
                    </Typography>

                    {props.isShowReservationCode &&
                        <Box>
                            <Box sx={{
                                gap: 1,
                                px: 1.5,
                                py: 0.75,
                                borderRadius: 1,
                                alignItems: "center",
                                display: "inline-flex",
                                justifyContent: "center",
                                backgroundColor: "#FFFFFF",
                            }}>
                                <Typography variant="button" sx={{ gap: 1, display: "flex", alignItems: "center" }}>
                                    {`${Strings.BOOKING.RESERVATION_CODE}: `}
                                    {!Helpers.isNullOrEmpty(reservationCode) &&
                                        <Typography variant="button" fontWeight="bold">
                                            {(reservationCode === VALUE_NULL) ? "" : reservationCode}
                                        </Typography>
                                    }
                                    {Helpers.isNullOrEmpty(reservationCode) &&
                                        <Box sx={{ display: "flex", height: "1rem" }}>
                                            <CircularProgress size={"1rem"} />
                                        </Box>
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    }
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ gap: 2, display: "flex" }}>
                    <Box
                        component={"img"}
                        src={extraInfo?.hotelInfo?.avatarUrl}
                        sx={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "8px",
                            border: "1px #c3c3c3 solid",
                        }}
                    ></Box>
                    <Box display="grid">
                        <Box display="grid">
                            <Typography variant="button" fontWeight="bold">
                                {extraInfo?.hotelInfo?.partnerName?.value?.[language] || "-/-"}
                            </Typography>
                            <Typography variant="caption" color="secondary">
                                {extraInfo?.hotelInfo?.description || "-/-"}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {Array.from({ length: extraInfo?.hotelInfo?.starRating }).map((el, index) => (
                                <Star key={index} htmlColor="#1E97DE" sx={{ width: "18px", height: "18px" }} />
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Box paddingTop={3} paddingLeft={"86px"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3} lg={4}>
                            <Box display="grid">
                                <Box display="grid">
                                    <Typography variant="button">
                                        {extraInfo?.hotelInfo?.product?.name?.value?.[language] || "-/-"}
                                    </Typography>
                                    <Typography variant="caption" color="secondary">
                                        {[...extraInfo?.hotelInfo?.product?.beds || []].map(el => el.description).join(", ") || "-/-"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box display="grid">
                                <Box display="grid">
                                    <Typography variant="button">
                                        {"Nhận phòng:"}&ensp;
                                        <Typography variant="button" fontWeight="bold">
                                            {`${getDateNameFormat(extraInfo?.hotelInfo?.checkinDate * 1000)}`}
                                        </Typography>
                                    </Typography>
                                    <Typography variant="button">
                                        {"Trả phòng:"}&ensp;&emsp;
                                        <Typography variant="button" fontWeight="bold">
                                            {`${getDateNameFormat(extraInfo?.hotelInfo?.checkoutDate * 1000)}`}
                                        </Typography>
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default CardItemHotelInfo;