import moment from "moment";
import Helpers from "commons/helpers";
import Constants from "constants/index";
import Strings from "constants/strings";
import OrderService from "services/sale/order.service";
import UserService from "services/identity/user.service";
import useAdministrativeDivision from "hooks/useAdministrativeDivision";
import OrganizationService from "services/identity/organization.service";

import { ICodename, IMultiLanguageContent } from "commons/interfaces";
import { ConfirmStatus, ItineraryType, PaymentStatus, Status } from "constants/enum";



// #region Interface Booking
export interface IPersionalInfo {
    id?: string;
    name?: string;
    email?: string;
    phoneNumber?: string;
}

export interface IDetailBooking {
    seller: string;
    sellerName: string;
    sellerEmail: string;
    sellerPhoneNumber: string;

    buyer: string;
    buyerName: string;

    brandId: string;
    brandName: string;
    brandAddress: string;

    manufacturerId: string;
    manufacturerName: string;

    supplierId: string;
    supplierName: string;
    supplierCode: string;

    tenantCode: string;
    serviceFee: number;
    serviceCode: string;
    organizationId: string;
    organization: {
        id: string
        type: number
        ownerId: string
        tenantCode: string
        tenantName: string
        name: IMultiLanguageContent
        description: IMultiLanguageContent
        organizationProfiles: IOrganizationProfile[]
    };

    bookingCode: string;
    bookingOrderId: string;
    bookingDetails: IBookingDetail[];

    name: string;
    email: string;
    phoneNumber: string;

    endTime: string;
    startTime: string;

    totalAmount: number;
    quoteAmount: number;

    adultSlot: number;
    childrenSlot: number;
    childrenSlotDetail: any;

    location: string;
    orderId: string;
    currency: string;
    description: string;
    referenceCode: string;
    confirmStatus: number;
    bookingQuantity: number;

    extraInfo: string;
    members?: string[];
    needApprove: number;
    paymentStatus: number;
    itineraryDetailConfirmStatus: number;

    id: string;
    type: number;
    possessionKey?: string;
    status: number;
    createUserName: string;
    createTime: string;
    createUser: string;
    updateTime: string;
    updateUser: string;
};

export interface IOrganizationProfile {
    id: string
    email: string
    name: IMultiLanguageContent
    description: IMultiLanguageContent
    faxNumber: string
    taxNumber: string
    timeZone: string
    location: string
    currency: string
    addressId: string
    phoneNumber: string
}

export interface IBookingDetail {
    type: ItineraryType;
    itemName: any;
    itemSku: any;
    itemStatus: number;
    itemAttributes: any[];
    itemPhotos: any;
    itineraryType?: number;
    bookingId: string;
    itemId: string;
    description: any;
    unitPrice: number;
    unitCost: number;
    quantity: number;
    amount: number;
    amendType: number;
    extraInfo: string;
    id: string;
    status: number;
    createTime: string;
    createUser: string;
    updateTime: string;
    updateUser: string;
    referenceId: string;

    bookingReferenceCodes?: IBookingReferenceCode[];
}

export interface IBookingReferenceCode {
    type: number
    bookingId: string
    bookingDetailId: string
    referenceCode: string
    reservationCode: string
    expiryDate: string
    extraInfoExternal: any
    extraInfo: string
    id: string
    status: number
    createTime: string
    createUser: string
    updateTime: string
    updateUser: string
}

export interface IFreeBaggage {
    name: string;
    code: string;
    isHandBaggage: boolean;
    is_hand_baggage: boolean;
    quantity: number;
    paxType: number;
    pax_type: number;
}

export interface IFlightCarrierObj {
    code: string;
    logoUrl: string;
    name: {
        en: string;
        vi: string;
    };
}

export interface IFlightPlaceObj {
    id: string;
    code: string;
    name: string;
    country: string;
    countryCode: string;
    city: string;
    cityCode: string;
    timezone: string;
}

export interface ISegmentsList {
    index: number
    departPlace: string
    arrivalPlace: string
    arrivalDate: number
    departDate: number
    drrivalPlace: string
    drrivalPlaceName: any
    departPlaceName: any
    departPlaceObj: IFlightPlaceObj
    arrivalPlaceObj: IFlightPlaceObj
    carrierOperator: string
    carrierOperatorName: any
    carrierMarketingObj: IFlightCarrierObj;
    carrierOperatorObj: IFlightCarrierObj;
    flightNumber: string
    aircraft: string
    flightDuration: number
    departTerminal: string
    arrivalTerminal: string
    departDT: string
    arrivalDT: string
}

export interface IRequestCreateBooking {
    serviceCode: string
    organizationId: string

    id?: string
    type: number

    buyer: string
    seller: string

    supplierId: string
    supplierCode: string

    brandId: string
    manufacturerId: string

    email: string
    lastName: string
    firstName: string
    phoneNumber: string
    countryPhonenumber: string

    endTime: number
    startTime: number
    location: string
    currency: string
    extraInfo: string
    propertyId: string
    description: string

    adultSlot: number
    childrenSlot: number
    childrenSlotDetail: string

    bookingCode: string
    bookingQuantity: number
    bookingDetailRequests: IBookingDetailRequest[]
}

export interface IBookingDetailRequest {
    id?: string
    roomId: string
    itemId: string
    orderId?: string
    quantity: number
    bedGroupId: string
    occupancy: {
        adultSlot: number
        childrenOld: number[]
    }[]
    bookingInfoRequest: {
        firstName: string
        lastName: string
    }[]
    type: number
    extraInfo: string
    bookingNo: string
    bookingClass: string
}

export interface IResponseUpdateBooking {
    id: number
    possessionKey?: string
    type: number
    serviceCode: string
    organizationId: number
    seller: number
    buyer: number
    supplierId: number
    manufacturerId: number
    brandId: number
    firstName: string
    lastName: string
    phoneNumber: string
    countryPhonenumber: string
    email: string
    startTime: number
    endTime: number
    bookingQuantity: number
    adultSlot: number
    childrenSlot: number
    childrenSlotDetail: string
    description: string
    location: string
    currency: string
    supplierCode: string
    bookingCode: string
    extraInfo: string
    propertyId: string
    bookingDetailRequests: {
        id: number
        orderId: number
        roomId: string
        bedGroupId: string
        itemId: number
        quantity: number
        occupancy: {
            adultSlot: number
            childrenOld: number[]
        }[]
        type: number
        flightType: number
        bookingClass: string
        bookingNo: string
        extraInfo: string
    }[]
}

export interface IItineraryExtraInfo {
    lastTicketDate: number;
    occupancy: IOccupancy[];
    passengers: IPassenger[];
    isInternational: boolean;
    hotelInfo: IExtraInfoProperty;
    issueTicketInfo: IIssueTicketInfo;
    flightInfo: IFlightExtraInformation;
};

export interface IIssueTicketInfo {
    error_code: any;
    is_success: boolean;
    booking: {
        status: number;
        last_ticket_date: string;
        itineraries: {
            leg: number;
            e_tickets: IETicket[];
            reservation_code: string;
            free_baggages: IFreeBaggage[];
        }[];
    };
}

export interface IETicket {
    pax_id: string;
    pax_name: string;
    ticket_number: string;
}

export interface IPassenger {
    id: string;
    dob: string;
    type: string;
    gender: string;
    loyalty_id: string;
    last_name: string;
    first_name: string;
    nationality: string;
    passport_number: string;
    passport_exp_date: string;
    passport_issuing_country: string;
}

export type IFlightExtraInformation = IFlightDetail & IAdditionalExtraBookingDetail;

export type IFlightDetail = {
    searchKey: string;
    flightId: string;
    carrierOperator: string;
    flightNumber: string;
    cabinClass: string;
    bookingClass: string;
    flightDuration: number;
    departDate: number;
    departDT?: string;
    departPlace: string;
    arrivalPlace: string;
    arrivalDT?: string;
    arrivalDate: number;
    departPlaceObj: IFlightPlaceObj;
    arrivalPlaceObj: IFlightPlaceObj;
    carrierOperatorObj: IFlightCarrierObj;
    carrierMarketingObj: IFlightCarrierObj;
    flightType: number;
    totalFareAmount: number;
    totalFareBasic: number;
    totalTaxAmount: number;
    currency: string;
    passengerQuantity: IPassengerQuantity;
    segmentsList: ISegmentsList[];
    freeBaggage: IFreeBaggage[];
    departPlaceName: string;
    arrivalPlaceName: string;
};

export type IAdditionalExtraBookingDetail = {
    supplierCode: string;
    occupancy: IOccupancy[];
    isInternational: boolean;
    leg: number;
    sessionId: string;
    confirmedPrice: number;
    bookingCode: string;

    amount: number;
    lastTicketDate: string;
    resultBookingErr: string;
    userPassenger: { [key: string]: number };
    issueTicketInfo: any;
};

export interface IOccupancy {
    adultSlot: number;
    quantity?: number;
    userIds?: string[];
    childrenOld: number[];
};

export interface IPassengerQuantity {
    adt: number;
    chd: number;
    inf: number;
};

export interface IExtraInfoProperty {
    propertyId: string;
    partnerName: IMultiLanguageContent;
    avatarId: string;
    avatarUrl: string;
    starRating: number;
    description: string;
    product: {
        id: string;
        beds: IBed[];
        avatarId: string;
        avatarUrl: string;
        userInfos: IRoomUserInfo[];
        name: IMultiLanguageContent;
        cancelPenalties: ICancelPenalty[];
    };
    checkinDate: number;
    checkinTime: string;
    checkoutDate: number;
    checkoutTime: string;
    nightQuantity: number;
    refundable: boolean;
    bedGroupId?: string;
    phoneNumber: string;
    price?: IPrice;
    address: IAddress;
    amenities: string[];
    geographicRegionCodes: string[];
    checkinSpecialInstructions: IDetailPropertyAttribute[];
};

export interface IDetailPropertyAttribute {
    id: string;
    partnerId: string;
    attributeCode: string;
    attributeValue: string;
    attributeValueTagId: string;
    attributeValueTagName: string;
    attributeValueReferenceId: string;
    attributeValueTagReferenceId: string;
    type: number;
    valueType: number;
    organizationId: string;
    value: IMultiLanguageContent;
    title: IMultiLanguageContent;
}

export interface IRoomUserInfo {
    users: { userId: string; fullName: string }[];
}

export interface IBed {
    id: string;
    quantity: number;
    description: string;
};
export interface ICancelPenalty {
    startTime: string;
    endTime: string;
    amount: number;
    nights: string;
    percent: string;
    currency: string;
};

export interface IPrice {
    night: number;
    directPay: IDirectPay;
    taxAndServiceFee: number;
    payAtProperty: IDirectPay;
};

export interface IDirectPay {
    fees?: IFees;
    totalPrice: number;
    pricePerNight: number;
    perNightStrikethrough: number;
}

export interface IFees {
    resortFee: IMandatoryFax;
    mandatoryFee: IMandatoryFax;
    mandatoryFax: IMandatoryFax;
}

export interface IMandatoryFax {
    price: number;
    currency: string;
}

export interface IAddress {
    id: string;
    addressLine: string;
    addressLine2: string;
    ward: string;
    wardName: string;
    district: string;
    districtName: string;
    city: string;
    cityName: string;
    province: string;
    provinceName: string;
    country: string;
    postalCode: string;
    longitude: number;
    lattitude: number;
    countryCode: string;

    countryName: IMultiLanguageContent;
    wardResponseName: IMultiLanguageContent;
    cityResponseName: IMultiLanguageContent;
    districtResponseName: IMultiLanguageContent;
    provinceResponseName: IMultiLanguageContent;
    provinceCode: string;
    cityCode: string;
    tenantCode: string;
    organizationId: string;

    obfuscatedLongitude: number;
    obfuscatedLattitude: number;
    status: number;
    createTime: string;
    createUser: string;
    updateTime: string;
    updateUser: string;
};
// #endregion Interface Booking

enum EReservationType {
    Canceled = "canceled",
    Pending = "pending",
    WaitingConfirm = "waitingconfirm",
    Issued = "issued",
    WaitingRefund = "waitingrefund",
    Refunded = "refunded",
};

const userService = new UserService();

const useDataRequestBooking = () => {
    const { getCountries } = useAdministrativeDivision();

    const VALUE_NULL = "NULL";

    const listReservationType: ICodename[] = [
        {
            code: EReservationType.Canceled,
            name: Strings.STATUS.PAYMENT.Cancel,
        },
        {
            code: EReservationType.Pending,
            name: Strings.STATUS.PAYMENT.Pending,
        },
        {
            code: EReservationType.WaitingConfirm,
            name: Strings.STATUS.PAYMENT.WaitingConfirm,
        },
        {
            code: EReservationType.Issued,
            name: Strings.STATUS.PAYMENT.Issued,
        },
        {
            code: EReservationType.WaitingRefund,
            name: Strings.STATUS.PAYMENT.WaitingRefund,
        },
        {
            code: EReservationType.Refunded,
            name: Strings.STATUS.PAYMENT.Refunded,
        },
    ];

    const requestStatusList: ICodename[] = [
        {
            code: ConfirmStatus.Rejected,
            name: Strings.STATUS.CONFIRM_STATUS.Rejected,
        },
        {
            code: ConfirmStatus.Pending,
            name: Strings.STATUS.CONFIRM_STATUS.Pending,
        },
        {
            code: ConfirmStatus.Completed,
            name: Strings.STATUS.CONFIRM_STATUS.Completed,
        },
    ];

    const itineraryTypeList: ICodename[] = [
        {
            code: ItineraryType.Flight,
            name: Strings.BOOKING.FLIGHT,
        },
        {
            code: ItineraryType.Hotel,
            name: Strings.BOOKING.HOTEL,
        },
    ];

    const genReservationtype = (type: EReservationType) => {
        let out: any = {};

        switch (type) {
            case EReservationType.Canceled:
                out.hasOrderId = undefined;
                out.paymentStatus = undefined;
                out.expiryDate = moment().unix();
                out.status = [Status.Active, Status.Cancel];
                break;
            case EReservationType.Pending:
                out.hasOrderId = 0;
                out.expiryDate = undefined;
                out.status = [Status.Active];
                out.paymentStatus = undefined;
                break;
            case EReservationType.WaitingConfirm:
                out.status = [Status.Active];
                out.hasOrderId = undefined;
                out.expiryDate = undefined;
                out.paymentStatus = [PaymentStatus.New, PaymentStatus.WaitingConfirm];
                break;
            case EReservationType.Issued:
                out.status = [Status.Active];
                out.hasOrderId = undefined;
                out.expiryDate = undefined;
                out.paymentStatus = PaymentStatus.Issued;
                break;
            case EReservationType.WaitingRefund:
                out.status = [Status.Active];
                out.hasOrderId = undefined;
                out.expiryDate = undefined;
                out.paymentStatus = PaymentStatus.WaitingRefund;
                break;
            case EReservationType.Refunded:
                out.status = [Status.Active];
                out.hasOrderId = undefined;
                out.expiryDate = undefined;
                out.paymentStatus = PaymentStatus.Refunded;
                break;
            default:
                out.hasOrderId = undefined;
                out.expiryDate = undefined;
                out.paymentStatus = undefined;
                out.status = [Status.Active, Status.Cancel, Status.Reject, Status.Inactive];
                break;
        }
        return out;
    };

    // Trạng thái duyệt
    const getDataConfirmStatusByBooking = (status: ConfirmStatus) => {
        switch (status) {
            case ConfirmStatus.Rejected:
                return { icon: "block", color: "error", title: Strings.STATUS.CONFIRM_STATUS.Rejected, } as any;
            case ConfirmStatus.Processing:
                return { icon: "rotate_left_outlined", color: "warning", title: Strings.STATUS.CONFIRM_STATUS.Pending, } as any;
            case ConfirmStatus.Pending:
                return { icon: "rotate_left_outlined", color: "warning", title: Strings.STATUS.CONFIRM_STATUS.Pending, } as any;
            case ConfirmStatus.Completed:
                return { icon: "done", color: "info", title: Strings.STATUS.CONFIRM_STATUS.Completed, } as any;
            default:
                return { icon: "", color: "", title: "", } as any;
        }
    };

    // Trạng thái đặt chỗ
    const getCellReservationStatusByBooking = ({
        status,
        paymentStatus,
        lastTicketDate,
    }: {
        status?: Status,
        lastTicketDate?: number,
        paymentStatus: PaymentStatus,
    }) => {
        let newData: any = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };

        if (Helpers.isNullOrEmpty(paymentStatus)) {
            newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };
        } else {
            if (paymentStatus === PaymentStatus.New && (lastTicketDate < moment().unix())) {
                newData = { icon: "cancel", color: "secondary", title: Strings.STATUS.PAYMENT.Cancel };
            } else {
                switch (paymentStatus) {
                    case PaymentStatus.Cancel:
                        newData = { icon: "cancel", color: "secondary", title: Strings.STATUS.PAYMENT.Cancel };
                        break;

                    case PaymentStatus.Pending:
                        newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.Pending };
                        break;
                    case PaymentStatus.New:
                        newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.WaitingConfirm };
                        break;
                    case PaymentStatus.WaitingConfirm:
                        newData = { icon: "hourglass_empty", color: "warning", title: Strings.STATUS.PAYMENT.WaitingConfirm };
                        break;

                    case PaymentStatus.Completed:
                        newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Completed };
                        break;

                    case PaymentStatus.Failed:
                        newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Failed };
                        break;

                    case PaymentStatus.Issued:
                        newData = { icon: "check", color: "success", title: Strings.STATUS.PAYMENT.Issued };
                        break;

                    case PaymentStatus.WaitingPartialRefund:
                    case PaymentStatus.WaitingRefund:
                        newData = { icon: "currency_exchange", color: "secondary", title: Strings.STATUS.PAYMENT.WaitingRefund };
                        break;

                    case PaymentStatus.PartialRefunded:
                    case PaymentStatus.Refunded:
                        newData = { icon: "currency_exchange", color: "error", title: Strings.STATUS.PAYMENT.Refunded };
                        break;

                    default:
                        newData = { icon: "", color: "secondary", title: Strings.STATUS.PAYMENT.UNDEFINED };
                        break;
                }
            }
        }

        if (status === Status.Cancel) {
            newData = { icon: "cancel", color: "secondary", title: Strings.STATUS.PAYMENT.Cancel };
        };

        return newData;
    };

    const formatDate = (value?: string | Date | number, format?: string): string => {
        const result = value
            ? moment(value)
                .local()
                .format(format || "DD/MM/YYYY")
            : "";
        return result;
    };

    const formatDateName = (date: number, language: string): string => {
        let name = "";
        if (Helpers.isNullOrEmpty(date) || date === 0) {
            return name;
        } else {
            try {
                if (typeof Intl === "undefined") {
                    require("intl");
                    require("intl/locale-data/jsonp/vi");
                }
                const options: Intl.DateTimeFormatOptions = { weekday: "long" };

                name = new Intl.DateTimeFormat(language, options).format(date);
            } catch (error) {
                console.log("formatDateName", error);
            }
            return name;
        }
    };

    const formatMothName = (date: number, language: string): string => {
        let name = "";
        if (Helpers.isNullOrEmpty(date) || date === 0) {
            return name;
        } else {
            try {
                if (typeof Intl === "undefined") {
                    require("intl");
                    require("intl/locale-data/jsonp/vi");
                }
                const options: Intl.DateTimeFormatOptions = { month: "long" };

                name = new Intl.DateTimeFormat(language, options).format(date);
            } catch (error) {
                console.log("formatDateName", error);
            }
            return name;
        }
    };

    const getDateNameFormat = (time: number, language?: string) => {
        const day = `${formatDate(time, "DD")}`;
        const year = moment(time).format("YYYY");
        const dayName = `${formatDateName(time, language || "vi")}`;
        const month = `${formatMothName(time, language || "vi")}`;

        return [dayName, `${day} ${month} ${year}`].join(", ");
    };

    const getDataMapUserByIds = async (ids: string[], organizationId: string) => {
        try {
            const newDataCities: Map<string, string> = new Map();

            const newData: Map<string, {
                id: string,
                fullName: string,
                email: string;
                dateOfBirth: any;
                gender: number;
                phoneNumber: string;
                passportNo?: string;
                nationality?: string;
                passportExpiredDate?: string;
                passportIssuedPlace?: string;
            }> = new Map();

            await Promise.all([
                userService.getPaged(Helpers.handleFormatParams({
                    pageNumber: 1,
                    pageSize: ids.length,
                    listStatus: [1],
                    selectedIds: ids,
                    clientId: Constants.CLIENT_ID,
                    organizationId: organizationId,
                })),
                getCountries(),
            ]).then(async ([result, resultCities]) => {
                [...resultCities || []].forEach((item: any) => {
                    newDataCities.set(item.code, item.name);
                });

                [...result.selectedItems || []].forEach(el => {
                    let fullName = el.fullName || el.userName;

                    if (!Helpers.isNullOrEmpty(el.organizationUserProfile?.firstName)
                        || !Helpers.isNullOrEmpty(el.organizationUserProfile?.lastName)) {
                        fullName = `${el.organizationUserProfile?.lastName || ""} ${el.organizationUserProfile?.firstName || ""}`;
                    }

                    newData.set(el.id, {
                        id: el.id,
                        fullName: fullName,
                        email: el.organizationUserProfile?.email,
                        gender: el.organizationUserProfile?.gender,
                        dateOfBirth: el.organizationUserProfile?.dateOfBirth,
                        phoneNumber: el.organizationUserProfile?.phoneNumber,

                        passportNo: el.organizationUserProfile?.passportNo,
                        passportExpiredDate: el.organizationUserProfile?.passportExpiredDate,
                        nationality: newDataCities.get(el.organizationUserProfile?.nationality),
                        passportIssuedPlace: newDataCities.get(el.organizationUserProfile?.passportIssuedPlace),
                    });
                });
            }).catch(e => {
                throw e
            })

            return newData;

        } catch (error) { return new Map(); }
    };

    const getDataMapOrgaByIds = async (ids: string[]) => {
        try {

            const newIds = ids.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);

            const result = await new OrganizationService().getOrganizationByIds(newIds);

            const newData: Map<string, {
                id: string,
                name: string,
                code: string,
                email: string,
                phoneNumber: number,
            }> = new Map();

            [...result || []].forEach(el => {
                const itemOrgProfile = [...el.organizationProfiles || []].find(org => (org.parentGroup === "0"));

                newData.set(el.id, {
                    id: el.id,
                    code: el.organizationCode,
                    name: el.name?.value?.["vi"],
                    email: itemOrgProfile?.email,
                    phoneNumber: itemOrgProfile?.phoneNumber,
                });
            });

            return newData;

        } catch (error) { return new Map(); }
    };

    const getDataMapOrderPriceByIds = async (ids: string[]) => {
        try {

            const newIds = ids.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);

            const result = await new OrderService().getResponseByOrderIds(newIds);

            const newData: Map<string, {
                id: string,
                amount: number,
                orderCode: string,
                serviceFee: number,
                paymentFee: number,
                orderDate: string | number,
            }> = new Map();

            [...result || []].forEach(el => {
                newData.set(el.orderId, {
                    ...el,
                    id: el.orderId,
                });
            });

            return newData;

        } catch (error) { return new Map(); }
    };

    return {
        VALUE_NULL,
        requestStatusList,
        itineraryTypeList,
        listReservationType,

        genReservationtype,

        formatDateName,
        formatMothName,
        getDateNameFormat,

        getDataConfirmStatusByBooking,
        getCellReservationStatusByBooking,

        getDataMapUserByIds,
        getDataMapOrgaByIds,
        getDataMapOrderPriceByIds,

    };
};

export default useDataRequestBooking;