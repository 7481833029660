import { Card, Typography } from "@mui/material";
import { Box } from "@maysoft/common-component-react";

import Strings from "constants/strings";
import useCabinClassCodenames from "hooks/useCabinClassCodenames";
import RefundAmendHistory from "./operation/containers/RefundAmendHistory";
import TransferAmendHistory from "./operation/containers/TransferAmendHistory";
import AdditionServicesAmendHistory from "./operation/containers/AdditionServicesAmendHistory";
import useAdditionalServiceCodenames from "components/Booking/DetailContainer/PopupAmend/hooks/useAdditionalServiceCodenames";

import { BookingDetailAmendType } from "constants/enum";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";

const CardOperationHistory = () => {
    const cabinClassCodenames = useCabinClassCodenames();
    const itemCodenames = useAdditionalServiceCodenames();
    const { userMap, currency, order, referenceMap } = useAddMoreBookingServiceContext();

    const subOrders = (order?.subOrder || [])?.sort((a, b) => parseInt(a.orderDate) - parseInt(b.orderDate));

    const data = subOrders.map(o => {
        return {
            order: o,
            type: o.type,
            startTime: o.orderDate,
            data: (o.orderDetails || []).map(od => referenceMap[od.externalId]).filter(v => !!v),
        }
    }).filter(v => v.data.length > 0);

    return (
        <Card>
            <Box display="flex" flexDirection="column" component="section">
                <Box display="flex" flexDirection="column" bgColor="#EBF0FF" py={1} px={2}>
                    <Typography variant="h6">{Strings.BOOKING.AMEND_BOOKING.AMEND_HISTORY}</Typography>
                </Box>

                <Box
                    p={2}
                    gap={2}
                    display="flex"
                    flexDirection="column"
                    sx={{
                        "& > div": {
                            borderTop: "1px solid #E0E0E0"
                        },
                        "& > div:first-child": {
                            borderTop: "none"
                        },
                    }}>
                    {data.map((details, index) => {
                        const { type, data, order, startTime } = details;

                        const startTimeNumber = parseInt(startTime);

                        const amount = order?.amount || 0;

                        if (data.length === 0) return null;

                        const commonProps = {
                            amount,
                            userMap,
                            currency,
                            details: data,
                            index: index + 1,
                            orderCode: order?.orderCode,
                            createTime: startTimeNumber,
                            paymentMethod: order?.paymentMethod,
                        }
                        return (
                            <Box key={index} display="flex" flexDirection="column" gap={2}>
                                {type === BookingDetailAmendType.Transfer && (
                                    <TransferAmendHistory  {...commonProps} cabinClassCodenames={cabinClassCodenames} />
                                )}
                                {type === BookingDetailAmendType.AdditionService && (
                                    <AdditionServicesAmendHistory  {...commonProps} itemCodenames={itemCodenames} />
                                )}
                                {type === BookingDetailAmendType.PartialRefund && (
                                    <RefundAmendHistory  {...commonProps} />
                                )}
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        </Card>
    )
}

export default CardOperationHistory;