import Helpers from "commons/helpers";
import Constants from "../../constants";
import BookingService from "./booking.service";

export interface IResponseSystemSetting {
    settingCode: string,
    settingValue: string,
    tenantCode: string,
    serviceCode: string,
    organizationId: string,
    id: string,
    status: number,
    createTime: string,
    createUser: string,
    updateTime: string,
    updateUser: string
}

class SystemSettingService extends BookingService {

    public getSettingCode = async (data: {
        settingCode: string,
        tenantCode?: string,
        organizationId?: string,
    }) => {
        const query = Helpers.handleFormatParams({
            tenantCode: data.tenantCode,
            organizationId: data.organizationId,
        });

        const result = await this.api.get({
            path: Constants.ApiPath.SYSTEM_SETTING.GET_BY_SETTING_CODE + `/${data.settingCode}?${query}`,
        });

        return result.data.result;
    }


    public update = async (data: {
        id: string,
        serviceCode: string,
        settingCode: string,
        settingValue: string,
        organizationId: string,
    }) => {
        const result = await this.api.post({
            path: Constants.ApiPath.SYSTEM_SETTING.UPDATE,
            data: data
        });
        return result.data;
    }

}

export default SystemSettingService;