import { IBookingDetailAmend, IBookingDetailPopupUser, IService } from "../../interfaces";
import DeeptechAccordion from "../components/DeeptechAccordion";
import ContentBox from "../components/ContentBox";
import { Chip } from "@mui/material";
import { Box, Typography } from "@maysoft/common-component-react";
import AdditionalServiceItem from "./AdditionalServiceItem";
import { ICodename } from "commons/interfaces";
import AddIcon from "@mui/icons-material/Add";
import FlightIcon from "@mui/icons-material/Flight";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import { BookingHelpers } from "commons/bookingHelpers";
import { useRef } from "react";
import Strings from "constants/strings";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";

const UserAccordionForAdditionalServices = <D extends IBookingDetailPopupUser>(props: {
    data: D;
    serviceCodenames: ICodename[];
    services?: IService[];
    errors?: Partial<Record<string, string>>;
    onAddService: (user: IBookingDetailPopupUser, detail: IBookingDetail, segmentIndex: number) => void;
    onUpdateService?: (
        newService: IService,
        keyChanged: keyof IService
    ) => void;
    onRemoveService?: (service: IService, user: IBookingDetailPopupUser, detail: IBookingDetail) => void;
}) => {
    const { isUserBookingDetailRefunded, currency, getBookingDetailsByUserId } = useAddMoreBookingServiceContext();
    const { onUpdateService, data: user, errors = {}, services = [], serviceCodenames, onAddService, onRemoveService } = props;
    const getServicesByDetail = (detail: IBookingDetail, segmentIndex?: number) => {
        return services.filter((service) => service.bookingDetailId === detail.id && (segmentIndex ? service.segmentIndex === segmentIndex : true));
    }
    const getErrorsByServiceItem = (service: IService) => {
        return Object.entries(errors).reduce((prev, [key, value]) => {
            if (key.startsWith(service._id)) {
                prev[key] = value;
            }

            return prev;
        }, {} as typeof errors);
    }

    const countByDetail = useRef<Record<string, number>>({});

    const renderReachLimitActions = () => {
        return (
            <Typography variant="caption" color="error">
                {Strings.BOOKING.ADDITION_SERVICE_REACH_LIMIT}
            </Typography>
        )
    }
    const renderAddMoreServiceItemActions = ({ detail, segmentIndex }: { detail: IBookingDetailAmend, segmentIndex: number }) => {
        return onAddService && (
            <div>
                <Chip
                    color="primary"
                    variant="outlined"
                    sx={{ border: "none" }}
                    label={
                        <Typography
                            variant="button"
                            color="primary"
                            fontWeight="bold"
                        >
                            {Strings.BOOKING.ADDITION_SERVICE_ADD_SERVICE_TEXT}
                        </Typography>
                    }
                    icon={<AddIcon sx={{ fontSize: 14 }} />}
                    onClick={() => onAddService(user, detail, segmentIndex)}
                />
            </div>
        )
    }
    return (
        <DeeptechAccordion
            data={user}
            renderContent={(data) =>
                getBookingDetailsByUserId(data.id).map(detail => {
                    const flightExtraInfo = BookingHelpers.getFlightExtraInfo(detail, data.id);
                    const { segments, arrivalPlace, departPlace, carrierMarketing } = flightExtraInfo;
                    const isRefunded = isUserBookingDetailRefunded(data.id, detail.id);
                    const hasTransit = segments.length >= 2;

                    return (
                        <ContentBox
                            key={detail.id}
                            title={
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    gap={1}
                                    alignItems="center"
                                >
                                    <FlightIcon fontSize="small" />
                                    <Typography variant="button" fontWeight="bold">
                                        {Strings.BOOKING.FLIGHT} {departPlace}&nbsp;&rarr;&nbsp;{arrivalPlace}
                                    </Typography>
                                    {carrierMarketing?.logoUrl && <img style={{ opacity: 0.8 }} src={carrierMarketing.logoUrl} alt={carrierMarketing?.name} width="30px" />}
                                    {isRefunded && (
                                        <Box ml={3}>
                                            <Chip label={Strings.BOOKING.REFUND_POPUP_REFUNDED_CHIP_LABEL} color="primary" size={"small"} />
                                        </Box>
                                    )}
                                </Box>
                            }
                        >
                            {!isRefunded ? segments.map(segment => {
                                const MAX_SERVICE_PER_ITEM = 5;
                                const servicesDetailsBySegment = getServicesByDetail(detail, segment.index);

                                const key = `${detail.id}_${segment.index}`;
                                const newCount = servicesDetailsBySegment.length;
                                const oldCount = countByDetail.current[key] || 0;
                                countByDetail.current[key] = newCount;
                                const isAddNewAdditionService = newCount > oldCount;
                                // const remainServices = calculateRemainingAdditionalServices(data, detail, segment.index) - newCount;
                                const isReachLimit = newCount >= MAX_SERVICE_PER_ITEM;

                                const isSegmentDepartPlaceSameFlightDepartPlace = segment.departPlace === departPlace;
                                const isSegmentArrivalPlaceSameFlightArrivalPlace = segment.arrivalPlace === arrivalPlace;

                                return (
                                    <Box key={segment.index}>
                                        {hasTransit && <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                                            <Typography variant="button" fontWeight="bold">
                                                Chặng bay&nbsp;
                                                {segment.departPlace || "-"}
                                                {isSegmentDepartPlaceSameFlightDepartPlace ? null : <>&nbsp;(Trung chuyển)</>}
                                                &nbsp;&rarr;&nbsp;
                                                {(segment.arrivalPlace as string) || "-"}
                                                {isSegmentArrivalPlaceSameFlightArrivalPlace ? null : <>&nbsp;(Trung chuyển)</>}
                                            </Typography>
                                            {segment.carrierMarketing?.logoUrl && <img style={{ opacity: 0.8 }} src={segment.carrierMarketing.logoUrl} alt={segment.carrierMarketing?.name} width="30px" />}
                                        </Box>}
                                        <Box display="flex" flexDirection="column" gap={1}>
                                            {servicesDetailsBySegment.map((service, index) => {
                                                const errorsByServiceItem = getErrorsByServiceItem(service);
                                                const isLatestItem = index === newCount - 1;
                                                return (
                                                    <AdditionalServiceItem
                                                        key={service._id}
                                                        carrierCode={flightExtraInfo.carrierMarketing.code.toString()}
                                                        serviceCodenames={serviceCodenames}
                                                        data={service}
                                                        deletable={true}
                                                        error={errorsByServiceItem}
                                                        onChange={onUpdateService}
                                                        shouldFocus={isLatestItem && isAddNewAdditionService}
                                                        onRemove={onRemoveService ? () => onRemoveService(service, data, detail) : undefined}
                                                        currency={currency}
                                                    />
                                                )
                                            })}
                                        </Box>
                                        {isReachLimit && renderReachLimitActions()}
                                        {!isReachLimit && renderAddMoreServiceItemActions({ detail, segmentIndex: segment.index })}
                                    </Box>
                                )
                            }) : null}
                        </ContentBox>
                    )
                })
            }
        />
    );
};

export default UserAccordionForAdditionalServices;
