import moment from "moment";
import { Fragment, useMemo, useState } from "react";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import useDataBookingOrder from "../useDataBookingOrder.hook";

import { IOrderDetail } from "services/sale/order.service";
import { ItineraryType, OrderType, PaymentStatus } from "constants/enum";
import { IItineraryExtraInfo } from "components/Booking/useDataRequestBooking.hook";



const CardItemBookingOrder = (props: {
    currency: string;
    orderType: number;
    isSubOrder: boolean;
    typeBooking: number;
    paymentStatus: number;
    organizationId: string;
    orderDetails: IOrderDetail[];
    dataMapReferenceBookingId: Map<string, string>;
    dataMapExtraInfoRoot: Map<string, IItineraryExtraInfo>;
}) => {
    const isRefunded = useMemo(() => (
        [PaymentStatus.Refunded, PaymentStatus.WaitingRefund].includes(props?.paymentStatus)
    ), [props?.paymentStatus]);

    const renderItemRow = (item: IOrderDetail, index: number) => {
        let totalAmount = (item?.amount || 0);

        if (props?.orderType !== OrderType.AdditionService) {
            totalAmount = totalAmount + (item?.fee || 0);
        };

        return (
            <Box key={item.id} sx={{
                gap: 1,
                display: "grid",
                alignItems: "end",
                gridTemplateColumns: "1fr 50px 150px",
            }}>
                <BoxContentExtraInfo
                    index={index}
                    orderType={props?.orderType}
                    externalId={item?.externalId}
                    isSubOrder={props?.isSubOrder}
                    typeBooking={props.typeBooking}
                    organizationId={props.organizationId}
                    extraInformation={item?.extraInformation}

                    dataMapExtraInfoRoot={props.dataMapExtraInfoRoot}
                    dataMapReferenceBookingId={props.dataMapReferenceBookingId}
                />
                <Typography variant="button">
                    {`x ${Helpers.formatCurrency(item?.quantity || 0)}`}
                </Typography>
                <Box textAlign={"end"}>
                    <Typography variant="button" color={isRefunded ? "error" : "dark"}>
                        {`${isRefunded ? "-" : ""} ${Helpers.formatCurrency(totalAmount)} ${item?.currency || props.currency}`}
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <Box sx={{
            gap: 1,
            display: "grid",
            padding: "16px",
            borderRadius: "16px",
            border: "1.5px solid #F0F0F0",
        }}>
            <Box gap={0.5} display={"grid"}>
                <Typography variant="h6">
                    {Strings.BOOKING_ORDER.DETAIL.BOOKING_ORDER_INFORMATION}
                </Typography>
            </Box>
            <Box gap={2} display={"grid"}>
                {[...props?.orderDetails || []].map((item, index) => (renderItemRow(item, index)))}
            </Box>
        </Box>
    );
};

export default CardItemBookingOrder;

const getTextDateTimeFlight = (valueTime: any) => {
    const day = Strings.Common.DAY.toLocaleLowerCase();
    const time = moment(Helpers.getDateValue(valueTime)).format("HH:mm");
    const date = moment(Helpers.getDateValue(valueTime)).format("DD/MM/YYYY");

    return `${time} ${day} ${date}`;
};

const getTextDateTimeHotel = (valueTime: string, valueDate: any) => {
    const day = Strings.Common.DAY.toLocaleLowerCase();
    const date = valueDate ? moment(Helpers.getDateValue(valueDate)).format("DD/MM/YYYY") : "";
    return `${valueTime ? valueTime : ""} ${day} ${date}`;
};

const getDataFlightInfo = (extraInfo: any) => {
    const flight = extraInfo?.users?.[0]?.flight;

    if (Helpers.isNullOrEmpty(flight)) {
        return {
            ticketNumber: "",
            reservationCode: "",
            departTime: extraInfo?.FlightInfo?.DepartDate,
            arrivalTime: extraInfo?.FlightInfo?.ArrivalDate,
            cabinClass: extraInfo?.FlightInfo?.CabinClass || "",
            departPlaceCode: extraInfo?.FlightInfo?.DepartPlaceObj?.Code || "",
            arrivalPlaceCode: extraInfo?.FlightInfo?.ArrivalPlaceObj?.Code || "",
            carrierName: extraInfo?.FlightInfo?.CarrierMarketingObj?.Name?.["vi"] || "",
        };
    } else {
        return {
            departTime: flight?.departTime,
            arrivalTime: flight?.arrivalTime,
            ticketNumber: flight?.ticketNumber,
            cabinClass: flight?.cabinClass || "",
            carrierName: flight?.airlineObj?.name || "",
            reservationCode: flight?.reservationCode || "",
            departPlaceCode: flight?.departPlaceObj?.code || "",
            arrivalPlaceCode: flight?.arrivalPlaceObj?.code || "",
        };
    };
};

const BoxContentExtraInfo = ({
    index,
    orderType,
    isSubOrder,
    externalId,
    typeBooking,
    organizationId,
    extraInformation,
    dataMapExtraInfoRoot,
    dataMapReferenceBookingId,
}: {
    index: number,
    orderType: number,
    externalId: string,
    isSubOrder: boolean,
    typeBooking: number,
    organizationId: string,
    extraInformation: string,
    dataMapReferenceBookingId: Map<string, string>;
    dataMapExtraInfoRoot: Map<string, IItineraryExtraInfo>;
}) => {
    const { getDataMapUserByIds } = useDataBookingOrder();

    const [dataMapUser, setDataMapUser] = useState<Map<string, string>>(new Map());

    const dataExtraInfo: {
        contact: any;
        itemName: any;
        timezone: string;
        users: {
            id: string;
            flight: {
                segmentIndex?: number;
                ticketNumber?: string;
                reservationCode?: string;

                baggage?: string
                cabinClass?: string
                seatNumber?: string
                departTime?: number
                arrivalTime?: number
                flightNumber?: string
                isInternational?: boolean
                airlineObj?: { code: string, name: string }
                arrivalPlaceObj?: {
                    code: string
                    name: string
                    cityName: string
                    countryName: string
                }
                departPlaceObj?: {
                    code: string
                    name: string
                    cityName: string
                    countryName: string
                }
            }
        }[];
    } | undefined = useMemo(() => (
        isSubOrder ? Helpers.converStringToJson(extraInformation) : undefined
    ), [extraInformation]);

    const dataExtraInfoBooking = useMemo(() => {
        const referenceId = dataMapReferenceBookingId.get(externalId);
        if (Helpers.isNullOrEmpty(referenceId)) {
            return dataMapExtraInfoRoot.get(externalId);
        } else {
            return dataMapExtraInfoRoot.get(referenceId);
        }
    }, [externalId]);

    useMemo(() => {
        (async () => {
            const ids = [...dataExtraInfo?.users || []].map(el => el.id);
            if (ids.length > 0) {
                let newDataUser = new Map();

                newDataUser = await getDataMapUserByIds({
                    ids: ids,
                    orgId: organizationId,
                    newDataUser: newDataUser,
                });

                setDataMapUser(newDataUser);
            }
        })()
    }, [organizationId, dataExtraInfo?.users]);

    const BoxDefault = () => (
        <>
            {typeBooking === ItineraryType.Flight &&
                <Box gap={0.5} display={"grid"}>
                    <Typography variant="button" sx={{ marginBottom: 0.5 }}>
                        {`${Strings.BOOKING.RESERVATION_CODE}: `}
                        {dataExtraInfoBooking?.issueTicketInfo?.booking?.itineraries?.[0]?.reservation_code}
                    </Typography>

                    <Typography variant="button">
                        {`${Strings.BOOKING.FLIGHT} ${getDepartArrivalFlight(dataExtraInfoBooking?.flightInfo)}`}
                    </Typography>

                    <Typography variant="button">
                        -&nbsp;{`${Strings.BOOKING_ORDER.DETAIL.CARRIER} ${dataExtraInfoBooking?.flightInfo?.carrierOperatorObj?.name?.vi}`}
                        &nbsp;-&nbsp;{`${Strings.BOOKING_ORDER.DETAIL.CABIN_CLASS} ${dataExtraInfoBooking?.flightInfo?.cabinClass}`}
                    </Typography>

                    <Typography variant="button">
                        -&nbsp;{`${Strings.BOOKING_ORDER.DETAIL.DEPART_AND_ARRIVAL}:`}&ensp;
                        {getTextDateTimeFlight(dataExtraInfoBooking?.flightInfo?.departDate)}
                        &ensp;-&ensp;
                        {getTextDateTimeFlight(dataExtraInfoBooking?.flightInfo?.arrivalDate)}
                    </Typography>
                </Box>
            }
            {typeBooking === ItineraryType.Hotel &&
                <Box gap={0.5} display={"grid"}>
                    <Typography variant="button" sx={{ marginBottom: 0.5 }}>
                        {"Mã đặt chỗ: "}
                        {dataExtraInfoBooking?.issueTicketInfo?.booking?.itineraries?.[0]?.reservation_code}
                    </Typography>
                    <Typography variant="button">
                        {`Đặt chỗ tại ${dataExtraInfoBooking?.hotelInfo?.partnerName?.value?.["vi"] || "-/-"}`}
                    </Typography>
                    <Typography variant="button">
                        -&nbsp;{`${dataExtraInfoBooking?.hotelInfo?.product?.name?.value?.["vi"] || "-/-"}`}
                    </Typography>
                    <Typography variant="button">
                        -&nbsp;{"Nhận phòng:"}&ensp;
                        {getTextDateTimeHotel(dataExtraInfoBooking?.hotelInfo?.checkinTime, dataExtraInfoBooking?.hotelInfo?.checkinDate)}
                        &ensp;{"- Trả phòng:"}&ensp;
                        {getTextDateTimeHotel(dataExtraInfoBooking?.hotelInfo?.checkoutTime, dataExtraInfoBooking?.hotelInfo?.checkoutDate)}
                    </Typography>
                </Box>
            }
        </>
    );

    const BoxAdditionService = () => (
        <Box gap={0.5} display={"grid"}>
            {(index === 0) &&
                <Box marginBottom={0.5} gap={0.5} display={"grid"}>
                    <Typography variant="button">
                        {Strings.BOOKING.ADD_SERVICE_ADDITION_SERVICE}
                    </Typography>
                    <Typography variant="button">
                        {`${Strings.BOOKING.RESERVATION_CODE}: `}
                        {dataExtraInfoBooking?.issueTicketInfo?.booking?.itineraries?.[0]?.reservation_code}
                    </Typography>
                    <Typography variant="button">
                        {`${Strings.BOOKING.FLIGHT} ${getDepartArrivalFlight(dataExtraInfoBooking?.flightInfo)}`}
                    </Typography>
                </Box>
            }
            {
                [...dataExtraInfoBooking?.flightInfo?.segmentsList || []].map((itemSeg, index) => {
                    const listItemSeg = [...dataExtraInfo?.users || []].filter(el => el.flight?.segmentIndex === itemSeg.index);
                    const arrival_depart_place = [dataExtraInfoBooking?.flightInfo?.arrivalPlace, dataExtraInfoBooking?.flightInfo?.departPlace];
                    const textD = arrival_depart_place.includes(itemSeg.departPlace) ? "" : "(Trung chuyển)";
                    const textA = arrival_depart_place.includes(itemSeg.arrivalPlace) ? "" : "(Trung chuyển)";
                    const departPlace = `${itemSeg.departPlaceObj?.name} (${itemSeg.departPlaceObj?.code}) ${textD}`;
                    const arrivalPlace = `${itemSeg.arrivalPlaceObj?.name} (${itemSeg.arrivalPlaceObj?.code}) ${textA}`;

                    return listItemSeg.length > 0 && (
                        <Box display={"grid"} key={index}>
                            <Typography variant="button">
                                {`- ${departPlace} - ${arrivalPlace}`}
                            </Typography>
                            {[...dataExtraInfo?.users || []].map((user: any, index: number) => (
                                <Box key={index} display={"grid"}>
                                    <Typography variant="button">
                                        &ensp;&#9679;&ensp;{`${Strings.BOOKING.FLIGHT_PASSENGER}: ${dataMapUser.get(user.id)} `}
                                    </Typography>
                                    <Typography variant="button">
                                        &ensp;&emsp;&omicron;&ensp;{dataExtraInfo?.itemName?.["vi"]}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    )
                })
            }
        </Box>
    );

    const BoxPartialRefund = () => (
        <Box gap={0.5} display={"grid"}>
            <Box marginBottom={0.5} gap={0.5} display={"grid"}>
                <Typography variant="button">
                    {Strings.BOOKING.ADD_SERVICE_TICKET_REFUND}
                </Typography>
                <Typography variant="button">
                    {`${Strings.BOOKING.RESERVATION_CODE}: `}
                    {dataExtraInfoBooking?.issueTicketInfo?.booking?.itineraries?.[0]?.reservation_code}
                </Typography>
                <Typography variant="button">
                    {`${Strings.BOOKING.FLIGHT} ${getDepartArrivalFlight(dataExtraInfoBooking?.flightInfo)}`}
                </Typography>
            </Box>
            {[...dataExtraInfo?.users || []].map((user, index) => (
                <Box key={index} display={"grid"}>
                    <Typography key={index} variant="button">
                        &ensp;&#9679;&ensp;{`${Strings.BOOKING.FLIGHT_PASSENGER}: ${dataMapUser.get(user.id)} `}
                    </Typography>
                    <Typography variant="button">
                        &ensp;&emsp;&omicron;&ensp;{`${Strings.BOOKING.TICKET_NUMBER}: `}
                        <Typography variant="button" fontWeight="bold">
                            {user.flight?.ticketNumber}
                        </Typography>
                    </Typography>
                </Box>
            ))}
        </Box>
    );

    const BoxTransfer = () => (
        <Box gap={0.5} display={"grid"}>
            {(index === 0) &&
                <Box marginBottom={0.5} gap={0.5} display={"grid"}>
                    <Typography variant="button">
                        {Strings.BOOKING.ADD_SERVICE_TRANSFER_FLIGHT}
                    </Typography>
                    <Typography variant="button">
                        {`${Strings.BOOKING.RESERVATION_CODE}: `}
                        {dataExtraInfoBooking?.issueTicketInfo?.booking?.itineraries?.[0]?.reservation_code}
                    </Typography>
                    <Typography variant="button">
                        {`${Strings.BOOKING.FLIGHT} ${getDepartArrivalFlight(dataExtraInfoBooking?.flightInfo)}`}
                    </Typography>
                </Box>
            }
            {
                [...dataExtraInfoBooking?.flightInfo?.segmentsList || []].map((itemSeg, index) => {
                    const listItemSeg = [...dataExtraInfo?.users || []].filter(el => el.flight?.segmentIndex === itemSeg.index);
                    const arrival_depart_place = [dataExtraInfoBooking?.flightInfo?.arrivalPlace, dataExtraInfoBooking?.flightInfo?.departPlace];
                    const textD = arrival_depart_place.includes(itemSeg.departPlace) ? "" : "(Trung chuyển)";
                    const textA = arrival_depart_place.includes(itemSeg.arrivalPlace) ? "" : "(Trung chuyển)";
                    const departPlace = `${itemSeg.departPlaceObj?.name} (${itemSeg.departPlaceObj?.code}) ${textD}`;
                    const arrivalPlace = `${itemSeg.arrivalPlaceObj?.name} (${itemSeg.arrivalPlaceObj?.code}) ${textA}`;

                    return listItemSeg.length > 0 && (
                        <Box display={"grid"} key={index}>
                            <Typography variant="button">
                                {`- ${departPlace} - ${arrivalPlace}`}
                            </Typography>
                            {[...dataExtraInfo?.users || []].map((user, index: number) => (
                                <Fragment key={index}>
                                    <Typography variant="button">
                                        &ensp;&#9679;&ensp;{`${Strings.BOOKING.FLIGHT_PASSENGER}: ${dataMapUser.get(user.id)} `}
                                    </Typography>
                                    <Box paddingLeft={"20px"} display={"grid"}>
                                        <Typography variant="button">
                                            &omicron;&ensp;{Strings.BOOKING_ORDER.DETAIL.NEW_FLIGHT}&nbsp;
                                            {`${user?.flight?.departPlaceObj?.name} (${user?.flight?.departPlaceObj?.code})`}
                                            &nbsp;-&nbsp;
                                            {`${user?.flight?.arrivalPlaceObj?.name} (${user?.flight?.arrivalPlaceObj?.code})`}
                                        </Typography>
                                        <Typography variant="caption" color="secondary">
                                            &ensp;&ensp;&ensp;{Strings.BOOKING_ORDER.DETAIL.CARRIER}:&nbsp;
                                            {`${user?.flight?.airlineObj?.name} (${user?.flight?.airlineObj?.code})`}
                                            &nbsp;;&nbsp;
                                            {`${Strings.BOOKING_ORDER.DETAIL.CABIN_CLASS}: ${user?.flight?.cabinClass}`}
                                            &nbsp;:&nbsp;
                                            {`${Strings.BOOKING.FLIGHT_NUMBER}: ${user?.flight?.flightNumber}`}
                                        </Typography>
                                        <Typography variant="caption" color="secondary">
                                            &ensp;&ensp;&ensp;{`${Strings.BOOKING_ORDER.DETAIL.DEPART_AND_ARRIVAL}: `}
                                            {getTextDateTimeFlight(user?.flight?.departTime)}
                                            &ensp;-&ensp;
                                            {getTextDateTimeFlight(user?.flight?.arrivalTime)}
                                        </Typography>
                                    </Box>
                                </Fragment>
                            ))}
                        </Box>
                    )
                })
            }
        </Box>
    );

    switch (orderType) {
        case OrderType.AdditionService:
            return <BoxAdditionService />;
        case OrderType.PartialRefund:
            return <BoxPartialRefund />;
        case OrderType.Transfer:
            return <BoxTransfer />;
        default:
            return <BoxDefault />;
    };
};

const getDepartArrivalFlight = (flightInfo: any) => {
    const arr = [
        `${flightInfo?.departPlaceObj?.name} (${flightInfo?.departPlaceObj?.code})`,
        `${flightInfo?.arrivalPlaceObj?.name} (${(flightInfo?.arrivalPlaceObj?.code)})`
    ];
    return arr.join(" - ");
};