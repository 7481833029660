import { useEffect, useState } from "react";
import { KeyModuleScreen } from "@maysoft/common-component-react";

import RequestBookingDetailContainer from "./DetailContainer";
import { ItineraryType, Mode, SupplierCode } from "constants/enum";
import RequestBookingContainer, { IDataRequestGetPaged } from "./listBooking";


const RequestBookingModule = (props: {
    mode: Mode;
    title?: string;
    idDetail?: string;

    type?: ItineraryType;
    supplierCode?: SupplierCode;

    onGoBack: () => void;
    onChangeMode: (mode: Mode) => void;

    hidenActionCreate?: boolean;
    hidenActionUpdate?: boolean;

    requestGetPaged: IDataRequestGetPaged;
    onNavigate: (data?: {
        id?: string;
        mode?: number;
    }) => void;
    onGetPaged: (data: {
        query: string;
        totalCount: number;
    }) => void;

    keyModuleContainer: {
        [key in KeyModuleScreen]?: {
            pathName: string;
        };
    };
}) => {
    const currentPath = window.location.pathname || "";

    const [idDetail, setIdDetail] = useState<string | undefined>(undefined);

    useEffect(() => { setIdDetail(props.idDetail); }, [props.idDetail]);

    const listContainers: { [key in KeyModuleScreen]?: JSX.Element; } = {
        "list": (
            <RequestBookingContainer
                type={props.type}
                title={props.title}
                supplierCode={props.supplierCode}
                requestGetPaged={props.requestGetPaged}
                hidenActionCreate={props.hidenActionCreate}
                hidenActionUpdate={props.hidenActionUpdate}

                onGetPaged={props.onGetPaged}
                onNavigate={(data) => {
                    setIdDetail(data.id);
                    props.onNavigate(data);
                }}
            />
        ),
        "detail": (
            <RequestBookingDetailContainer
                type={props.type}
                idDetail={idDetail}
                onGoBack={() => {
                    setIdDetail(undefined);
                    props.onGoBack();
                }}
            />
        ),
    };

    const renderContainerView = () => {
        const itemFind = Object.keys(props.keyModuleContainer).find((key) => (
            (props.keyModuleContainer as any)[key]?.pathName === currentPath)
        );
        return (listContainers as any)[itemFind];
    };

    return <>{renderContainerView()}</>;
};

export default RequestBookingModule;
