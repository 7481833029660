import { Box, Typography } from "@maysoft/common-component-react";
import { Chip } from "@mui/material";
import { BookingHelpers } from "commons/bookingHelpers";
import Helpers from "commons/helpers";
import { IBookingDetail } from "components/Booking/useDataRequestBooking.hook";
import { BookingDetailAmendType } from "constants/enum";
import Strings from "constants/strings";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";
import { IBookingDetailFlightAmendServicePopupExtraInfo } from "../../interfaces";

const FlightRefundDetail = ({ detail, isRefunded = false, userId }: { detail: IBookingDetail, isRefunded?: boolean, userId: string }) => {
    const { orderDetailMapByBookingDetailId } = useAddMoreBookingServiceContext();
    const { arrivalPlace, departPlace, getTicketNumberByUserId } = BookingHelpers.getFlightExtraInfo(detail, userId);
    const ticketNumber = (() => {
        if (detail.amendType === BookingDetailAmendType.Normal) {
            return getTicketNumberByUserId(userId);
        }
        const orderDetailExtraInformation = Helpers.converStringToJson(orderDetailMapByBookingDetailId[detail.id].extraInformation) as IBookingDetailFlightAmendServicePopupExtraInfo;
        const user = orderDetailExtraInformation?.users?.find(u => u.id === userId);
        const actualTicketNumber = user?.transferFlightActualData?.actualTicketNumber;

        return actualTicketNumber ?? "";
    })();


    return (
        <Box
            display="flex"
            gap={1}
            alignItems={"center"}
            flexDirection="row"
        >
            <Typography variant="button">
                {Strings.BOOKING.FLIGHT} {departPlace}&nbsp;-&nbsp;{arrivalPlace}&nbsp;
                {ticketNumber && (
                    <Typography variant="button" ml={4}>
                        <b>{Strings.BOOKING.TICKET_NUMBER}</b>:&nbsp;{ticketNumber}
                    </Typography>
                )}
            </Typography>
            {isRefunded && (
                <Chip label={Strings.BOOKING.REFUND_POPUP_REFUNDED_CHIP_LABEL} color="primary" size={"small"} />
            )}
        </Box>
    );
}

export default FlightRefundDetail;