import { useMemo } from "react";
import { RadioButtonChecked } from "@mui/icons-material";
import { Box, Typography } from "@maysoft/common-component-react";
import { IFlightExtraInformation } from "components/Booking/useDataRequestBooking.hook";

type ISegment =
    | {
        place: string;
        terminal: string;
        name: string;
    }
    | {
        name: string;
        minutes: number;
    };

export const SegmentFlightIntoTooltip = ({ data, language }: { data: IFlightExtraInformation; language: string }) => {
    const segmentsList = useMemo(() => formatSegmentsList(data), [data]);

    return (
        <Box sx={{
            px: 2,
            py: 1,
            gap: 1,
            display: "grid",
        }}>
            {segmentsList.map((item, index) => {
                if ("minutes" in item) {
                    return (
                        <Box key={index} display="flex" alignItems="center" gap={0.5} flexWrap="wrap">
                            <RadioButtonChecked sx={{ fontSize: 12 }} color="warning" />
                            <Typography variant="caption" color="warning">
                                Trung chuyển tại {item.name} {formatDuration(item.minutes, false, language)}
                            </Typography>
                        </Box>
                    );
                }

                return (
                    <Box key={index} display="flex" alignItems="center" gap={0.5} flexWrap="wrap">
                        <RadioButtonChecked sx={{ fontSize: 12 }} color="secondary" />
                        <Typography variant="caption" color="secondary">
                            {item.place}
                        </Typography>
                        <Typography variant="caption">{item.name}</Typography>
                        <Typography variant="caption" color="warning">
                            ga {item.terminal}
                        </Typography>
                    </Box>
                );
            })}
        </Box>
    );
};

export default SegmentFlightIntoTooltip;

export const formatSegmentsList = (data: IFlightExtraInformation) => {
    const result: ISegment[] = [];

    const segmentsList = data?.segmentsList || [];

    segmentsList.forEach((el, index) => {
        result.push({
            place: el.departPlace,
            terminal: el.departTerminal,
            name: el.departPlaceObj?.name,
        });

        result.push({
            place: el.arrivalPlace,
            terminal: el.arrivalTerminal,
            name: el.arrivalPlaceObj?.name,
        });

        if ((el.arrivalPlace === data?.departPlace) && (el.arrivalPlace !== data?.arrivalPlace)) {
            result.push({
                name: el.arrivalPlaceObj?.city,
                minutes: (segmentsList[index + 1]?.departDate - segmentsList[index]?.arrivalDate) / 1000 / 60,
            });
        }

        if ((index !== segmentsList.length - 1) &&
            (el.arrivalPlace !== data?.departPlace) &&
            (el.arrivalPlace !== data?.arrivalPlace)) {
            result.push({
                name: el.arrivalPlaceObj?.city,
                minutes: (segmentsList[index + 1]?.departDate - segmentsList[index]?.arrivalDate) / 1000 / 60,
            });
        }
    });

    return result;
};

export const formatDuration = (minutes: number, isShowZeroMinutes = false, language: string) => {
    const hours = Math.floor(minutes / 60);

    const minutesReminder = minutes - hours * 60;

    if (minutesReminder === 0) {
        return isShowZeroMinutes
            ? `${hours}h ${minutesReminder}m`
            : `${hours}h`;
    }

    return `${hours}h ${minutesReminder}m`;
};

