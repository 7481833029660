import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Constants from "../../constants";
import Strings from "constants/strings";
import ModuleBookingOrder from "components/BookingOrder";

import { RootState } from "store";
import { Mode } from "constants/enum";
import { DashboardLayout } from "layout";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setListPathName } from "store/slice/titleRoute.slice";

const BookingOrderScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const idDetail: string = searchParams.get("id");
    const bookingId: string = searchParams.get("bookingId");

    const modeList = (Screens.ORDER_LIST.includes(window.location.pathname));
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const [model, setModel] = useState<{ mode: Mode; title: string; route: any[] }>({
        mode: Mode.View,
        title: Strings.ORDER.TITLE_DETAIL_VIEW,
        route: [
            { title: Strings.ORDER.TITLE_MENU, route: Screens.ORDER_LIST },
            { title: Strings.Common.DETAIL, route: "" },
        ],
    });

    const [request] = useState<any>({
        pageNumber: Number(searchParams.get("pageNumber") || 1),
        pageSize: Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE),

        endTime: !Helpers.isNullOrEmpty(searchParams.get("endTime")) ? searchParams.get("endTime") : moment().endOf("month").unix(),
        startTime: !Helpers.isNullOrEmpty(searchParams.get("startTime")) ? searchParams.get("startTime") : moment().startOf("month").unix(),

        code: searchParams.get("code"),
        searchText: searchParams.get("searchText"),
        customServiceType: searchParams.get("customServiceType"),
        paymentStatus: Helpers.isNullOrEmpty(searchParams.get("paymentStatus")) ? undefined : Number(searchParams.get("paymentStatus")),
    });

    useEffect(() => {
        if (modeList) {
            return;
        } else {
            if (Helpers.isNullOrEmpty(idDetail)) {
                handleChangeMode(Mode.Create);
            } else {
                handleChangeMode(pramsMode || Mode.Update);
            }
        }
    }, [modeList, pramsMode, idDetail]);

    const handleGoBack = () => {
        const itemP = listPathName.find((el) => el.pathName === Screens.ORDER_LIST);
        navigate(Screens.ORDER_LIST + (itemP ? itemP.query : ""));
    };

    const handleChangeMode = (valueMode: Mode) => {
        if (valueMode === Mode.Create) {
            setModel({
                mode: Mode.View,
                title: Strings.ORDER.TITLE_CREATE_VIEW,
                route: [
                    { title: Strings.ORDER.TITLE_MENU, route: Screens.ORDER_LIST },
                    { title: Strings.Common.ADD_NEW, route: "" },
                ],
            });
            navigate(Screens.ORDER_VIEW, { replace: true });
        }
        if (valueMode === Mode.Update) {
            setModel({
                mode: Mode.Update,
                title: Strings.ORDER.TITLE_UPDATE_VIEW,
                route: [
                    { title: Strings.ORDER.TITLE_MENU, route: Screens.ORDER_LIST },
                    { title: Strings.Common.UPDATE, route: "" },
                ],
            });
            navigate(Screens.ORDER_EDIT + `?id=${idDetail}&bookingId=${bookingId}&mode=${pramsMode}`, { replace: true });
        }
        if (valueMode === Mode.View) {
            setModel({
                mode: Mode.View,
                title: Strings.ORDER.TITLE_DETAIL_VIEW,
                route: [
                    { title: Strings.ORDER.TITLE_MENU, route: Screens.ORDER_LIST },
                    { title: Strings.Common.DETAIL, route: "" },
                ],
            });
            navigate(Screens.ORDER_VIEW + `?id=${idDetail}&bookingId=${bookingId}&mode=${pramsMode}`, { replace: true });
        }
    }

    return (
        <DashboardLayout
            isPermission
            title={modeList ? Strings.ORDER.TITLE_LIST_VIEW : model.title}
            route={modeList ? [{ title: Strings.ORDER.TITLE_MENU, route: "" }] : model.route}
        >
            <ModuleBookingOrder
                keyModuleContainer={{
                    list: { pathName: Screens.ORDER_LIST },
                    detail: { pathName: Screens.ORDER_VIEW },
                    update: { pathName: Screens.ORDER_VIEW },
                    create: { pathName: Screens.ORDER_VIEW },
                }}

                requestGetPaged={request}
                onGetPaged={({ query, totalCount }) => {
                    dispatch(setListPathName({ pathname: Screens.ORDER_LIST, query, totalCount }));
                    navigate(Screens.ORDER_LIST + query, { replace: true });
                }}
                onNavigate={(data) => {
                    if (data.id) {
                        navigate(Screens.ORDER_VIEW + `?id=${data?.id}&mode=${data.mode}`)
                    } else {
                        navigate(Screens.ORDER_VIEW)
                    }
                }}

                mode={model.mode}
                idDetail={idDetail}
                bookingId={bookingId}
                onGoBack={() => handleGoBack()}
                onChangeMode={(mode: Mode) => { handleChangeMode(mode) }}
            />
        </DashboardLayout>
    );
};

export default BookingOrderScreen;
