import { Box, Button, FormField, Modal, Typography } from "@maysoft/common-component-react";
import { Grid, IconButton, Tooltip } from "@mui/material";


import { ContentCopy } from "@mui/icons-material";
import Constants from "constants/index";
import moment from "moment";
import Strings from "constants/strings";
import { useEffect, useMemo, useState } from "react";
import { lazy as yupLazy, number, object, string } from "yup";
import DeeptechAccordion from "components/Booking/DetailContainer/PopupAmend/components/DeeptechAccordion";
import { IBookingDetailFlightAmendServicePopupExtraInfo } from "components/Booking/DetailContainer/interfaces";
import OrderService, { IOrderDetail, IOrderDetailDataUpdate, IRecordOrder } from "services/sale/order.service";
import { OrderStatus, OrderType, PaymentStatus } from "constants/enum";
import Helpers from "commons/helpers";
import SubOrderService from "services/sale/subOrder.service";
import { showLoading } from "store/slice/loadingAPI.slice";
import { useDispatch } from "react-redux";
import { setDataAlert } from "store/slice/message.slice";
import { BookingHelpers } from "commons/bookingHelpers";
import { IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import { mapValues } from "lodash";

const subOrderService = new SubOrderService();
const orderService = new OrderService();

const LocalHelpers = {
    copyToClipboard: async function(text: string) { return navigator.clipboard.writeText(text); },
    getPaymentLinkQueryData: function(paymentLink: string) {
        const url = new URL(paymentLink);
        const orderId = url.searchParams.get("orderId");
        const expireTime = url.searchParams.get("expireTime");
        return { orderId, expireTime };
    }
}

interface ICardPaymentLinkBookingOrderProps {
    userMap: Map<string, string>;
    order: IRecordOrder;
    booking: IDetailBooking;
    refetchOrderDataCallback: () => Promise<void>;
}
const CardPaymentLinkBookingOrder = (props: ICardPaymentLinkBookingOrderProps) => {
    const dispatch = useDispatch();

    const confirmPaymentTimestamp = Number(props.order.confirmTime) * 1000;
    const confirmPaymentDate = moment(confirmPaymentTimestamp).format(Constants.DateFormat.HHMM_DDMMYYYY.replace(" ", " - "));
    const isConfirmTimeExpired = confirmPaymentTimestamp < Date.now();

    const [paymentLink, setPaymentLink] = useState<string | null>(props.order.paymentLink);
    const { orderId, expireTime } = LocalHelpers.getPaymentLinkQueryData(paymentLink);
    const isPaymentLinkExpired = Number(expireTime) * 1000 < Date.now();
    useEffect(() => {
        const paymentLink = props.order.paymentLink;
        setPaymentLink(paymentLink);
    }, [props.order.paymentLink]);

    const isOrderPaymentNew = props.order.paymentStatus === PaymentStatus.New;
    const isOrderPaymentCompleted = props.order.paymentStatus === PaymentStatus.Completed;
    const isWaitingForConfirmToFinishOrder = props.order.orderStatus === OrderStatus.Confirming;
    const isOrderPaidAndWaitConfirm = isOrderPaymentCompleted && isWaitingForConfirmToFinishOrder;
    const isResendPaymentLinkButtonShowed = isOrderPaymentNew && isPaymentLinkExpired && !isConfirmTimeExpired;

    const handleRetry = async () => {
        try {
            dispatch(showLoading(true));
            const retryPaymentLinkResponse = await subOrderService.retryPaymentLink({ orderId: props.order.id });
            setPaymentLink(retryPaymentLinkResponse.paymentLink);
            Helpers.showAlert("Gửi lại payment link thành công", "success");
        } catch(e) {
            Helpers.showAlert(Helpers.renderExceptionError(e), "error");
        } finally {
            dispatch(showLoading(false));
        }

    }
    const handleCopyPaymentLink = async () => {
        try {
            await LocalHelpers.copyToClipboard(paymentLink);
            dispatch(setDataAlert({ message: "Copy payment link thành công", type: "success" }));
        } catch (e) {
            dispatch(setDataAlert({ message: "Copy payment link thất bại", type: "error" }));
        }
    }
    const onCancelOrder = async ({ reason }: { reason: string }) => {
        try {
            dispatch(showLoading(true));
            await subOrderService.cancelSubOrder({ orderId: props.order.id, reason });
            await props.refetchOrderDataCallback();
            Helpers.showAlert("Huỷ đơn hàng thành công", "success");
        } catch(e) {
            Helpers.showAlert(Helpers.renderExceptionError(e), "error");
        } finally {
            dispatch(showLoading(false));
        }
    }
    const handleConfirmAdditionServiceOrder = async () => {
        try {
            dispatch(showLoading(true));
            await subOrderService.finishSubOrder({ orderId: props.order.id });
            await props.refetchOrderDataCallback();
            Helpers.showAlert("Xác nhận đơn hàng thành công", "success");
        } catch(e) {
            Helpers.showAlert(Helpers.renderExceptionError(e), "error");
        } finally {
            dispatch(showLoading(false));
        }
    }
    const handleConfirmTransferFlightOrder = async (updatedOrderDetail: IOrderDetailDataUpdate[]) => {
        try {
            dispatch(showLoading(true));
            await subOrderService.finishSubOrder({ orderId: props.order.id });
            await orderService.updateOrderDetail({
                orderId: props.order.id,
                orderDetailDataUpdate: updatedOrderDetail,
            });
            await props.refetchOrderDataCallback();
            Helpers.showAlert("Xác nhận đơn hàng thành công", "success");
        } catch(e) {
            Helpers.showAlert(Helpers.renderExceptionError(e), "error");
        } finally {
            dispatch(showLoading(false));
        }
    }


    const renderOrderPaidAndWaitConfirmActions = () => {
        switch (props.order.type) {
            case OrderType.Transfer:
                return <PaymentLinkTransferFlightOrderWaitingConfirmAction booking={props.booking} subOrder={props.order} userMap={props.userMap} onConfirm={handleConfirmTransferFlightOrder} onCancel={onCancelOrder} />;
            case OrderType.AdditionService:
                return <PaymentLinkAdditionServiceOrderWaitingConfirmAction onConfirm={handleConfirmAdditionServiceOrder} onCancel={onCancelOrder} />;
            default:
                return null;
        }
    }
    const renderPaymentLinkContent = () => {
        if (paymentLink) {
            if (isPaymentLinkExpired) {
                return (
                    <Typography variant="button" color="error">
                        Link thanh toán đã hết hạn
                    </Typography>
                );
            }

            return (<>
                    <Typography variant="button" color="success">
                        Payment link đã tạo thành công
                    </Typography>
                    <Tooltip title="Copy link thanh toán">
                        <IconButton onClick={handleCopyPaymentLink}>
                            <ContentCopy style={{ fontSize: "1rem" }} />
                        </IconButton>
                    </Tooltip>
                </>);
        }

        return (
            <Typography variant="button" color="error">
                Không tìm thấy payment link
            </Typography>
        )
    }

    return orderId === props.order.id && (
        <>
            <Box gap={1} display={"flex"} alignItems="center" borderTop="1px solid #dfdfdf" mt={2} pt={2}>
                {renderPaymentLinkContent()}
                <Box ml="auto" display="flex" gap={1}>
                    {isResendPaymentLinkButtonShowed && <PaymentLinkOrderRetryAction onRetry={handleRetry} />}
                    {isOrderPaidAndWaitConfirm && renderOrderPaidAndWaitConfirmActions()}
                </Box>
            </Box>
            <Box gap={1} display={"flex"} alignItems="center">
                <Typography variant="button">
                    Thời hạn xác nhận: {confirmPaymentDate}
                </Typography>
            </Box>
        </>
    );
};

export default CardPaymentLinkBookingOrder;

const PaymentLinkOrderCanceledAction = ({ onClose, onCancel }: { onClose: () => void, onCancel: (args: { reason: string }) => void }) => {
    const MAX_LENGTH = 100;
    const [isCancelModalVisibled, setIsCancelModalVisibled] = useState(false);
    const [reason, setReason] = useState("");
    const [reasonErrorMessage, setReasonErrorMessage] = useState("");
    const schema = string().required("Vui lòng nhập lý do huỷ").min(1).max(MAX_LENGTH);

    useEffect(() => {
        if (!isCancelModalVisibled) {
            setReason("");
            setReasonErrorMessage("");
        }
    }, [isCancelModalVisibled])

    return (
        <>
            <Button color="secondary" onClick={() => {
                setIsCancelModalVisibled(true);
            }}>
                Huỷ
            </Button>

            {isCancelModalVisibled && (<Modal
                fullWidth
                maxWidth="md"
                visible={isCancelModalVisibled}
                onClose={() => {
                    onClose();    
                    setIsCancelModalVisibled(false)
                    setReason("");
                    setReasonErrorMessage("");
                }}
                title="Lý do hủy đơn hàng"
                onAction={async () => {
                    try {
                        await schema.validate(reason);
                        setReasonErrorMessage("");
                        setIsCancelModalVisibled(false);
                        onCancel({ reason });
                    } catch (error: any) {
                        const errorMessage = error.errors[0];
                        console.log(errorMessage, error);
                        setReasonErrorMessage(errorMessage);
                    }
                }}
                hasActionButton
                buttonAction={Strings.Common.CONFIRM}
                closeButton={Strings.Common.CANCEL}
            >
                <Typography variant="button">
                    Bạn sắp huỷ yêu cầu này.<br />Vui lòng nhập lý do huỷ và xác nhận để tiếp tục
                </Typography> 
                <br />
                <Typography variant="button" fontWeight="bold">
                    Lý do hủy*
                </Typography>
                <FormField
                    required
                    autoComplete="off"
                    variant="outlined"
                    defaultValue={reason}
                    placeholder={Strings.BOOKING.BOOKING_DETAIL_NOTE_PLACEHOLDER}
                    multiline
                    rows={4}
                    maxLength={MAX_LENGTH}
                    errorMessage={reasonErrorMessage}
                    error={!!reasonErrorMessage}
                    onChange={() => setReasonErrorMessage("")}
                    onBlur={(reason: string) => setReason(reason)}
                />
            </Modal>)}
        </>
    )
}
const PaymentLinkAdditionServiceOrderWaitingConfirmAction = (props: { onConfirm: () => void, onCancel: ({ reason }: { reason: string }) => void, }) => {
    return <Box ml="auto" gap={1} display="flex">
            <Button onClick={props.onConfirm}>
                Hoàn thành
            </Button>
            <PaymentLinkOrderCanceledAction
                onClose={() => {}}
                onCancel={props.onCancel}
            />
        </Box>
}


interface IPaymentLinkTransferFlightConfirmData {
    bookingDetailId: string,
    orderDetailId: string,
    costPrice: number,
    extraFee: number,
    reservationCode: string,
    ticketNumber: string
}
const PaymentLinkTransferFlightOrderWaitingConfirmAction = (props: { booking: IDetailBooking, subOrder: IRecordOrder, userMap: Map<string, string>, onConfirm: (updatedOrderDetail: IOrderDetailDataUpdate[]) => void, onCancel: ({ reason }: { reason: string }) => void, }) => {
    const [visibled, setVisibled] = useState(false);
    const [confirmDatas, setConfirmDatas] = useState<{
        [key: string]: IPaymentLinkTransferFlightConfirmData;
    }>(props.subOrder.orderDetails.reduce((p, c) => {
        p[c.id] = {
            bookingDetailId: c.externalId,
            orderDetailId: c.id,
            costPrice: 0,
            extraFee: 0,
            reservationCode: "",
            ticketNumber: ""
        }
        return p;
    }, { } as any));
    const [confirmDataErrors, setConfirmDataErrors] = useState<{
        [key: string]: {
            costPrice?: string,
            extraFee?: string,
            reservationCode?: string,
            ticketNumber?: string
        }
    }>({ });

    useEffect(() => {
        if (!visibled) {
            setConfirmDatas(props.subOrder.orderDetails.reduce((p, c) => {
                p[c.id] = {
                    bookingDetailId: c.externalId,
                    orderDetailId: c.id,
                    amount: 0,
                    extraFee: 0,
                    reservationCode: "",
                    ticketNumber: ""
                }
                return p;
            }, { } as any));
            setConfirmDataErrors({ });
        }
    }, [props.subOrder.orderDetails, visibled])

    const schema = yupLazy(obj => object(
        mapValues(obj, () => object({
            costPrice: number().required("Vui lòng nhập tiền vé chênh lệch").min(0, "Vui lòng nhập số tiền lớn hơn 0"),
            extraFee: number().required("Vui lòng nhập phí khác").min(0, "Vui lòng nhập số tiền lớn hơn 0"),
            reservationCode: string().required("Vui lòng nhập mã đặt chỗ mới").min(1, "Vui lòng nhập mã đặt chỗ mới"),
            ticketNumber: string().required("Vui lòng nhập số vé mới").min(1, "Vui lòng nhập số vé mới"),
        }))
    ))

    const orderDetailsGroupByUserId = useMemo(() => {
        return props.subOrder?.orderDetails?.reduce((p, c) => {
            const e = Helpers.converStringToJson(c.extraInformation) as IBookingDetailFlightAmendServicePopupExtraInfo;
            for(const user of e?.users) {
                if (!p[user.id]) {
                    p[user.id] = [];
                }
                p[user.id].push(c);
            }
            return p;
        }, { } as Record<string, IOrderDetail[]>);
    }, [props.subOrder?.orderDetails])

    const onChange = (detailId: string, newObj: Partial<IPaymentLinkTransferFlightConfirmData>) => {
        const keysChanged = Object.keys(newObj) as (keyof IPaymentLinkTransferFlightConfirmData)[];
        if (keysChanged.length === 0) {
            return;
        }
        const keysWithValueUpdated: Partial<Record<keyof IPaymentLinkTransferFlightConfirmData, boolean>> = {
            costPrice: false,
            extraFee: false,
            reservationCode: false,
            ticketNumber: false
        }
        setConfirmDatas(current => {
            keysChanged.forEach(key => {
                keysWithValueUpdated[key] = newObj[key] !== current[detailId][key];
            })
            return ({ ...current, [detailId]: { ...current[detailId], ...newObj } });
        })
        setConfirmDataErrors(current => {
            const newErrors = { ...current };
            let shouldUpdateError = false;
            Object.entries(keysWithValueUpdated).forEach(([key, value]) => {
                if (value) {
                    newErrors[detailId] = { ...newErrors[detailId], [key]: undefined };
                    shouldUpdateError = true;
                }
            })
            return shouldUpdateError ? newErrors : current;
        })
    }
    return <Box ml="auto" gap={1} display="flex">
        <Button onClick={() => {
            setVisibled(true);
        }}>
            Hoàn thành
        </Button>
        {visibled && (
            <Modal
                fullWidth
                maxWidth="md"
                visible={visibled}
                onClose={() => {
                    setVisibled(false);
                }}
                title="Xác nhận hoàn thành đơn hàng"
                onAction={async () => {
                    try {
                        await schema.validate(confirmDatas, { abortEarly: false });
                        setVisibled(false);
                        props.onConfirm(Object.values(confirmDatas).map(data => {
                            const detailData = props.subOrder.orderDetails.find(d => d.id === data.orderDetailId);
                            const extraInfo = Helpers.converStringToJson(detailData.extraInformation) as IBookingDetailFlightAmendServicePopupExtraInfo;
                            return {
                                orderDetailId: data.orderDetailId,
                                costPrice: data.costPrice,
                                extraInformation: JSON.stringify({
                                    ...extraInfo,
                                   users: [{
                                        ...extraInfo.users[0],
                                        transferFlightActualData: {
                                            actualCost: data.costPrice,
                                            actualFee: data.extraFee,
                                            actualReservationCode: data.reservationCode,
                                            actualTicketNumber: data.ticketNumber,
                                        }
                                   }], 
                                } as IBookingDetailFlightAmendServicePopupExtraInfo),
                            }
                        }));
                    } catch (error: any) {
                        if (error.name === "ValidationError") {
                            let newErrors: { [recordId: string]: { [field: string] : string } } = {};
                            error.inner.forEach((e: any) => {
                                const path = `${e.path}`.split(".");
                                const orderDetailId = path[0];
                                const field = path[1];
                                newErrors[orderDetailId] = {
                                    ...newErrors[orderDetailId],
                                    [field]: e.message
                                };
                            });
                            setConfirmDataErrors(newErrors);
                        } else {
                            Helpers.showAlert(Helpers.renderExceptionError(error), "error");
                        }
                    }
                }}
                hasActionButton
                buttonAction={Strings.Common.CONFIRM}
                closeButton={Strings.Common.CANCEL}
            >
                <Box display="flex" gap={2} flexDirection="column">
                    {Object.entries(orderDetailsGroupByUserId).map(([userId, orderDetails]) => {
                        const userDisplayName = props.userMap.get(userId);
                        return (
                            <DeeptechAccordion
                                key={userId}
                                data={null}
                                defaultExpanded
                                px={1}
                                renderTitle={() => {
                                    return (
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="button" fontWeight="bold">
                                                {userDisplayName}
                                            </Typography>
                                        </Box>
                                    );
                                }}
                                renderContent={() => orderDetails.map(detail => {
                                    const data = confirmDatas[detail.id] || { } as any;
                                    const error = confirmDataErrors[detail.id] || { } as any;
                                    const currentDetail = props.booking?.bookingDetails.find(d => d.id === detail.externalId);
                                    const currentExtraInfo = currentDetail?.extraInfo ? BookingHelpers.getFlightExtraInfoByBookingDetailType(currentDetail, userId) : { } as any;
                                    const { departPlaceCity, departPlace, arrivalPlaceCity, arrivalPlace } = currentExtraInfo;
                                    const unitPrice = detail.unitPrice || 0;
                                    const extraFee = detail.extraFee || 0;

                                    const oldDetail = props.booking?.bookingDetails.find(d => d.id === currentDetail?.referenceId);
                                    const prevExtraInfo = oldDetail?.extraInfo ? BookingHelpers.getFlightExtraInfoByBookingDetailType(oldDetail) : { } as any;
                                    const { reservationCode: oldReservationCode} = prevExtraInfo;

                                    return <Box display="flex" alignItems="center" flexDirection="column" gap={3} key={detail.id}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" flexWrap="wrap">
                                            <Typography variant="button" fontWeight="bold">
                                                Mã đặt chỗ: {oldReservationCode}
                                            </Typography>
                                            <Typography variant="button">
                                                Chuyến bay {departPlaceCity} đến {arrivalPlaceCity} ({departPlace} - {arrivalPlace})
                                            </Typography>
                                        </Box>
            
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Box justifyContent="space-between" display="flex">
                                                    <Typography variant="button">
                                                        Tiền vé chênh lệch
                                                    </Typography>
                                                    <Typography variant="button" fontWeight="bold">
                                                        {Helpers.formatCurrency(unitPrice)} {props.subOrder.currency}
                                                    </Typography>
                                                </Box>
                                            </Grid>  
                                            <Grid item xs={12} md={6} display="flex">
                                                <FormField
                                                    variant="outlined"
                                                    label="Tiền chênh lệch thực tế"
                                                    placeholder={"Nhập tiền chênh lệch thực tế"}
                                                    autoComplete="off"
                                                    isMoney
                                                    defaultValue={data.costPrice ?? "0"}
                                                    onBlur={(costPrice: string) => onChange(detail.id, { costPrice: isNaN(parseInt(costPrice)) ? undefined : parseInt(costPrice) })}
                                                    errorMessage={error.costPrice}
                                                    error={!!error.costPrice}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box justifyContent="space-between" display="flex">
                                                    <Typography variant="button">
                                                        Phí khác
                                                    </Typography>
                                                    <Typography variant="button" fontWeight="bold">
                                                        {Helpers.formatCurrency(extraFee)} {props.subOrder.currency}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormField
                                                    variant="outlined"
                                                    label="Phí thực tế"
                                                    placeholder={"Nhập phí thực tế"}
                                                    autoComplete="off"
                                                    isMoney
                                                    defaultValue={data.extraFee ?? "0"}
                                                    onBlur={(extraFee: string) => onChange(detail.id, { extraFee: isNaN(parseInt(extraFee)) ? undefined : parseInt(extraFee) })}
                                                    errorMessage={error.extraFee}
                                                    error={!!error.extraFee}
                                                />
                                            </Grid>
                                            <Grid item xs={0} md={6}>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormField
                                                    variant="outlined"
                                                    label="Mã đặt chỗ mới"
                                                    placeholder={"Nhập mã đặt chỗ mới"}
                                                    autoComplete="off"
                                                    defaultValue={data.reservationCode ?? ""}
                                                    onBlur={(reservationCode: string) => onChange(detail.id, { reservationCode })}
                                                    errorMessage={error.reservationCode}
                                                    error={!!error.reservationCode}
                                                />
                                            </Grid>
                                            <Grid item xs={0} md={6}>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormField
                                                    variant="outlined"
                                                    label="Số vé mới"
                                                    placeholder={"Nhập số vé mới"}
                                                    autoComplete="off"
                                                    defaultValue={data.ticketNumber ?? ""}
                                                    onBlur={(ticketNumber: string) => onChange(detail.id, { ticketNumber })}
                                                    errorMessage={error.ticketNumber}
                                                    error={!!error.ticketNumber}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                })}
                                />
                            )
                        })}
                    </Box>
                </Modal>
                )}

        <PaymentLinkOrderCanceledAction
            onClose={() => {}}
            onCancel={props.onCancel}
        />
    </Box>
}
const PaymentLinkOrderRetryAction = (props: { onRetry: () => void } ) => {
    return (
        <Button onClick={props.onRetry}>
            Retry
        </Button>
    )
}