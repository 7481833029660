import moment from "moment";
import { useMemo, useState } from "react";
import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import useDataBookingOrder from "../useDataBookingOrder.hook";

import { IOrderDetailExtends } from ".";
import { IOrderDetail } from "services/sale/order.service";
import { ItineraryType, OrderType, PaymentStatus } from "constants/enum";
import { IItineraryExtraInfo } from "components/Booking/useDataRequestBooking.hook";



const CardItemBookingOrder = (props: {
    currency: string;
    orderType: number;
    isSubOrder: boolean;
    typeBooking: number;
    paymentStatus: number;
    organizationId: string;
    orderDetails: IOrderDetail[];
    dataMapExtraInfoBooking: Map<string, string>;
    dataMapOrderDetail: Map<string, IOrderDetailExtends>;
}) => {
    const isRefunded = useMemo(() => (
        [PaymentStatus.Refunded, PaymentStatus.WaitingRefund].includes(props?.paymentStatus)
    ), [props?.paymentStatus]);

    const renderItemRow = (item: IOrderDetail, index: number) => {
        let totalAmount = (item?.amount || 0);

        if (props?.orderType !== OrderType.AdditionService) {
            totalAmount = totalAmount + (item?.fee || 0);
        };

        const itemOrderDetailParent = props.dataMapOrderDetail.get(item.referenceId);

        return (
            <Box key={item.id} sx={{
                gap: 1,
                display: "grid",
                alignItems: "end",
                gridTemplateColumns: "1fr 50px 150px",
            }}>
                <RenderFlightInfo
                    index={index}
                    orderType={props?.orderType}
                    isSubOrder={props?.isSubOrder}
                    typeBooking={props.typeBooking}
                    organizationId={props.organizationId}
                    extraInformation={item?.extraInformation}

                    orderTypeParent={itemOrderDetailParent?.orderType}
                    extraInformationParent={itemOrderDetailParent?.extraInformation}

                    extraInfoBooking={props.dataMapExtraInfoBooking.get(item?.externalId)}
                    extraInfoBookingParent={props.dataMapExtraInfoBooking.get(itemOrderDetailParent?.externalId)}
                />
                <Typography variant="button">
                    {`x ${Helpers.formatCurrency(item?.quantity || 0)}`}
                </Typography>
                <Box textAlign={"end"}>
                    <Typography variant="button" color={isRefunded ? "error" : "dark"}>
                        {`${isRefunded ? "-" : ""} ${Helpers.formatCurrency(totalAmount)} ${item?.currency || props.currency}`}
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <Box sx={{
            gap: 1,
            display: "grid",
            padding: "16px",
            borderRadius: "16px",
            border: "1.5px solid #F0F0F0",
        }}>
            <Box gap={0.5} display={"grid"}>
                <Typography variant="h6">
                    {Strings.BOOKING_ORDER.DETAIL.BOOKING_ORDER_INFORMATION}
                </Typography>
            </Box>
            <Box gap={2} marginTop={1} display={"grid"}>
                {[...props?.orderDetails || []].map((item, index) => (renderItemRow(item, index)))}
            </Box>
        </Box>
    );
};

export default CardItemBookingOrder;

const getTextDateTimeFlight = (valueTime: any) => {
    const day = Strings.Common.DAY.toLocaleLowerCase();
    const time = moment(Helpers.getDateValue(valueTime)).format("HH:mm");
    const date = moment(Helpers.getDateValue(valueTime)).format("DD/MM/YYYY");

    return `${time} ${day} ${date}`;
};

const getTextDateTimeHotel = (valueTime: string, valueDate: any) => {
    const day = Strings.Common.DAY.toLocaleLowerCase();
    const date = valueDate ? moment(Helpers.getDateValue(valueDate)).format("DD/MM/YYYY") : "";
    return `${valueTime ? valueTime : ""} ${day} ${date}`;
};

const getDataFlightInfo = (extraInfo: any) => {
    const flight = extraInfo?.users?.[0]?.flight;

    if (Helpers.isNullOrEmpty(flight)) {
        return {
            ticketNumber: "",
            reservationCode: "",
            departTime: extraInfo?.FlightInfo?.DepartDate,
            arrivalTime: extraInfo?.FlightInfo?.ArrivalDate,
            cabinClass: extraInfo?.FlightInfo?.CabinClass || "",
            departPlaceCode: extraInfo?.FlightInfo?.DepartPlaceObj?.Code || "",
            arrivalPlaceCode: extraInfo?.FlightInfo?.ArrivalPlaceObj?.Code || "",
            carrierName: extraInfo?.FlightInfo?.CarrierMarketingObj?.Name?.["vi"] || "",
        };
    } else {
        return {
            departTime: flight?.departTime,
            arrivalTime: flight?.arrivalTime,
            ticketNumber: flight?.ticketNumber,
            cabinClass: flight?.cabinClass || "",
            carrierName: flight?.airlineObj?.name || "",
            reservationCode: flight?.reservationCode || "",
            departPlaceCode: flight?.departPlaceObj?.code || "",
            arrivalPlaceCode: flight?.arrivalPlaceObj?.code || "",
        };
    };
};

const RenderFlightInfo = ({
    index,
    orderType,
    isSubOrder,
    typeBooking,
    organizationId,
    orderTypeParent,
    extraInformation,
    extraInfoBooking,
    extraInformationParent,
    extraInfoBookingParent,
}: {
    index: number,
    orderType: number,
    isSubOrder: boolean,
    typeBooking: number,
    organizationId: string,
    orderTypeParent: number,
    extraInformation: string,
    extraInfoBooking: string,
    extraInformationParent?: string,
    extraInfoBookingParent?: string,
}) => {
    const { getDataMapUserByIds } = useDataBookingOrder();

    const [dataMapUser, setDataMapUser] = useState<Map<string, string>>(new Map());

    const dataExtraInfo = useMemo(() => (Helpers.converStringToJson(extraInformation)), [extraInformation]);
    const dataExtraInfoParent = useMemo(() => (Helpers.converStringToJson(extraInformationParent)), [extraInformationParent]);

    const dataFlightInfo = useMemo(() => (getDataFlightInfo(dataExtraInfo)), [dataExtraInfo]);
    const dataFlightParentInfo = useMemo(() => (getDataFlightInfo(dataExtraInfoParent)), [dataExtraInfoParent]);

    const dataExtraInfoBooking = useMemo(() => (Helpers.toCamelCaseObj(Helpers.converStringToJson(extraInfoBooking)) as IItineraryExtraInfo), [extraInfoBooking]);

    useMemo(() => {
        if (orderType === OrderType.PartialRefund) {
            (async () => {
                let newDataUser = new Map();

                newDataUser = await getDataMapUserByIds({
                    orgId: organizationId,
                    newDataUser: newDataUser,
                    ids: [...dataExtraInfo?.users || []].map(el => el.id),
                });

                setDataMapUser(newDataUser);
            })()
        };
    }, [orderType, dataExtraInfo?.users]);

    const BoxDefault = () => (
        <>
            {typeBooking === ItineraryType.Flight &&
                <Box gap={0.5} display={"grid"}>
                    <Typography variant="button" sx={{ marginBottom: 0.5 }}>
                        {`${Strings.BOOKING.RESERVATION_CODE}: `}
                        {dataExtraInfoBooking?.issueTicketInfo?.booking?.itineraries?.[0]?.reservation_code}
                    </Typography>

                    <Typography variant="button">
                        {`${Strings.BOOKING.FLIGHT} ${dataExtraInfoBooking?.flightInfo?.departPlaceObj?.code} - ${dataExtraInfoBooking?.flightInfo?.arrivalPlaceObj?.code}`}
                    </Typography>

                    <Typography variant="button">
                        -&nbsp;{`${Strings.BOOKING_ORDER.DETAIL.CARRIER} ${dataExtraInfoBooking?.flightInfo?.carrierOperatorObj?.name?.vi}`}
                        &nbsp;-&nbsp;{`${Strings.BOOKING_ORDER.DETAIL.CABIN_CLASS} ${dataExtraInfoBooking?.flightInfo?.cabinClass}`}
                    </Typography>

                    <Typography variant="button">
                        -&nbsp;{`${Strings.BOOKING_ORDER.DETAIL.DEPART_AND_ARRIVAL}:`}&ensp;
                        {getTextDateTimeFlight(dataExtraInfoBooking?.flightInfo?.departDate)}
                        &ensp;-&ensp;
                        {getTextDateTimeFlight(dataExtraInfoBooking?.flightInfo?.arrivalDate)}
                    </Typography>
                </Box>
            }
            {typeBooking === ItineraryType.Hotel &&
                <Box gap={0.5} display={"grid"}>
                    <Typography variant="button" sx={{ marginBottom: 0.5 }}>
                        {"Mã đặt chỗ: "}
                        {dataExtraInfoBooking?.issueTicketInfo?.booking?.itineraries?.[0]?.reservation_code}
                    </Typography>
                    <Typography variant="button">
                        {`Đặt chỗ tại ${dataExtraInfoBooking?.hotelInfo?.partnerName?.value?.["vi"] || "-/-"}`}
                    </Typography>
                    <Typography variant="button">
                        -&nbsp;{`${dataExtraInfoBooking?.hotelInfo?.product?.name?.value?.["vi"] || "-/-"}`}
                    </Typography>
                    <Typography variant="button">
                        -&nbsp;{"Nhận phòng:"}&ensp;
                        {getTextDateTimeHotel(dataExtraInfoBooking?.hotelInfo?.checkinTime, dataExtraInfoBooking?.hotelInfo?.checkinDate)}
                        &ensp;{"- Trả phòng:"}&ensp;
                        {getTextDateTimeHotel(dataExtraInfoBooking?.hotelInfo?.checkoutTime, dataExtraInfoBooking?.hotelInfo?.checkoutDate)}
                    </Typography>
                </Box>
            }
        </>
    );

    const BoxAdditionService = () => (
        <Box gap={0.5} display={"grid"}>
            {(index === 0) &&
                <Box marginBottom={0.5} display={"grid"}>
                    <Typography variant="button">
                        {Strings.BOOKING.ADD_SERVICE_ADDITION_SERVICE}
                    </Typography>
                    <Typography variant="button">
                        {`${Strings.BOOKING.RESERVATION_CODE}: `}
                        {dataFlightInfo?.reservationCode}
                    </Typography>
                </Box>
            }
            <Typography variant="button">
                {`${Strings.BOOKING.FLIGHT} ${dataFlightParentInfo?.departPlaceCode} - ${dataFlightParentInfo?.arrivalPlaceCode}`}
            </Typography>
            <Typography variant="button">
                &ensp;&#8226;&ensp;{dataExtraInfo?.itemName?.["vi"]}
            </Typography>
        </Box>
    );

    const BoxPartialRefund = () => (
        <Box gap={0.5} display={"grid"}>
            {(index === 0) &&
                <Box marginBottom={0.5} display={"grid"}>
                    <Typography variant="button">
                        {Strings.BOOKING.ADD_SERVICE_TICKET_REFUND}
                    </Typography>
                    <Typography variant="button">
                        {`${Strings.BOOKING.RESERVATION_CODE}: `}
                        {dataFlightInfo?.reservationCode}
                    </Typography>
                </Box>
            }
            <Typography variant="button">
                {`${index + 1}. ${dataMapUser.get(dataExtraInfo?.users?.[0]?.id)}`}
            </Typography>
            <Typography variant="button">
                {`${Strings.BOOKING.FLIGHT} ${dataFlightParentInfo?.departPlaceCode} - ${dataFlightParentInfo?.arrivalPlaceCode}`}
                &ensp;&#8226;&ensp;
                <Typography variant="button" fontWeight="bold">
                    {`${Strings.BOOKING.TICKET_NUMBER}: `}
                    <Typography variant="button">{dataFlightInfo?.ticketNumber}</Typography>
                </Typography>
            </Typography>
        </Box>
    );

    const BoxTransfer = () => (
        <Box gap={0.5} display={"grid"}>
            {(index === 0) &&
                <Box marginBottom={0.5} display={"grid"}>
                    <Typography variant="button">
                        {Strings.BOOKING.ADD_SERVICE_TRANSFER_FLIGHT}
                    </Typography>
                    <Typography variant="button">
                        {`${Strings.BOOKING.RESERVATION_CODE}: `}
                        {dataFlightInfo?.reservationCode}
                    </Typography>
                </Box>
            }

            <Typography variant="button">
                {`${Strings.BOOKING_ORDER.DETAIL.NEW_FLIGHT}: `}
                {`${Strings.BOOKING.FLIGHT} ${dataFlightInfo?.departPlaceCode} - ${dataFlightInfo?.arrivalPlaceCode}`}
            </Typography>

            <Typography variant="button">
                -&nbsp;{`${Strings.BOOKING_ORDER.DETAIL.CARRIER} ${dataFlightInfo?.carrierName}`}
                &nbsp;-&nbsp;{`${Strings.BOOKING_ORDER.DETAIL.CABIN_CLASS} ${dataFlightInfo?.cabinClass}`}
            </Typography>

            <Typography variant="button">
                -&nbsp;{`${Strings.BOOKING_ORDER.DETAIL.DEPART_AND_ARRIVAL}:`}&ensp;
                {getTextDateTimeFlight(dataFlightInfo?.departTime)}
                &ensp;-&ensp;
                {getTextDateTimeFlight(dataFlightInfo?.arrivalTime)}
            </Typography>
        </Box>
    );

    switch (orderType) {
        case OrderType.AdditionService:
            return <BoxAdditionService />;
        case OrderType.PartialRefund:
            return <BoxPartialRefund />;
        case OrderType.Transfer:
            return <BoxTransfer />;
        default:
            return <BoxDefault />;
    };
};