import { FlightTakeoff } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { Box, Typography, useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import CardTimeLineFlight from "./cardTimeLineFlight";
import useDataRequestBooking, { IETicket, IFreeBaggage, IIssueTicketInfo, IItineraryExtraInfo, IPassenger } from "../../useDataRequestBooking.hook";

import { IBookingDetail } from "../../useDataRequestBooking.hook";



const getValueBaggage = (data?: IFreeBaggage) => {
    if (!Helpers.isNullOrEmpty(data?.quantity)
        || !Helpers.isNullOrEmpty(data?.name)) {
        return `${data?.quantity || ""} kiện ${data?.name || ""}`;
    } else {
        return "-"
    }
};

const CardItemFlightInfo = (props: {
    data: IBookingDetail,
    reservationCode?: string;
    isShowReservationCode?: boolean,
}) => {
    const {
        language,
    } = useCommonComponentContext();

    const {
        VALUE_NULL,
        getDateNameFormat,
    } = useDataRequestBooking();

    const [reservationCode, setReservationCode] = useState("");
    const [passengers, setPassengers] = useState<IPassenger[]>([]);
    const [dataMapFreeBaggage, setDataMapFreeBaggage] = useState<IFreeBaggage[]>([]);
    const [dataMapETickets, setDataMapETickets] = useState<Map<string | number, IETicket>>(new Map());

    const extraInfo: IItineraryExtraInfo = useMemo(() => Helpers.toCamelCaseObj(Helpers.converStringToJson(props.data?.extraInfo)), [props.data]);

    const valueNumber = useMemo(() => props.isShowReservationCode ? 3 : 4, [props.isShowReservationCode]);

    useEffect(() => {

        const leg = extraInfo?.flightInfo?.leg;

        const issueTicketInfo: IIssueTicketInfo = extraInfo?.issueTicketInfo;

        const newItemItinerary = [...issueTicketInfo?.booking?.itineraries || []].find(el => el.leg === leg);

        let newMapE: Map<string, IETicket> = new Map();
        for (const e of [...newItemItinerary?.e_tickets || []]) {
            newMapE.set(e.pax_id, e);
        };

        setDataMapETickets(newMapE);
        setPassengers([...extraInfo?.passengers || []]);

        let newDataFB: IFreeBaggage[] = [];
        if (newDataFB.length > 0) {
            setDataMapFreeBaggage(newDataFB);
        } else {
            setDataMapFreeBaggage([...extraInfo?.flightInfo.freeBaggage || []]);
        }

        if (Helpers.isNullOrEmpty(newItemItinerary?.reservation_code)) {
            setReservationCode(props.reservationCode);
        } else {
            setReservationCode(newItemItinerary?.reservation_code);
        }

    }, [extraInfo, props.reservationCode]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Box sx={{
                    borderRadius: "4px",
                    padding: "12px 16px",
                    backgroundColor: "#EBF0FF",
                }}>
                    <Box sx={{
                        gap: 2,
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "end",
                    }}>
                        <Typography variant="h5">
                            {`${extraInfo?.flightInfo?.departPlaceObj?.city} ${extraInfo?.flightInfo?.departPlaceObj?.country}`}
                        </Typography>
                        <FlightTakeoff />
                        <Typography variant="h5">
                            {`${extraInfo?.flightInfo?.arrivalPlaceObj?.city} ${extraInfo?.flightInfo?.arrivalPlaceObj?.country}`}
                        </Typography>
                    </Box>

                    <Typography variant="button" fontWeight="bold">
                        {`${getDateNameFormat(extraInfo?.flightInfo?.departDate * 1000)}`}
                    </Typography>

                    {props.isShowReservationCode &&
                        <Box>
                            <Box sx={{
                                gap: 1,
                                px: 1.5,
                                py: 0.75,
                                borderRadius: 1,
                                alignItems: "center",
                                display: "inline-flex",
                                justifyContent: "center",
                                backgroundColor: "#FFFFFF",
                            }}>
                                <Typography variant="button" sx={{ gap: 1, display: "flex", alignItems: "center" }}>
                                    {`${Strings.BOOKING.RESERVATION_CODE}: `}
                                    {!Helpers.isNullOrEmpty(reservationCode) &&
                                        <Typography variant="button" fontWeight="bold">
                                            {(reservationCode === VALUE_NULL) ? "" : reservationCode}
                                        </Typography>
                                    }
                                    {Helpers.isNullOrEmpty(reservationCode) &&
                                        <Box sx={{ display: "flex", height: "1rem" }}>
                                            <CircularProgress size={"1rem"} />
                                        </Box>
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    }
                </Box>
            </Grid>

            <Grid item xs={12} >
                <Box sx={{
                    gap: 1,
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "end",
                }}>
                    <Box
                        component={"img"}
                        src={extraInfo?.flightInfo?.carrierMarketingObj?.logoUrl}
                        sx={{
                            width: "21px",
                            height: "21px",
                            borderRadius: "50%",
                            objectFit: "contain",
                            border: "1px #c3c3c3 solid"
                        }}
                    ></Box>
                    <Typography variant="button">{(extraInfo?.flightInfo?.carrierMarketingObj?.name as any)?.[language]}</Typography>
                    <Typography variant="button">{" - "}</Typography>
                    <Typography variant="button">
                        {[extraInfo?.flightInfo?.carrierMarketingObj?.code, extraInfo?.flightInfo?.flightNumber].join("")}
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <CardTimeLineFlight
                    language={language}
                    data={extraInfo?.flightInfo}
                />
            </Grid>

            <Grid item xs={12}>
                {
                    passengers.map((item, index: number) => (
                        <Box marginBottom={3} key={item.id}>
                            <Grid container spacing={1} alignItems="start">
                                <Grid item xs={12} md={3}>
                                    <Box display="grid">
                                        <Typography variant="button" fontWeight="bold">
                                            {`${item.first_name || ""} ${item.last_name || ""}`}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Grid container spacing={1} alignItems="start">
                                        <Grid item xs={6} sm={valueNumber} md={valueNumber}>
                                            <Box display="grid">
                                                <Typography variant="caption" color="secondary">
                                                    {Strings.Common.BOOKING_HAND_BAGGAGE}
                                                </Typography>
                                                <Typography variant="button">
                                                    {getValueBaggage(dataMapFreeBaggage.find(el => `${el.is_hand_baggage}` === "true" || `${el.isHandBaggage}` === "true"))}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={valueNumber} md={valueNumber}>
                                            <Box display="grid">
                                                <Typography variant="caption" color="secondary">
                                                    {Strings.Common.BOOKING_CHECKED_BAGGAGE}
                                                </Typography>
                                                <Typography variant="button">
                                                    {getValueBaggage(dataMapFreeBaggage.find(el => `${el.is_hand_baggage}` === "false" || `${el.isHandBaggage}` === "false"))}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={valueNumber} md={valueNumber}>
                                            <Box display="grid">
                                                <Typography variant="caption" color="secondary">
                                                    {Strings.BOOKING.SEAT_NUMBER}
                                                </Typography>
                                                <Typography variant="button">
                                                    {"-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {props.isShowReservationCode &&
                                            <Grid item xs={6} sm={valueNumber} md={valueNumber}>
                                                <Box display="grid">
                                                    <Typography variant="caption" color="secondary">
                                                        {Strings.BOOKING.TICKET_NUMBER}
                                                    </Typography>
                                                    <Typography variant="button">
                                                        {dataMapETickets.get(item.id)?.ticket_number || "-"}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    ))
                }
            </Grid>
        </Grid>
    );
};

export default CardItemFlightInfo;