import { useEffect, useState } from "react";
import { useCommonComponentContext } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import HotelBookingDetailContainer from "./HotelBooking";
import FlightBookingDetailContainer from "./FlightBooking";
import RequestBookingService from "services/booking/requestBooking.service";

import { ItineraryType } from "constants/enum";
import { IDetailBooking } from "../useDataRequestBooking.hook";


interface IProps {
    idDetail?: string;
    type?: ItineraryType;
    onGoBack: () => void;
}

const requestBookingService = new RequestBookingService();

const RequestBookingDetailContainer = (props: IProps) => {

    const {
        userInfo,
        onError,
        onHideLoading,
        onShowLoading,
    } = useCommonComponentContext();

    const [dataDetail, setDataDetail] = useState<IDetailBooking>(undefined);

    useEffect(() => {
        if (!Helpers.isNullOrEmpty(userInfo?.userProfile.organizationId) &&
            !Helpers.isNullOrEmpty(props.idDetail)) {
            getDetailBooking(props.idDetail);
        }
    }, [userInfo?.userProfile.organizationId, props.idDetail]);

    const getDetailBooking = async (id: string) => {
        try {
            onShowLoading();

            const result = await requestBookingService.getDetail(id);

            const currency = result?.currency || userInfo?.userProfile?.currency;

            setDataDetail({ ...result, currency: currency });

        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            onError(e);
        } finally {
            onHideLoading();
        }
    };

    if (props.type === ItineraryType.Flight) {
        return (
            <FlightBookingDetailContainer
                dataDetail={dataDetail}
                onGoBack={props.onGoBack}
                refetchDetail={() => getDetailBooking(props.idDetail)}
            />
        );
    };

    if (props.type === ItineraryType.Hotel) {
        return (
            <HotelBookingDetailContainer
                dataDetail={dataDetail}
                onGoBack={props.onGoBack}
                refetchDetail={() => getDetailBooking(props.idDetail)}
            />
        );
    };

    return (
        <></>
    );
};

export default RequestBookingDetailContainer;
