import { useState } from "react";
import { Box, Button } from "@maysoft/common-component-react";
import { BookingDetailAmendType } from "constants/enum";
import { useAddMoreBookingServiceContext } from "providers/addBookingServiceProvider";

import Strings from "constants/strings";
import BookingDetailRefundPopup from "../../PopupAmend/BookingDetailRefundPopup";
import BookingDetailTransferFlightPopup from "../../PopupAmend/BookingDetailTransferFlightPopup";
import BookingDetailAdditionalServicesPopup from "../../PopupAmend/BookingDetailAdditionalServicesPopup";

interface IAddBookingServicePopupsContainerProps {
    onCompleted?: (args: { subOrderId?: string }) => Promise<void>;
}

const AddBookingServicePopupsContainer = (props: IAddBookingServicePopupsContainerProps) => {
    const { users } = useAddMoreBookingServiceContext();

    const [visiblePopup, setVisiblePopup] = useState<BookingDetailAmendType>(undefined);
    const isTransfer = visiblePopup === BookingDetailAmendType.Transfer;
    const isPartialRefund = visiblePopup === BookingDetailAmendType.PartialRefund;
    const isAdditionService = visiblePopup === BookingDetailAmendType.AdditionService;

    const handleCompleted = async (args: any) => {
        setVisiblePopup(undefined);
        await props.onCompleted?.(args);
    }

    return users.length >= 1 && (
        <Box sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, minmax(200px, 1fr))",
            gap: 1,
            flexWrap: "wrap",
            border: "1px solid #fafafa",
            p: 3,
            mb: 1,
            "@media (max-width: 687px)": {
                gridTemplateColumns: "repeat(2, minmax(200px, 1fr))",
            },
        }} bgColor="white">
            <Button onClick={() => setVisiblePopup(BookingDetailAmendType.AdditionService)}>{Strings.BOOKING.ADD_SERVICE_ADDITION_SERVICE}</Button>
            <BookingDetailAdditionalServicesPopup visible={isAdditionService} onCompleted={handleCompleted} onClose={() => setVisiblePopup(undefined)} />

            <Button onClick={() => setVisiblePopup(BookingDetailAmendType.Transfer)}>{Strings.BOOKING.ADD_SERVICE_TRANSFER_FLIGHT}</Button>
            {isTransfer && <BookingDetailTransferFlightPopup visible={isTransfer} onCompleted={handleCompleted} onClose={() => setVisiblePopup(undefined)} />}

            <Button onClick={() => setVisiblePopup(BookingDetailAmendType.PartialRefund)}>{Strings.BOOKING.ADD_SERVICE_TICKET_REFUND}</Button>
            {isPartialRefund && <BookingDetailRefundPopup visible={isPartialRefund} onCompleted={handleCompleted} onClose={() => setVisiblePopup(undefined)} />}
        </Box>
    )
}

export default AddBookingServicePopupsContainer;