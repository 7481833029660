import Constants from "../../constants";
import SaleService from "./sale.service";

class SubOrderService extends SaleService {
    public finishSubOrder = async (request: IFinishSubOrderRequest) => {
        const result = await this.api.put({
            path: Constants.ApiPath.SUB_ORDER.FINISH_SUB_ORDER.concat("?orderId=" + request.orderId),
            // data: request,
        });

        return result.data.result;
    }
    public cancelSubOrder = async (request: ICancelSubOrderRequest) => {
        const result = await this.api.put({
            path: Constants.ApiPath.SUB_ORDER.CANCEL_SUB_ORDER,
            data: request,
        });

        return result.data.result;
    }
    public retryPaymentLink = async (request: IRetryPaymentLinkRequest): Promise<{ paymentLink: string }> => {
        const result = await this.api.post({
            path: Constants.ApiPath.SUB_ORDER.RETRY_PAYMENT_LINK.concat("?orderId=" + request.orderId),
            // data: request,
        });

        return { paymentLink: result.data.result };
    }
}

export default SubOrderService;

interface IRetryPaymentLinkRequest {
    orderId: string;
}

interface ICancelSubOrderRequest {
    orderId: string,
    reason: string
}
interface IFinishSubOrderRequest {
    orderId: string;
}