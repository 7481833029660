import moment from "moment";
import { Menu } from "@mui/material";
import { useMemo, useState } from "react";
import { Box, Typography } from "@maysoft/common-component-react";

import SegmentFlightIntoTooltip, { formatDuration } from "./segmentFlightIntoTooltip";
import useDataRequestBooking, { IFlightExtraInformation } from "components/Booking/useDataRequestBooking.hook";



const CardTimeLineFlight = ({ data, language }: { data: IFlightExtraInformation | undefined; language: string }) => {

    const { formatDateName } = useDataRequestBooking();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const segmentsList = data?.segmentsList || [];

    const length = segmentsList.length;
    const isOneStopFlight = length === 2;
    const isMultiStopFlight = length > 2;
    const isStraightFlight = length === 1;

    const numberSegmentBetween = Array.from({ length }, (_, index) => index + 1);

    const labelDurationLine = useMemo(() => {
        if (isStraightFlight) {
            return "Bay thẳng";
        }

        if (isOneStopFlight) {
            const duration = formatDuration((segmentsList[1].departDate - segmentsList[0].arrivalDate) / (60 * 1000), false, language);
            return `${duration} tại ${segmentsList[1].departPlaceObj?.name || segmentsList[1].departPlaceObj?.city || ""}`;
        }

        if (isMultiStopFlight) {
            const stopPoints = segmentsList.reduce((res: string[], segment, index) => {
                if (index !== 0) {
                    return [...res, segment?.departPlaceObj?.name || segment?.departPlaceObj?.city];
                }
                return [...res];
            }, []);
            return `${length - 1} điểm dừng tại ${stopPoints.join(", ")}`;
        }
    }, [isMultiStopFlight, isOneStopFlight, isStraightFlight, length, segmentsList, language]);

    const isEqualDate = useMemo(() => {
        const startDay = moment(data?.departDate * 1000).format("DD/MM/YYYY");
        const endDay = moment(data?.arrivalDate * 1000).format("DD/MM/YYYY");

        return (startDay === endDay);
    }, [data?.departDate, data?.arrivalDate]);


    return (
        <Box>
            <Box
                sx={{
                    position: "relative",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    display: "flex",
                    width: "100%",
                    flexShrink: 0,
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "0.5rem",
                    px: "0.5rem",
                    overflow: "hidden",
                    "@media (min-width: 640px)": {
                        flexDirection: "row",
                    }
                }}
            >
                <Box
                    sx={{
                        maxWidth: 500,
                        margin: "auto",
                        minWidth: "20vw",
                        flexShrink: 0,
                        my: "0.25rem",
                        textAlign: "center",
                    }}
                >
                    {!isEqualDate &&
                        <Typography variant="button" color="secondary">
                            {`${formatDateName(data?.departDate * 1000, language)}, ${moment(data?.departDate * 1000).format("DD/MM")}`}
                        </Typography>
                    }
                    <Box sx={{ marginBottom: "4px", }}>
                        <Typography variant="button" fontWeight="bold" sx={{
                            "@media (max-width: 1440px)": {
                                fontSize: "1rem",
                            }
                        }}>
                            {moment(data?.departDate * 1000).format("HH:mm")}
                        </Typography>
                    </Box>
                    <Typography variant="button">
                        {`${data?.departPlaceObj?.name} ${data?.departPlaceObj?.code}`}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        position: "relative",
                        width: "80%",
                        display: "flex",
                        gap: 0.25,
                        flexDirection: "column",
                        justifyContent: "space-between",
                        my: "0.25rem",
                        overflow: "hidden",
                        minWidth: "200px",
                        flex: 1,
                        top: 0,
                        "@media (min-width: 640px)": {
                            top: -12,
                            width: "unset",
                        }
                    }}
                >
                    <Box
                        sx={{
                            overflow: "hidden",
                            textAlign: "center",
                            color: "#868691",
                            fontSize: "0.75rem",
                        }}>
                        <Box
                            component={"span"}
                            sx={{
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}>
                            <Typography variant="caption" color="dark">
                                {formatDuration(data?.flightDuration || 0, false, language)}
                            </Typography>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            position: "relative",
                            height: 2,
                            width: "80%",
                            margin: "auto",
                            borderRadius: 3,
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#D1D1D8",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box sx={{
                            opacity: 1,
                            color: "#D1D1D8",
                            boxShadow: "none",
                            width: "0.375rem",
                            height: "0.375rem",
                            background: "#D1D1D8",
                            borderRadius: "3996px",
                        }}></Box>
                        <Box sx={{
                            opacity: 1,
                            boxShadow: "none",
                            width: "0.375rem",
                            height: "0.375rem",
                            background: "#FFFFFF",
                            borderRadius: "3996px",
                            border: "1px #D1D1D8 solid",
                        }}></Box>
                        <Box sx={{
                            opacity: 1,
                            color: "#D1D1D8",
                            boxShadow: "none",
                            width: "0.375rem",
                            height: "0.375rem",
                            background: "#D1D1D8",
                            borderRadius: "3996px",
                        }}></Box>
                    </Box>

                    <Box sx={{
                        textAlign: "center",
                        fontSize: "0.625rem",
                        color: "#868691",
                    }}>
                        {isStraightFlight
                            ? (
                                <Box
                                    sx={{
                                        display: "-webkit-box",
                                        overflow: "hidden",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                    }}
                                >{labelDurationLine} </Box>
                            )
                            : (
                                <Typography
                                    color="info"
                                    variant="caption"
                                    id="basic-button"
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    aria-controls={open ? "basic-menu" : undefined}
                                    sx={{
                                        fontStyle: "italic",
                                        textDecoration: "underline",
                                        ":hover": { cursor: "pointer" }
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setAnchorEl(event.currentTarget);
                                    }}
                                >
                                    {labelDurationLine}
                                </Typography>
                            )
                        }
                    </Box>
                </Box>

                <Box
                    sx={{
                        maxWidth: 500,
                        margin: "auto",
                        minWidth: "20vw",
                        flexShrink: 0,
                        my: "0.25rem",
                        textAlign: "center",
                    }}
                >
                    {!isEqualDate &&
                        <Typography variant="button" color="secondary">
                            {`${formatDateName(data?.arrivalDate * 1000, language)}, ${moment(data?.arrivalDate * 1000).format("DD/MM")}`}
                        </Typography>
                    }
                    <Box sx={{ marginBottom: "4px", }}>
                        <Typography variant="button" fontWeight="bold" sx={{
                            "@media (max-width: 1440px)": {
                                fontSize: "1rem",
                            }
                        }}>
                            {moment(data?.arrivalDate * 1000).format("HH:mm")}
                        </Typography>
                    </Box>
                    <Typography variant="button" >
                        {`${data?.arrivalPlaceObj?.name} ${data?.arrivalPlaceObj?.code}`}
                    </Typography>
                </Box>

                {(isOneStopFlight || isMultiStopFlight) && (
                    <>
                        <Menu
                            open={open}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            onClose={() => {
                                setAnchorEl(null);
                            }}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <SegmentFlightIntoTooltip
                                data={data}
                                language={language}
                            />
                        </Menu>
                    </>
                )}

            </Box>
        </Box>
    );
};

export default CardTimeLineFlight;