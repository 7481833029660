import moment from "moment-timezone";
import { Card } from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import Screens from "constants/screens";
import useDataRequestBooking, { IItineraryExtraInfo } from "components/Booking/useDataRequestBooking.hook";
import ConfirmRefundPopup from "components/Booking/DetailContainer/PopupAmend/BookingDetailConfirmRefundPopup";
import AddBookingServicePopupsContainer from "components/Booking/DetailContainer/FlightBooking/cardStatus/addBookingServicePopupsContainer";

import { IOrder } from "commons/interfaces";
import { BookingHelpers } from "commons/bookingHelpers";
import { IDetailBooking } from "components/Booking/useDataRequestBooking.hook";
import { ConfirmStatus, ItineraryType, Mode, NeedApprove, PaymentStatus } from "constants/enum";



const CardStatusInfo = ({ data, refetchDetail, isBookingOrderLoading, order }: { data: IDetailBooking, refetchDetail: (args?: { needRefetchBookingOrder: boolean }) => Promise<void>, isBookingOrderLoading: boolean, order?: IOrder }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const isAmend = parseInt(searchParams.get("amend")) === 1;

    const {
        getDataConfirmStatusByBooking,
        getCellReservationStatusByBooking,
    } = useDataRequestBooking();

    const newDataConfirmStatus = useMemo(() => {
        if (Number(data?.needApprove) === NeedApprove.Approve) {
            return getDataConfirmStatusByBooking(data?.itineraryDetailConfirmStatus);
        } else {
            return undefined;
        }
    }, [data?.needApprove, data?.itineraryDetailConfirmStatus, getDataConfirmStatusByBooking]);

    const extraInfoFirst: IItineraryExtraInfo = useMemo(() => {
        if (Helpers.isNullOrEmpty(data?.bookingDetails?.[0]?.extraInfo)) {
            return undefined;
        } else {
            return Helpers.toCamelCaseObj(Helpers.converStringToJson(data?.bookingDetails?.[0]?.extraInfo));
        }
    }, [data?.bookingDetails?.[0]?.extraInfo]);

    const newValue_LastTicketDate = useMemo(() => {
        if (!Helpers.isNullOrEmpty(extraInfoFirst?.lastTicketDate)) {
            const valueUnix = moment.utc(extraInfoFirst?.lastTicketDate).unix();
            return valueUnix;
        } else {
            return undefined;
        }
    }, [extraInfoFirst?.lastTicketDate]);

    const valueBackgroundColor = useMemo(() => {
        let newValue = "#FFF3EB";

        if ((
            (Number(data?.paymentStatus) >= PaymentStatus.Completed) &&
            (Number(data?.paymentStatus) < PaymentStatus.WaitingRefund)
        ) &&
            (Number(data?.itineraryDetailConfirmStatus) === ConfirmStatus.Completed)) {
            return "#F2F9FA";
        };

        if ([
            PaymentStatus.Refunded,
            PaymentStatus.WaitingRefund,
        ].includes(Number(data?.paymentStatus))) {
            return "#EAECF1";
        }

        return newValue;
    }, [data?.paymentStatus, data?.itineraryDetailConfirmStatus]);

    const newDataPaymentStatus = useMemo(() => {
        return getCellReservationStatusByBooking({
            status: data?.status,
            paymentStatus: data?.paymentStatus,
            lastTicketDate: newValue_LastTicketDate,
        });
    }, [
        data?.status,
        data?.paymentStatus,
        newValue_LastTicketDate,
        getCellReservationStatusByBooking,
    ]);

    const valueBookingCode = useMemo(() => {
        if (data?.type === ItineraryType.Flight) {
            const itemFirst = data?.bookingDetails?.[0]?.bookingReferenceCodes?.[0];
            return itemFirst?.referenceCode || "";
        }
        if (data?.type === ItineraryType.Hotel) {
            const itemFirst = data?.bookingDetails?.[0]?.bookingReferenceCodes?.[0];
            return itemFirst?.referenceCode || "";
        }
        return "";
    }, [data?.type, data?.bookingDetails]);

    const RenderTextPaymentStatus = () => {
        if (PaymentStatus.RefundProcessing === order?.paymentStatus) {
            return <Typography variant="button">
                {Strings.BOOKING.STATUS_INFO_REFUND_IS_PROCESSING}
            </Typography>
        }
        if (data?.confirmStatus === ConfirmStatus.Cancel || data?.itineraryDetailConfirmStatus === ConfirmStatus.Rejected) {
            return (
                <Typography variant="button" color="secondary">
                    {Strings.BOOKING.STATUS_INFO_PAYMENT_STATUS_TEXT_REJECT}
                </Typography>
            )
        }

        if (PaymentStatus.New === Number(data?.paymentStatus)) {
            if (
                !Helpers.isNullOrEmpty(newValue_LastTicketDate) &&
                (newValue_LastTicketDate < moment().unix())
            ) {
                return (
                    <Typography variant="button" color="secondary">
                        {Strings.BOOKING.STATUS_INFO_PAYMENT_STATUS_TEXT_EXPIRED}
                    </Typography>
                )
            } else {
                const strDate = newValue_LastTicketDate ? moment(Helpers.getDateValue(newValue_LastTicketDate)).format("HH:mm DD/MM/YYYY") : "";
                return (
                    <Typography variant="button">
                        {strDate ? Strings.formatString(Strings.BOOKING.STATUS_INFO_DUE_DATE, strDate) : ""}
                    </Typography>
                )
            }
        }

        if (PaymentStatus.WaitingRefund === data?.paymentStatus) {
            return (
                <Button color="info" variant="outlined" onClick={() => { setConfirmRefundPopupVisible(true) }}>
                    {Strings.BOOKING.STATUS_INFO_CONFIRM_REFUND}
                </Button>
            )
        }

        if (PaymentStatus.Refunded === data?.paymentStatus) {
            if (isBookingOrderLoading || !order) {
                return (
                    <Typography variant="button" color="secondary">
                        {Strings.BOOKING.STATUS_INFO_FETCHING_REFUND_DETAIL}
                    </Typography>
                )
            }
            return (
                <Typography variant="button" color="secondary">
                    {Strings.formatString(Strings.BOOKING.STATUS_INFO_PAYMENT_STATUS_TEXT_REFUND_AT, moment(parseInt(order.updateTime) * 1000).format("HH:mm - DD/MM/YYYY"), order.note)}
                </Typography>
            )
        }

        return <Typography variant="button">{""}</Typography>
    };

    const [confirmRefundPopupVisible, setConfirmRefundPopupVisible] = useState(false);

    const handleCompleted = async ({ subOrderId }: { subOrderId?: string }) => {
        setConfirmRefundPopupVisible(false);
        if (subOrderId) {
            navigate(Screens.ORDER_EDIT.concat(`?id=${order.id}&bookingId=${data.id}&mode=${Mode.View}`));
        } else {
            await refetchDetail({ needRefetchBookingOrder: true });
        }
    };

    const handleCompletedRefundBooking = async () => {
        setConfirmRefundPopupVisible(false);
        await refetchDetail({ needRefetchBookingOrder: true });
    };

    return (
        <Card sx={{ backgroundColor: valueBackgroundColor }}>
            <Box p={3}>
                <Box sx={{
                    gap: 1,
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "end",
                    justifyContent: "space-between",
                }}>
                    <Box sx={{ gap: 1, display: "grid" }}>
                        <Typography variant="button">
                            {Strings.formatString(Strings.BOOKING.STATUS_INFO_TITLE_BOOKING_CODE, { code: order?.orderCode })}
                            {
                                ![
                                    12,
                                    PaymentStatus.Failed,
                                    PaymentStatus.Refunded,
                                    PaymentStatus.Completed,
                                    PaymentStatus.WaitingRefund,
                                ].includes(Number(data?.paymentStatus)) &&
                                <Box
                                    sx={{
                                        marginLeft: 2,
                                        padding: "4px 12px",
                                        borderRadius: "8px",
                                        display: "inline-flex",
                                    }}
                                    bgColor={newDataConfirmStatus?.color}
                                >
                                    <Typography variant="caption" fontWeight="bold" color="white">
                                        {newDataConfirmStatus?.title || ""}
                                    </Typography>
                                </Box>
                            }
                        </Typography>

                        <Typography variant="button">{`Booking Code: ${valueBookingCode}`}</Typography>

                        <Typography variant="button">{Strings.BOOKING.STATUS_INFO_TITLE_BOOKING_AT}
                            <Typography variant="button" fontWeight="bold">
                                {moment(Helpers.getDateValue(data?.createTime)).format("HH:mm DD/MM/YYYY")}
                            </Typography>
                        </Typography>
                    </Box>
                    <Box sx={{ gap: 1, display: "grid", alignItems: "flex-end" }}>
                        <Typography variant="h6" color={newDataPaymentStatus?.color} textAlign="right">
                            {newDataPaymentStatus?.title || ""}
                        </Typography>
                        <Box minHeight={"21px"}>
                            <RenderTextPaymentStatus />
                        </Box>
                    </Box>
                </Box>
            </Box>

            {
                isAmend && data
                && BookingHelpers.isBookingIssued(data)
                && <AddBookingServicePopupsContainer onCompleted={handleCompleted} />
            }

            {confirmRefundPopupVisible &&
                !Helpers.isNullOrEmpty(data.bookingOrderId) &&
                <ConfirmRefundPopup
                    visible={confirmRefundPopupVisible}
                    bookingOrderId={data.bookingOrderId}
                    onCompleted={() => { handleCompletedRefundBooking(); }}
                    onClose={() => { setConfirmRefundPopupVisible(false) }}
                />
            }
        </Card>
    );
};

export default CardStatusInfo;