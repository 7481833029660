const Screens = {
    // =========================================================================
    // Auth
    // =========================================================================
    AUTH: "/auth",
    LOGIN: "/auth/login",
    LOGIN_REDIRECT: "/auth/callback",

    NOT_FOUND: "/404",
    RESET_PASSWORD: "/resetPassAuth",
    SECOND_LOGIN: "/auth/second_login",
    FORGOT_PASSWORD: "/auth/forgot-password",

    // =========================================================================
    // Main
    // =========================================================================
    /** / */
    HOME: "/",
    PROFILE: "/profile",
    ADMIN_USER: "/user/list",
    PROFILE_UPDATE_PHONE_NUMBER: "/profile/updatePhoneNumber",

    DASHBOARD: "/dashboard",

    // Quản lý tổ chức (Organization Manager)
    // ORGANIZATION
    ORGANIZATION_LIST: "/organization-module/organization/list",
    ORGANIZATION_EDIT: "/organization-module/organization/edit",
    ORGANIZATION_CREATE: "/organization-module/organization/create",

    // GROUP
    GROUP_LIST: "/organization-module/group/list",
    GROUP_EDIT: "/organization-module/group/edit",
    GROUP_CREATE: "/organization-module/group/create",

    // STAFF
    STAFF_LIST: "/organization-module/staff/list",
    STAFF_EDIT: "/organization-module/staff/edit",
    STAFF_CREATE: "/organization-module/staff/create",

    //product
    PRODUCT_TEMPLATE_EDIT: "/product-template/edit",
    PRODUCT_TEMPLATE_LIST: "/product-template/list",

    //product
    PRODUCT_LIST: "/product/list",
    PRODUCT_EDIT: "/product/edit",

    //combo product
    BUNDLE_PRODUCT_LIST: "/product-manage/bundle/list",
    BUNDLE_PRODUCT_EDIT: "/product-manage/bundle/edit",

    SERVICE_ITEM_LIST: "/product-manage/service/list",
    SERVICE_ITEM_EDIT: "/product-manage/service/edit",

    PROMOTION_LIST: "/product-manage/promotion/list",
    PROMOTION_EDIT: "/product-manage/promotion/edit",

    VOUCHER_EDIT: "/product-manage/promotion/voucher",

    // customer
    CUSTOMER_LIST: "/customer-module/customer/list",
    CUSTOMER_EDIT: "/customer-module/customer/edit",
    SETTING_LOYALTY_CUSTOMER: "/customer-module/loyalty-member/setting",

    // supplier
    SUPPLIER_LIST: "/partner/supplier/list",
    SUPPLIER_EDIT: "/partner/supplier/edit",

    // manufacturer
    MANUFACTURER_LIST: "/manufacturer/list",
    MANUFACTURER_EDIT: "/manufacturer/edit",

    // agent
    AGENT_LIST: "/partner/agent/list",
    AGENT_EDIT: "/partner/agent/edit",

    // membership
    MEMBERSHIP_LIST: "/membership/list",
    MEMBERSHIP_EDIT: "/membership/edit",

    // order
    ORDER_LIST: "/order/list",
    ORDER_VIEW: "/order/edit",
    ORDER_EDIT: "/order/edit",

    REFUND_ORDER_LIST: "/refund-order/list",
    REFUND_ORDER_VIEW: "/refund-order/view",
    REFUND_ORDER_EDIT: "/refund-order/edit",

    // request booking
    AMEND_BOOKING_LIST: "/booking-module/amend",
    AMEND_BOOKING_VIEW: "/booking-module/amend/detail",
    FLIGHT_BOOKING_LIST: "/booking-module/flight/list",
    FLIGHT_BOOKING_VIEW: "/booking-module/flight/detail",

    HOTEL_BOOKING_LIST: "/booking-module/hotel/list",
    HOTEL_BOOKING_EDIT: "/booking-module/hotel/edit",
    HOTEL_BOOKING_VIEW: "/booking-module/hotel/detail",
    HOTEL_BOOKING_CREATE: "/booking-module/hotel/create",

    // payment-profile
    PAYMENT_PROFILE_VIEW: "/payment-profile/view",

    // codename
    CODENAME_LIST: "/system-setting/codename/list",
    CODENAME_EDIT: "/system-setting/codename/edit",

    // config system
    SYSTEM_SETTING: "/system-setting/config-system/list",
    SYSTEM_SETTING_EDIT: "/system-setting/config-system/edit",

    // setting payment gateway
    SETTING_PAYMENT_GATEWAY: "/system-setting/setting-payment-gateway/config",

    // attribute
    ATTRIBUTE_LIST: "/system-setting/attribute/list",
    ATTRIBUTE_EDIT: "/system-setting/attribute/edit",

    //
    CASH_BOOK: "/cashbook/list",
    PAYMENT_TICKET: "/cashbook/payment-ticket/edit",
    RECEIVE_VOUCHER_EDIT: "/cashbook/receive-voucher/edit",

    PAYMENT_LIST: "/payment/list",
    PAYMENT_DETAIL: "/payment/detail",

    SERVICE_INFO: "/system-setting/service-info",

    SERVICE_FEE_LIST: "/system-setting/service-fee/list",
    HIDDEN_FEE_LIST: "/system-setting/hidden-fee/list",
    SETTING_ORDER: "/system-setting/setting-order",

    LIMIT_SETTING_LIST: "/ticket-fund/limit-setting/list",
    LIMIT_SETTING_EDIT: "/ticket-fund/limit-setting/edit",

    APPROVE_DEPOSIT_LIST: "/ticket-fund/approve-deposit/list",
    APPROVE_DEPOSIT_EDIT: "/ticket-fund/approve-deposit/edit",

    REQUEST_DEPOSIT_LIST: "/ticket-fund/request-deposit/list",
    REQUEST_DEPOSIT_EDIT: "/ticket-fund/request-deposit/edit",


    // ROLE
    ROLE_LIST: "/role/list",
    ROLE_EDIT: "/role/edit",
    ROLE_CREATE: "/role/create",
};

export default Screens;
