import React, { memo, useEffect, useMemo, useState } from "react";

import InputMask from "react-input-mask";
import Helpers from "../../commons/helpers";

import { Mode } from "constants/enum";
import { IconButton, InputAdornment } from "@mui/material";
import { FormField, TitleValue, Typography } from "@maysoft/common-component-react";

interface IProps {
    mode?: number;
    disabled?: boolean;
    required?: boolean;
    readOnly?: boolean;
    isFullRow?: boolean;
    is24hours?: boolean;

    label?: string;
    placeholder?: string;
    errorMessage?: string;
    defaultValue?: string;
    variant?: "standard" | "outlined";

    onChangeValue?: (value: any) => void;
}

const InputMaskTime: React.FC<IProps> = ({
    mode,
    label,
    variant,
    readOnly,
    disabled,
    required,
    isFullRow,
    is24hours,
    placeholder,
    defaultValue,
    errorMessage,
    onChangeValue,
}: IProps) => {

    const [timeTemp, setTimeTemp] = useState("");
    const [unitTime, setUnitTime] = useState<"AM" | "PM">("AM");

    useEffect(() => { setTimeTemp(defaultValue); }, [defaultValue]);

    const newMask = useMemo(() => {
        if (is24hours) {
            if (Helpers.isNullOrEmpty(timeTemp)) {
                return [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
            } else {
                return [
                    /[0-2]/,
                    (timeTemp?.[0] === '2') ? /[0-3]/ : (['0', '1'].includes(timeTemp?.[0] || "")) && /[0-9]/,
                    ':',
                    /[0-5]/,
                    /[0-9]/
                ];
            }
        } else {
            if (Helpers.isNullOrEmpty(timeTemp)) {
                return [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
            } else {
                return [
                    /[0-1]/,
                    (timeTemp?.[0] === '1') ? /[0-2]/ : (['0'].includes(timeTemp?.[0] || "")) && /[0-9]/,
                    ':',
                    /[0-5]/,
                    /[0-9]/
                ];
            }
        }
    }, [is24hours, timeTemp]);

    if (mode === Mode.View) {
        return <TitleValue title={label || ""} isFullRow={isFullRow} value={defaultValue} />;
    };

    return (
        <InputMask
            mask={newMask}
            disabled={disabled}
            value={defaultValue}
            maskPlaceholder={"00:00"}
            onChange={(event: any) => {
                let newValue = event.target.value;
                if (Helpers.isNullOrEmpty(event.target.value) || ["00:00", "--:--"].includes(event.target.value)) {
                    newValue = "";
                } else {
                    newValue = event.target.value;
                }
                setTimeTemp(newValue);
                Helpers.isFunction(onChangeValue) && onChangeValue(`${newValue} ${is24hours ? "" : unitTime}`);
            }}
        >
            <FormField
                fullWidth
                label={label}
                variant={variant}
                required={required}
                placeholder={placeholder}
                helperText={errorMessage}
                InputLabelProps={{ shrink: true }}
                inputProps={{ readOnly: readOnly }}
                error={!Helpers.isNullOrEmpty(errorMessage)}
                InputProps={{
                    endAdornment: !is24hours && (
                        <InputAdornment position="end">
                            <IconButton onClick={() => {
                                setUnitTime(prev => (prev === "AM") ? "PM" : "AM");
                            }}>
                                <Typography variant="button" fontWeight="bold">{unitTime}</Typography>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </InputMask>
    );
};

export default memo(InputMaskTime, (prev, next) => {
    if (
        prev.mode !== next.mode ||
        prev.label !== next.label ||
        prev.required !== next.required ||
        prev.disabled !== next.disabled ||
        prev.variant !== next.variant ||
        prev.readOnly !== next.readOnly ||
        prev.isFullRow !== next.isFullRow ||
        prev.placeholder !== next.placeholder ||
        prev.errorMessage !== next.errorMessage ||
        prev.defaultValue !== next.defaultValue ||
        prev.onChangeValue !== next.onChangeValue
    ) {
        return false;
    }
    return true;
});