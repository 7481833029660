import { Box, Typography } from "@maysoft/common-component-react";

import Helpers from "commons/helpers";
import Strings from "constants/strings";
import OperationHistoryBookingText from "../components/OperationHistoryBookingText";
import OperationHistorySectionTitle from "../components/OperationHistorySectionTitle";
import OperationHistoryOrderDataText from "../components/OperationHistoryOrderDataText";

import { ICodename } from "commons/interfaces";
import { BookingDetailAmendType } from "constants/enum";
import { IBookingDetail, IItineraryExtraInfo } from "components/Booking/useDataRequestBooking.hook";
import { IBookingDetailAmendHistorySectionProps, IBookingDetailAmend } from "components/Booking/DetailContainer/interfaces";



const AdditionServicesAmendHistory = ({
    index,
    amount,
    details,
    userMap,
    currency,
    orderCode,
    createUser,
    createTime,
    itemCodenames,
    paymentMethod,
}: IBookingDetailAmendHistorySectionProps & { itemCodenames: ICodename[] }) => {

    const detailsViewModel = details.reduce((p, detail) => {
        const referenceId = detail.referenceId;

        if (!p[referenceId]) {
            p[referenceId] = {
                amendDetails: {},
                referenceBooking: detail.referenceBooking,
            };
        };

        detail.amendExtraInfoParsed.users.forEach(u => {
            const userId = u.id;
            if (!p[referenceId].amendDetails[userId]) {
                p[referenceId].amendDetails[userId] = [];
            }

            p[detail.referenceId].amendDetails[userId].push(detail);
        });
        return p;
    }, {} as Record<string, {
        referenceBooking: IBookingDetail;
        amendDetails: Record<string, IBookingDetailAmend[]>
    }>);

    const renderSegAmendDetail = (segIndex: number, amendDetails: Record<string, IBookingDetailAmend[]>) => {
        const boxItems: JSX.Element[] = [];
        Object.entries(amendDetails).forEach(([userId, amendDetails]) => {
            [...amendDetails || []].forEach(itemAmendDetail => {
                const extraInfoItemAmendDetail: {
                    users: {
                        id: string;
                        flight: {
                            segmentIndex: number;
                            ticketNumber: string;
                            reservationCode: string;
                        }
                    }[];
                    contact: any;
                    itemName: any;
                    timezone: string;
                } = Helpers.toCamelCaseObj(Helpers.converStringToJson(itemAmendDetail?.extraInfo));

                const listItemAmendDetailBySeg = [...extraInfoItemAmendDetail?.users || []].filter(el => el.flight?.segmentIndex === segIndex);

                if ([...listItemAmendDetailBySeg || []].length > 0) {
                    boxItems.push(
                        <Box
                            gap={1}
                            display="grid"
                            key={segIndex + userId}
                            sx={{
                                maxWidth: "1000px",
                                gridTemplateRows: "1fr auto",
                                gridTemplateColumns: "repeat(5, 1fr)",
                                "@media (max-width: 900px)": {
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                },
                                "@media (max-width: 600px)": {
                                    gridTemplateColumns: "repeat(2, 1fr)",
                                },
                            }}
                        >
                            <Typography variant="button" fontWeight="bold" sx={{ gridRow: "1/-1" }}  >
                                {userMap[userId].fullName}
                            </Typography>
                            <div key={itemAmendDetail.id}>
                                {renderTransferFlightItem(itemCodenames.find(c => c.code === itemAmendDetail.itemId)?.name, itemAmendDetail.description)}
                            </div>
                        </Box>
                    )
                }
            });
        });
        return boxItems;
    }

    const renderTransferFlightItem = (title: string, value: string) => {
        return (
            value &&
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography variant="caption" component="span">{title}</Typography>
                <Typography variant="button" component="span" textTransform="none" fontWeight="medium">{value}</Typography>
            </Box>
        );
    };

    return (
        <Box display="flex" flexDirection="column" p={2} gap={1}>
            <OperationHistorySectionTitle
                index={index}
                updateTime={createTime}
                fullName={createUser?.fullName}
                title={Strings.BOOKING.ADD_SERVICE_ADDITION_SERVICE}
            />
            <Box px={2} gap={2} display="flex" flexDirection="column">
                <OperationHistoryOrderDataText
                    amount={amount}
                    currency={currency}
                    orderCode={orderCode}
                    paymentMethod={paymentMethod}
                    amendType={BookingDetailAmendType.AdditionService}
                />

                {Object.values(detailsViewModel).map(({ amendDetails, referenceBooking }) => {
                    const extraInfoBooking: IItineraryExtraInfo = Helpers.toCamelCaseObj(Helpers.converStringToJson(referenceBooking?.extraInfo));

                    const dataSegments = [...extraInfoBooking?.flightInfo?.segmentsList || []];
                    const itineraries = [...extraInfoBooking?.issueTicketInfo?.booking?.itineraries || []];
                    const reservationCode = itineraries.find((i: any) => i.leg === extraInfoBooking?.flightInfo?.leg)?.reservation_code;
                    const arrival_depart_place = [extraInfoBooking?.flightInfo?.arrivalPlace, extraInfoBooking?.flightInfo?.departPlace];

                    return (
                        dataSegments.map((seg) => {
                            const boxSegAmendDetail = renderSegAmendDetail(seg.index, amendDetails);
                            const textD = arrival_depart_place.includes(seg.departPlace) ? "" : "(Trung chuyển)";
                            const textA = arrival_depart_place.includes(seg.arrivalPlace) ? "" : "(Trung chuyển)";
                            return (
                                boxSegAmendDetail.length > 0 &&
                                <Box key={seg.index} display="flex" flexDirection="column">
                                    <OperationHistoryBookingText
                                        reservationCode={reservationCode}
                                        departTime={seg.departDate / 1000}
                                        departPlace={`${seg.departPlaceObj?.name || seg.departPlace} ${textD}`}
                                        arrivalPlace={`${seg.arrivalPlaceObj?.name || seg.arrivalPlace} ${textA}`}
                                    />
                                    {boxSegAmendDetail}
                                </Box>
                            )
                        })
                    )
                })}
            </Box>
        </Box>
    )
}

export default AdditionServicesAmendHistory;